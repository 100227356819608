import React, { Component } from 'react'
import { Link, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { HeaderContainer, InfoContainer, UsernameContainer, Dropdown, DropdownItem, WhatsAppButton } from './styles'
import { Row, Col } from 'react-flexbox-grid'
import Modal from 'react-responsive-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as BrandIcons from '@fortawesome/free-brands-svg-icons'
import * as Icons from '@fortawesome/free-solid-svg-icons'
import Banner from '@components/Banner'
import Logo from '@components/Logo'
import NavBar from '@components/NavBar'
import Button from '@components/Button'
import RegisterOrLogin from '@components/RegisterOrLogin'
import Arrow from '@components/Icons/Arrow'
import { isLogged } from '../../redux/LoginRedux'
import { Creators } from '../../actions'
import Config from '../../config'
import NoImage from '../../assets/images/noimage.png'
import Capitalize from '../../helpers/Capitalize'
import GAEvents from '../../helpers/GAEvents'
import './index.css'

// Google Analytics
import ReactGA from 'react-ga'

// Facebook Pixel
import ReactPixel from 'react-facebook-pixel'

// Hotjar
import { hotjar } from 'react-hotjar'

class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dropdownOpen: false,
      titleDropdownOpen: false,
      logout: false,
      metaTitle: null,
      metaDescription: null,
      modalLoginOpen: false,
      checkoutRoute: false,
      redirectTo: null,
      codeGoogle: null
    }
  }

  componentDidMount() {
    const { attemptGetBanners } = this.props
    attemptGetBanners()
  }
  
  componentWillReceiveProps(newProps) {
    const { location, fetching, store, productFetching, productDetail, cartProceed, showLoginDialog, loggedIn, isCookiesAllowed } = newProps
    const { state, pathname } = location
    // Copy current state
    const newState = Object.assign({}, this.state)

    // Did logout? Let's safe redirect to home
    if (!loggedIn && newState.logout) {
      setTimeout(() => {
        window.location.href = '/produtos'
      }, 1000)
    }

    if (!fetching && store) {
      const { settings } = store
      const { config_meta_title, config_meta_description, config_analytics_id, config_pixel_id, config_hotjar_id } = settings
      
      if(isCookiesAllowed === true){
        if (config_analytics_id && config_analytics_id.length > 0){
          ReactGA.initialize(config_analytics_id)
          ReactGA.ga('require','ec')
          ReactGA.pageview(window.location.pathname + window.location.search)
        }
    
        if (config_pixel_id && config_pixel_id.length > 0){
          ReactPixel.init(config_pixel_id)
          ReactPixel.pageView()
        }
    
        if (config_hotjar_id && config_hotjar_id.length > 0){
          hotjar.initialize(config_hotjar_id, 6)
        }
      }

      // Update page title
      document.title = config_meta_title

      newState.metaTitle = config_meta_title
      newState.metaDescription = config_meta_description
    }

    // Pages metas handle
    if (pathname === '/sobre' || pathname === '/contato') {
      const { settings } = store
      const { config_store_city, config_name } = settings

      switch(pathname) {
        case '/sobre':
          newState.metaTitle = `Saiba mais sobre a ${config_name} - ${config_store_city}`
          newState.metaDescription = `Conheça mais sobre a ${config_name}! Farmácia em ${config_store_city} com o melhor atendimento, medicamentos e linha completa de perfumaria!`
          break
        case '/contato':
          newState.metaTitle = `Entre em contato com ${config_name} aqui!`
          newState.metaDescription = `Clique e veja telefone e todos os contatos da ${config_name}! O atendimento mais rápido e prático de farmácia em ${config_store_city}`
          break
        default:
          break
      }
    }

    // Products metas handle
    if (state && state.metas) {
      const { cachedProduct, category, subcategory } = state
      const metaProduct = cachedProduct || productDetail

      if (!productFetching && metaProduct) {
        const { model, name, categories } = metaProduct
        const { settings } = store
        const { config_store_city, config_name } = settings
        
        // Update meta title
        newState.metaTitle = `${Capitalize(model)} ${Capitalize(name)} - ${Capitalize(config_store_city)}`

        // Update meta description
        if (categories && categories.length > 0 && categories[0].name.toLowerCase() !== 'outros') {
          let parentCategory = undefined
          parentCategory = categories.reverse()[0]

          newState.metaDescription = `${model} em ${config_store_city} você encontra aqui no site da ${config_name}, além de toda linha completa de ${parentCategory.name}!`
        } else {
          newState.metaDescription = `${model} em ${config_store_city} você encontra aqui no site da ${config_name}. São milhares de produtos e ofertas!`
        }
      }

      // Maybe we need to set category metas :P
      if (!productFetching && !metaProduct) {
        if (category && !subcategory) {
          const { meta_title, meta_description } = category
         
          newState.metaTitle = meta_title
          newState.metaDescription = meta_description
        }

        if (subcategory) {
          const { meta_title, meta_description } = subcategory
         
          newState.metaTitle = meta_title
          newState.metaDescription = meta_description
        }
      }

      // Update page title
      document.title = newState.metaTitle
    }

    if (cartProceed) {
      newState.modalLoginOpen = true
      newState.checkoutRoute = true
      this.props.setCartProceed(false)
    }

    if (showLoginDialog) {
      newState.modalLoginOpen = true
      this.props.setLoginDialog(false)
    }

    if (newState !== this.state) {
      this.setState(newState)
    }
  }

  toggleDropdown = () => {
    this.setState(state => ({
      ...state,
      dropdownOpen: !state.dropdownOpen
    }))
  }

  toggleTitleDropdown = () => {
    this.setState(state => ({
      ...state,
      titleDropdownOpen: !state.titleDropdownOpen
    }))
  }

  onDropdownClick = (logout = false) => {
    const { attemptLogout, resetCurrentAddress } = this.props

    // Debouncing
    setTimeout(() => {
      this.setState({
        dropdownOpen: false,
        modalLoginOpen: false,
        logout
      }, () => {
        if (logout) {
          resetCurrentAddress()
          attemptLogout()
        }
      })
    }, 100)
  }

  openLoginModal = () => {
    const { modalLoginOpen } = this.state

    if (!modalLoginOpen) {
      this.setState({
        modalLoginOpen: true
      })
    }
    this.props.setCartProceedHome(true);
    this.props.setCartProceedBySocialLogin(false);
  }

  closeLoginModal = () => {
    this.setState({
      modalLoginOpen: false
    })
  }

  _titleStyles = (text) => {
    const len = text.length > 30 ? 26 : 30
    const perc = text.length / 100
    const size = (len - (len * perc)).toFixed(0)

    return {
      fontSize: Number(size) < 16 ? 16 : Number(size)
    }
  }

  _renderLoginModal = () => {
    const { loggedIn, currentAddress, store } = this.props
    const { modalLoginOpen, checkoutRoute } = this.state
    const isMobile  = window.innerWidth < 768
    const { settings } = store;
    const onFinish = () => {
      if (checkoutRoute) {
        let redirectTo = {
          pathname: '/checkout'
        }
        if (!currentAddress) {
          redirectTo = {
            pathname: '/endereco',
            state: {
              selectAddress: true
            }
          }
        }

        this.setState({
          redirectTo
        })
      }
    }

    return (
      <Modal open={!loggedIn ? modalLoginOpen : false} showCloseIcon={false} onClose={this.closeLoginModal} styles={{modal: {borderRadius: 5, maxWidth: '50%'}}} center>
        <RegisterOrLogin mobile={isMobile} onFinish={onFinish} closeModal={this.closeLoginModal} appId={settings.config_facebook_appId} clientId={settings.config_google_clientId} />
      </Modal>
    )
  }

  _renderTitle = () => {
    const { store } = this.props
    const { titleDropdownOpen } = this.state
    const { settings, urls } = store
    const { config_name } = settings

    const titleStyles = this._titleStyles(config_name)

    if (urls && urls.length > 0) {
      const dropdownWidth = (Number(26 * config_name.length) / 2) + 45

      return (
        <React.Fragment>
          <h1 style={{cursor: 'pointer', ...titleStyles}} onClick={this.toggleTitleDropdown}>
            {config_name}
            <Arrow width={titleStyles.fontSize - 4} height={titleStyles.fontSize - 4} color="#fff" style={{transform: titleDropdownOpen ? 'rotate(180deg)' : 'rotate(0)', marginLeft: 10, marginTop: 5 }}/>
          </h1>

          <Dropdown
            className={`animated ${titleDropdownOpen ? 'flipInX' : 'flipOutX'}`}
            width={dropdownWidth}
            open={titleDropdownOpen}
            style={{
              top: '50%',
              left: 0,
              right: 'unset'
            }}
          >
            {
              urls.map((url, index) => {
                const { url_name, url_address } = url

                return <DropdownItem key={`store-url-${index}`} onClick={() => document.location.href = `${url_address}?noredirect`}>{url_name}</DropdownItem>
              })
            }
          </Dropdown>
        </React.Fragment>
      )
    } else {
      return (
        <h1 style={{cursor: 'pointer', ...titleStyles}} onClick={() => window.location = '/'}>{config_name}</h1>
      )
    }
  }

  render() {
    const { loggedIn, user, store, banners, location } = this.props
    const { settings } = store
    const { 
      config_name,
      config_address,
      config_store_city,
      config_store_number,
      config_navbar_color,
      config_logo,
      config_whatsapp_phone,
      config_whatsapp_button } = settings

    const { dropdownOpen, logout, metaTitle, metaDescription, redirectTo } = this.state

    const storeAddress = `${config_address}, ${config_store_number ? config_store_number + ',' : ''} ${config_store_city || ''}`
    const storeLogo = config_logo ? `${Config.s3Bucket}${config_logo}` : NoImage

    let bannerImages = []
    if (banners) {
      bannerImages = banners.map(p => {
        const { image } = p

        return `${Config.s3Bucket}${image}`
      })
    }

    return (
      <HeaderContainer>
        <Helmet>
          {metaTitle ? <meta name="title" content={metaTitle} /> : null}
          {metaDescription ? <meta name="description" content={metaDescription} /> : null}

          {metaTitle ? <meta property="og:title" content={metaTitle} /> : null}
          {metaDescription ? <meta property="og:description" content={metaDescription} /> : null}
          {window.location ? <meta property="og:url" content={window.location} /> : null}
          {storeLogo ? <meta property="og:image" content={storeLogo} /> : null}
        </Helmet>

        <Banner images={bannerImages} defaultColor={config_navbar_color} />
        <a href="/">
          <Logo image={storeLogo} alt={config_name} />
        </a>
        <InfoContainer>
          <Row>
            <Col xs={8}>
              { this._renderTitle() }

              <h4 style={{cursor: 'pointer'}} onClick={() => {
                window.open(`https://www.google.com/maps/place/${storeAddress}`, '_blank')
                GAEvents({category : 'Navigation',action : 'Click no texto de endereco da loja',label : 'Header'})
              } }>{storeAddress}</h4>
            </Col>
            <Col xs={4}>
              {
                loggedIn ? (
                  <UsernameContainer>
                    <h3>Olá, {user.firstname}</h3>
                    <Button 
                    color="secondary"
                    onClick={this.toggleDropdown}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: 40,
                      width: 40,
                      fontSize: 22,
                      borderRadius: 3,
                      padding: 0,
                      marginLeft: 10,
                      borderColor: '#fff',
                      color: '#fff'
                    }}
                    >
                      <FontAwesomeIcon icon={Icons.faBars} />
                    </Button>
                    <Dropdown className={`animated ${dropdownOpen ? 'flipInX' : 'flipOutX'}`} width={200} open={dropdownOpen}>
                      <Link to="/pedido">
                        <DropdownItem onClick={() => {
                          this.onDropdownClick()
                          GAEvents({category : 'Navigation',action : 'Click no botão Histórico de pedidos',label : 'Header'})
                        }}>Histórico de pedidos</DropdownItem>
                      </Link>
                      <Link to="/endereco">
                        <DropdownItem onClick={() => {
                          this.onDropdownClick()
                          GAEvents({category : 'Navigation',action : 'Click no botão Meus enderecos',label : 'Header'})
                        }}>Meus endereços</DropdownItem>
                      </Link>
                      <DropdownItem onClick={() => {
                        GAEvents({category : 'Navigation',action : 'Click no botão Sair',label : 'Header'})
                        this.onDropdownClick(true)
                      }}>Sair</DropdownItem>
                    </Dropdown>
                  </UsernameContainer>
                ) : (
                  <Button style={{height: 40, marginLeft: 50}} onClick={()=> {
                    this.openLoginModal()
                    GAEvents({ category: 'Login', action: 'Click no botão de Entrar',label : 'Login Modal' })
                  }}>Entrar</Button>
                )
              }
            </Col>
          </Row>
        </InfoContainer>
        <NavBar/>

        {logout ? <Redirect to="/produtos" /> : null}
        {location && location.pathname === '/' ? <Redirect exact from="/" to="/produtos" /> : null}

        {
          config_whatsapp_button && config_whatsapp_phone && config_whatsapp_phone.length > 0 ? (
            <WhatsAppButton href={`https://api.whatsapp.com/send?phone=55${config_whatsapp_phone.replace(/\D+/g, '')}&text=Olá, estava no site e preciso de auxílio`} target="_blank" onClick={() => { GAEvents({ category: 'Contato', action: 'Click no botão do WhatsApp' }) }}>
              <FontAwesomeIcon icon={BrandIcons.faWhatsapp} size="2x" />
            </WhatsAppButton>
          ) : null
        }

        {
          // Login modal
          this._renderLoginModal()
        }

        { redirectTo && redirectTo !== location.pathname ? <Redirect to={redirectTo} /> : null }
      </HeaderContainer>
    )
  }
}

const mapStateToProps = ({ login, startup, banner, product, cart, address }) => ({
  fetching: startup.fetching,
  store: startup.store,
  user: login.user,
  banners: banner.banners,
  productFetching: product.fetching,
  productDetail: product.product,
  loggedIn: isLogged(login),
  showLoginDialog: login.showLoginDialog,
  cartProceed: cart.proceed,
  currentAddress: address.currentAddress,
})

const mapPropsToDisptach = dispatch => ({
  attemptLogout: () => dispatch(Creators.logoutRequest()),
  attemptGetBanners: () => dispatch(Creators.bannerListRequest()),
  resetCurrentAddress: () => dispatch(Creators.setCurrentAddressRequest(null)),
  setCartProceed: (proceed) => dispatch(Creators.cartSetProceed(proceed)),
  setLoginDialog: (show) => dispatch(Creators.setLoginDialogRequest(show)),

  setCartProceedHome: (proceedHome) => dispatch(Creators.cartProceedHome(proceedHome)),
  setCartProceedBySocialLogin: (proceedCheckout) => dispatch(Creators.cartProceedBySocialLogin(proceedCheckout)),

})

export default withRouter(connect(mapStateToProps, mapPropsToDisptach)(Header))