import React from 'react'
import { SVG } from './styles.js'

const Wallet = ({ width = 30, height = 27, color = "#707070", style = {}, onClick = () => {} }) => (
  <SVG 
    xmlns="http://www.w3.org/2000/svg" 
    width={width}
    height={height}
    style={style}
    onClick={onClick}
    viewBox="0 0 30 27"
  >
    <g className="b">
      <g className="c" fill="none" transform="translate(0 7.811)">
        <path className="e" d="M22.79,19.581H5a5.006,5.006,0,0,1-5-5V0H27.79V6.7H21.418a3.032,3.032,0,1,0,0,6.063H27.79v1.823A5.006,5.006,0,0,1,22.79,19.581Z"/>
        <path className="f" fill={color} d="M 22.7898006439209 18.58130073547363 C 24.99563217163086 18.58130073547363 26.79020118713379 16.78713035583496 26.79020118713379 14.58180046081543 L 26.79020118713379 13.75930023193359 L 21.4182014465332 13.75930023193359 C 19.19489097595215 13.75930023193359 17.38610076904297 11.95051002502441 17.38610076904297 9.727200508117676 C 17.38610076904297 7.504390239715576 19.19489097595215 5.696000576019287 21.4182014465332 5.696000576019287 L 26.79020118713379 5.696000576019287 L 26.79020118713379 1.000000357627869 L 1.000001549720764 1.000000357627869 L 1.000001549720764 14.58180046081543 C 1.000001549720764 16.78713035583496 2.7945716381073 18.58130073547363 5.000401496887207 18.58130073547363 L 22.7898006439209 18.58130073547363 M 22.7898006439209 19.58130073547363 L 5.000401496887207 19.58130073547363 C 2.243171453475952 19.58130073547363 1.547241254229448e-06 17.33852958679199 1.547241254229448e-06 14.58180046081543 L 1.547241254229448e-06 3.677368169974216e-07 L 27.79020118713379 3.677368169974216e-07 L 27.79020118713379 6.696000576019287 L 21.4182014465332 6.696000576019287 C 19.74629211425781 6.696000576019287 18.38610076904297 8.055789947509766 18.38610076904297 9.727200508117676 C 18.38610076904297 11.39911079406738 19.74629211425781 12.75930023193359 21.4182014465332 12.75930023193359 L 27.79020118713379 12.75930023193359 L 27.79020118713379 14.58180046081543 C 27.79020118713379 17.33852958679199 25.54703140258789 19.58130073547363 22.7898006439209 19.58130073547363 Z"/>
      </g>
      <g className="c" fill="none" transform="translate(0 4.992)">
        <path className="e" d="M27.79,3.79H25.616L21.826,0H24a3.8,3.8,0,0,1,3.79,3.789Zm-24.556,0H0A3.794,3.794,0,0,1,3.79,0H6.422A6.986,6.986,0,0,0,4.883,1.165,6.885,6.885,0,0,0,3.235,3.789Z"/>
        <path className="f" fill={color} d="M 26.60470962524414 2.789905071258545 C 26.23036766052246 1.818626284599304 25.32787322998047 1.10767650604248 24.25132179260254 1.01120662689209 L 26.03001976013184 2.789905071258545 L 26.60470962524414 2.789905071258545 M 2.557823896408081 2.789905071258545 C 2.84959864616394 2.145484209060669 3.224526166915894 1.547510862350464 3.678722620010376 1.002191305160522 C 2.54063892364502 1.047064781188965 1.575469732284546 1.776862502098083 1.185255527496338 2.789905071258545 L 2.557823896408081 2.789905071258545 M 27.79019927978516 3.790004968643188 L 27.78919982910156 3.789905071258545 L 25.61579895019531 3.789905071258545 L 21.82589912414551 4.987716692994582e-06 L 24.00029945373535 4.987716692994582e-06 C 26.08915901184082 4.987716692994582e-06 27.78929901123047 1.699744939804077 27.79019927978516 3.789005041122437 L 27.79019927978516 3.790004968643188 Z M 3.234599590301514 3.789905071258545 L 3.233699560165405 3.789905071258545 L -3.601074354264711e-07 3.789905071258545 C -3.601074354264711e-07 1.699694991111755 1.700589656829834 4.987716692994582e-06 3.789899587631226 4.987716692994582e-06 L 6.422399520874023 4.987716692994582e-06 C 5.863159656524658 0.3116049766540527 5.345199584960938 0.7028049826622009 4.883399486541748 1.164605021476746 C 4.135059833526611 1.912935018539429 3.580329656600952 2.795914888381958 3.234599590301514 3.789005041122437 L 3.234599590301514 3.789905071258545 Z"/>
      </g>
      <g className="d" fill={color} stroke={color} transform="translate(18.106 13.799)">
        <path className="e" d="M3.649,0h8.842a0,0,0,0,1,0,0V7.3a0,0,0,0,1,0,0H3.649A3.649,3.649,0,0,1,0,3.649v0A3.649,3.649,0,0,1,3.649,0Z"/>
        <path className="a" fill="none" d="M3.649.5h8.278a.064.064,0,0,1,.064.064v6.17a.064.064,0,0,1-.064.064H3.649A3.149,3.149,0,0,1,.5,3.649v0A3.149,3.149,0,0,1,3.649.5Z"/>
      </g>
      <g className="c" fill="none" transform="translate(12.112 0)">
        <path className="e" d="M14.919,8.781H5.776L0,3.559,3.482.411a1.671,1.671,0,0,1,2.2,0L14.79,8.648a1.484,1.484,0,0,1,.129.133Z"/>
        <path className="f" fill={color} d="M 12.33911037445068 7.78110408782959 L 5.007090091705322 1.152833938598633 C 4.898079872131348 1.054273962974548 4.746409893035889 1.000003933906555 4.580009937286377 1.000003933906555 C 4.413569927215576 1.000003933906555 4.261789798736572 1.054333925247192 4.152780055999756 1.152833938598633 L 1.491165161132813 3.559023380279541 L 6.161440849304199 7.78110408782959 L 12.33911037445068 7.78110408782959 M 14.91919994354248 8.78110408782959 L 14.91888999938965 8.78110408782959 L 5.776430130004883 8.78110408782959 L -2.822876155050835e-08 3.559023857116699 L 3.482159852981567 0.41103395819664 C 3.775480031967163 0.1459739506244659 4.165369987487793 3.951263352064416e-06 4.580009937286377 3.951263352064416e-06 C 4.99468994140625 3.951263352064416e-06 5.384530067443848 0.1459739506244659 5.677720069885254 0.41103395819664 L 14.78960990905762 8.648344039916992 C 14.83563041687012 8.689973831176758 14.87917041778564 8.734563827514648 14.91899967193604 8.7808837890625 L 14.91919994354248 8.78110408782959 Z"/>
      </g>
      <g className="c" fill="none" transform="translate(14.129 0)">
        <path className="e" d="M12.918,8.781H8.033L0,1.6,1.333.411A1.659,1.659,0,0,1,2.443,0a1.659,1.659,0,0,1,1.11.411l9.365,8.37Z"/>
        <path className="f" d="M 8.414258003234863 7.781137466430664 L 10.29897880554199 7.781122207641602 L 2.887114763259888 1.156720757484436 C 2.774024724960327 1.055660724639893 2.616294622421265 1.000000834465027 2.442984819412231 1.000000834465027 C 2.269664764404297 1.000000834465027 2.111944675445557 1.05565083026886 1.998934745788574 1.156640768051147 L 1.500591635704041 1.602079510688782 L 8.414258003234863 7.781137466430664 M 12.91827487945557 8.781340599060059 L 12.91800498962402 8.781101226806641 L 8.032514572143555 8.781140327453613 L 4.728088242700323e-06 1.602120757102966 L 1.332514762878418 0.4110707640647888 C 1.629134774208069 0.1459907740354538 2.023504734039307 7.763671874272404e-07 2.442984819412231 7.763671874272404e-07 C 2.862454652786255 7.763671874272404e-07 3.256824731826782 0.1459907740354538 3.553454637527466 0.4110707640647888 L 12.91849517822266 8.781140327453613 L 12.91827487945557 8.781340599060059 Z"/>
      </g>
      <g className="c" fill="none" transform="translate(1.158 1.409)">
        <path className="e" d="M17.266,7.372H0L7.6.392a1.551,1.551,0,0,1,2.062,0l7.6,6.98Z"/>
        <path className="f" fill={color} d="M 14.69830417633057 6.372003555297852 L 8.987695693969727 1.128753423690796 C 8.897286415100098 1.045723438262939 8.771326065063477 1.000003457069397 8.633026123046875 1.000003457069397 C 8.494706153869629 1.000003457069397 8.368696212768555 1.045753479003906 8.278225898742676 1.128813505172729 L 2.567702054977417 6.372003555297852 L 14.69830417633057 6.372003555297852 M 17.26605606079102 7.372003555297852 L 17.26565551757813 7.372003555297852 L 17.26605606079102 7.372003555297852 Z M 17.26605606079102 7.372003555297852 L -3.876953087456059e-06 7.372003555297852 L 7.601906299591064 0.3922134339809418 C 7.877376079559326 0.139293447136879 8.243546485900879 3.446579057708732e-06 8.633026123046875 3.446579057708732e-06 C 9.022505760192871 3.446579057708732e-06 9.388675689697266 0.139293447136879 9.66408634185791 0.3922134339809418 L 17.26577568054199 7.371773242950439 L 17.26605606079102 7.372003555297852 Z"/>
      </g>
    </g>
  </SVG>
)

export default Wallet
