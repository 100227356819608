import styled  from 'styled-components'

export const Error = styled.p`
color: #b73535;
font-size: 14px;
margin-top: 2px;
margin-left: 5px;
`

export const FacebookContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ContentError = styled.div`
  width: ${({ mobile }) => mobile ? '100%' : '50%'};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px 20px;
`

export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-size: 16px;
    flex-shrink: inherit;
    font-family: Gilmer
  }
`