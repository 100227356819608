import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AllHtmlEntities } from 'html-entities'
import Body from '@components/MobileBody'
import { Creators } from '../../actions'

class AboutUs extends Component {
  constructor(props) {
    super(props)

    this.htmlEntities = new AllHtmlEntities()
  }

  componentDidMount() {
    const { content, fetching, attemptGetAboutUs } = this.props

    // If we have not load the content yet, let's do this immediately
    if (!content) {
      attemptGetAboutUs()
    } else {
      // Otherwise, let's just update a few moment after loads
      if (!fetching) {
        setTimeout(() => {
          attemptGetAboutUs()
        }, 5000)
      }
    }
  }
  
  render() {
    const { content } = this.props

    let htmlContent = content || ''
    const htmlParsed = this.htmlEntities.decode(htmlContent)

    if (htmlParsed.length > 0) {
      htmlContent = htmlParsed
    }

    return (
      <Body>
        { content ? (<div dangerouslySetInnerHTML={{__html: htmlContent }} />) : null}
      </Body>
    )
  }
}

const mapStateToProps = ({ aboutUs }) => ({
  fetching: aboutUs.fetching,
  content: aboutUs.content
})

const mapDispatchToProps = dispatch => ({
  attemptGetAboutUs: () => dispatch(Creators.aboutUsRequest())
})

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs)
