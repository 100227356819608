import { call, put,select } from 'redux-saga/effects'
import Config from '../config'
import { Creators } from '../actions'
import { loggedToken,isLogged } from '../redux/LoginRedux'
const userToken = ({ login }) => isLogged(login) ? loggedToken(login) : null

export function * startup(api) {
  const token = yield select(userToken)

  if (process.env.NODE_ENV === "development" && Config.localOrigin) {
    api.authSetHeaders({
      'X-Origin': Config.localOrigin
    })
  }
  

  const response = yield call(api.startup)
  const { ok, data } = response

  if (ok) {
    const { store,accessToken } = data

    api.setHeaders({
      'Authorization' : `Bearer ${token || accessToken}`
    })

    api.authSetHeaders({'Authorization' : `Bearer ${token || accessToken}`})
    api.paymentSetHeaders({'Authorization' : `Bearer ${token || accessToken}`})
    
    yield put(Creators.startupSuccess(store,accessToken))
  } else {
    const { error } = data

    yield put(Creators.startupFailure(error))
  }
}
