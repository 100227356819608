import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Icons from '@fortawesome/free-solid-svg-icons'
import Button from '@components/Button'
import { floatToBRL } from '../../helpers/MoneyFormat'
import { Text, BtnGroup } from './styles'

export default class InsufficientCartItems extends Component {
  static propTypes = {
    total: PropTypes.number.isRequired,
    minimumValue: PropTypes.number.isRequired,
    onSelectProducts: PropTypes.func,
    onSelectAddress: PropTypes.func
  }

  static defaultProps = {
    onSelectProducts: () => {},
    onSelectAddress: () => {}
  }

  render() {
    const { total, minimumValue, onSelectProducts, onSelectAddress } = this.props
    const accomplish = minimumValue - total

    return (
      <div style={{textAlign: 'center'}}>
        <FontAwesomeIcon icon={Icons.faFrownOpen} size="10x" color="#a8a8a8" style={{marginBottom: 15, marginTop: 15}} />

        <Text fontSize={24} bold>
          O pedido mínimo para este endereço é {floatToBRL(minimumValue)}
        </Text>
        <Text>
          Resta {floatToBRL(accomplish)} para alcançar o valor mínimo de {floatToBRL(minimumValue)}
        </Text>
        
        <hr style={{marginTop: 30}} />

        <BtnGroup>
          <Button onClick={onSelectProducts}>Adicionar mais produtos</Button>
          <Button color="secondary" onClick={onSelectAddress} style={{paddingRight: 25, paddingLeft: 25}}>Escolher outro endereço</Button>
        </BtnGroup>
      </div>
    )
  }
}
