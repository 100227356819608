import React from 'react'
import { SVG } from './styles.js'
import './index.css'

const Marker = ({ width = 48, height = 74 }) => (
  <SVG 
    xmlns="http://www.w3.org/2000/svg" 
    width={width}
    height={height}
    viewBox="0 0 48 74"
  >
    <g transform="translate(2.504 2.508)">
      <path className="marker-a" d="M22.147,70.1H35.674c.828,0,1.012.212.92.776-.092,1.412,0,2.823,0,4.235,0,.423-.092.565-.644.565H8.436c-.552,0-.736-.141-.736-.565V70.665c0-.494.184-.565.736-.565C13.037,70.171,17.638,70.1,22.147,70.1Z" transform="translate(-0.58 -3.921)"/>
      <g transform="translate(0 0)">
        <path className="marker-b" d="M21.161.094A21.331,21.331,0,0,1,35.056,4.787a21.073,21.073,0,0,1,7.638,12.238,21.854,21.854,0,0,1-1.2,12.607c-1.1,2.853-2.392,5.613-3.773,8.374a131.945,131.945,0,0,1-7.362,11.778c-1.656,2.392-3.5,4.785-5.245,7.085C24.106,58.157,23,59.446,21.99,60.734c-.276.368-.46.368-.828,0-1.656-2.208-3.4-4.325-5.061-6.533-1.932-2.484-3.681-5.153-5.521-7.73A83.345,83.345,0,0,1,3.31,33.681,37.853,37.853,0,0,1,.273,24.755a18.721,18.721,0,0,1,.552-9.018A21.385,21.385,0,0,1,4.966,7.915,21.31,21.31,0,0,1,17.3.462,9.521,9.521,0,0,1,21.161.094Z" transform="translate(0.037 -0.022)"/>
        <ellipse className="marker-a" cx="8.757" cy="8.757" rx="8.757" ry="8.757" transform="translate(12.359 13.161)"/>
      </g>
    </g>
  </SVG>
)

export default Marker
