import styled from 'styled-components'

export const Title = styled.h2`
  line-height: 15px;
  font-family: Gilmer-Bold;
  font-size: 18px;
`
export const Text = styled.span`
  font-family: Gilmer-Light;
  font-size: 14px;
  color: #A8A8A8;
`
