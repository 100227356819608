import React from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Info, PrimaryText, SecondaryText, DropdownHeader, Dropdown, InfoContainer } from './styles.js'
import { Grid, Row, Col } from 'react-flexbox-grid'
import Arrow from '@components/Icons/Arrow'
import './index.css'

class Container extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    icon: PropTypes.any,
    primaryText: PropTypes.string,
    secondaryText: PropTypes.string,
    dropdown: PropTypes.bool,
    visible: PropTypes.bool,
    onClick: PropTypes.func
  }

  static defaultProps = {
    visible: false
  }

  constructor(props) {
    super(props)

    this.state = {
      visible: props.visible,
      redirectTo: null
    }
  }

  toggleDropwdown = () => {
    const { dropdown,onClick } = this.props
    if (!dropdown) return
    onClick()
    // Native mobile app
    const isMyPharmaBase = window.navigator ? window.navigator.userAgent === 'mypharmaBase' : false

    if (!isMyPharmaBase) {
      this.setState(state => ({ visible: !state.visible }))
    } else {
      const { name } = this.props

      this.setState({
        redirectTo: {
          path: `/info-dropdown/${name}`,
          push: true
        }
      })
    }
  }

  closeDropdown = () => {
    // Native mobile app
    const isMyPharmaBase = window.navigator ? window.navigator.userAgent === 'mypharmaBase' : false

    if (!isMyPharmaBase) {
      this.setState(state => ({ visible: false }))
    } else {
      this.setState({
        redirectTo: {
          path: '/produtos',
          push: false
        }
      })
    }
  }

  render() {
    const { icon, primaryText, secondaryText, dropdown = false, dropdownContent, children } = this.props
    const { visible, redirectTo } = this.state

    return (
      <InfoContainer>
        <Info onClick={this.toggleDropwdown} dropdown={dropdown}>
          <Grid>
            <Row middle="xs">
              <Col xs={12}>
                <Row>
                  <Col xs={dropdown ? 9 : 12} style={{ maxHeight: 18 }}>
                    {icon}
                  </Col>
                  {dropdown &&
                    <Col xs={3}>
                      <Arrow width={10} height={5} style={{ transform: visible ? 'rotate(180deg)' : 'rotate(0)' }} />
                    </Col>
                  }
                </Row>
              </Col>
            </Row>
            <Row middle="xs">
              <Col xs={12}>
                <Row>
                  <PrimaryText>{primaryText}</PrimaryText>
                </Row>
                <Row>
                  <SecondaryText>{secondaryText}</SecondaryText>
                </Row>
              </Col>
            </Row>
          </Grid>
        </Info>
        <div style={{ position: 'relative' }}>
          {dropdown && (
            <React.Fragment>
              <DropdownHeader visible={visible}>
                <i className="material-icons" onClick={this.closeDropdown}>close</i>
              </DropdownHeader>
              <Dropdown visible={visible} >
                {visible ? (dropdownContent || children) : null}
              </Dropdown>
            </React.Fragment>
          )}
        </div>

        {
          // Redirect to dropdown route when we are in native app
          redirectTo ? <Redirect to={redirectTo.path} push={redirectTo.push || false} /> : null
        }
      </InfoContainer>
    )
  }
}

export default Container