import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware, connectRouter } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import { persistStore, persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/es/storage'

import { reducers } from '../redux'
import rootSaga from '../sagas'

const config = {
  key: 'root',
  storage,
  blacklist: [
    'router',
    'product',
    'category',
    'delivery',
    'payment',
    'order',
    'banner',
    'featuredProduct',
    'account',
    'search'
  ]
}

export const history = createBrowserHistory()

reducers['router'] = connectRouter(history)

const reducer = persistCombineReducers(config, reducers)

const middleware = routerMiddleware(history)

const sagaMiddleware = createSagaMiddleware()

export var store

export function configureStore() {
  const composeEnchancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  store = createStore(reducer, composeEnchancers(applyMiddleware(middleware, sagaMiddleware)))
  const persistor = persistStore(store)

  applyMiddleware(sagaMiddleware)
  sagaMiddleware.run(rootSaga)
  
  return { store, persistor }
}
