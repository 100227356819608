import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Icons from '@fortawesome/free-solid-svg-icons'
import { AddressCardContainer, InfoContainer, ActionContainer, AddressPrimary, AddressSecondary } from './styles'
import './index.css'

export default class MobileAddressOption extends Component {
  static propTypes = {
    street: PropTypes.string,
    city: PropTypes.string,
    uf: PropTypes.string,
    selected: PropTypes.bool,
    onSelect: PropTypes.func
  }

  static defaultProps = {
    selected: false,
    onSelect: () => {}
  }

  constructor(props) {
    super(props)

    this.state = {
      className: 'animated slideInLeft'
    }
  }

  render() {
    const {
      street,
      city,
      uf,
      number,
      selected,
      onSelect,
    } = this.props
    const {  className } = this.state

    return (
        <AddressCardContainer className={className}>
          <button style={{display: "flex" ,flexDirection: "row", alignItems: "center" ,background: "none", outline: 0, border: 0, padding:0, margin:0}} onClick={() => onSelect()} >
            <FontAwesomeIcon color="#a8a8a8" icon={Icons.faStore} size={"2x"} style={{marginRight: 5}}/>
            <InfoContainer>
              <AddressPrimary>
                {`RETIRAR NA LOJA`}
              </AddressPrimary>
              <AddressSecondary>
              {street} - {number} - {city} {uf}
              </AddressSecondary>
            </InfoContainer>     
          </button>
            <ActionContainer>
              <FontAwesomeIcon icon={Icons.faCheckCircle} size="2x" color={selected ? '#00BF91' : ''} style={{marginRight: 10}} onClick={() => onSelect()}/>
            </ActionContainer>
        </AddressCardContainer>
    )
  }
}
