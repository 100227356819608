import styled from 'styled-components'
import InputMasked from "react-input-mask";

export const Title = styled.h2`
  font-family: ${({ bold }) => `Gilmer-${bold ? 'Bold' : 'Light'}`};
  font-size: ${({ fontSize }) => fontSize ? `${fontSize}px` : '15px'};
  text-align: center;
  color: #707070;
  line-height: 20px;
  margin: 20px 0 5px 0;
`
export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  input {
    font-size: 14px;
    margin-bottom: ${({ mobile }) => mobile ? '0' : '10px'};
    height: 10px;
  }
  button {
    width: ${({ mobile }) => mobile ? '100%' : ''};
    margin-top: ${({ mobile }) => mobile ? '10px' : '0'};
  }
   a {
     margin-top: 10px;
     text-align:center;
     align-self: center;
   }
`;

export const InputMask = styled(InputMasked)`
  height: ${({ height }) => height ? `${height}px` : '26px'};
  width: ${({ width, textAlign }) => width ? `${width}px` : textAlign === 'center' ? '100%' : 'calc(100% - 10px)'};
  border-radius: 10px;
  border: 1px solid #707070; 
  color: #707070;
  font-family: Gilmer;
  font-size: ${({ fontSize }) => fontSize ? `${fontSize}px` : '22px'};
  text-align: ${({ textAlign }) => textAlign || 'left'};
  outline: none;
  background-color: #FFFFFF;
  padding: ${({ textAlign }) => textAlign === 'center' ? '15px 0 15px 0' : '15px 0 15px 10px'};
  margin-bottom: 10px;

  &:focus {
    border: 1px solid ${({ validfield }) => validfield ? '#00BF91' : '#c0392b'};
  }

  &::placeholder {
    font-family: Gilmer-Light;
  }
`;

export const StoreList = styled.ul`
  display: grid;
  grid-template-columns: ${({ mobile }) => mobile ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)'};
  grid-gap: 15px;
  list-style: none;

  li {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: solid 1px rgba(0,0,0,.09);
    border-radius: 5px;
    box-shadow: 0 0 4px 0 rgba(0,0,0,.05);
    transition: 0.2s background;
    font-family: Gilmer;
    text-align: center;


       :hover {
         background: #EEE;
        }

        strong {
        margin-top: 10px;
        font-size: 16px;
        font-weight: bold;
      }
      span {
        font-size: 12px;
        font-weight: bold;
        margin: auto;
        margin-bottom: 5px;  
      }    

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #00A881;
      outline: 0;
      border: none;
      cursor: pointer;
      width: 100%;
      height: 40px;
      border-radius: 5px;
      
      font-size: 15px;
      font-family: Gilmer;
      font-weight: bold;
      color: #FFF;
      font-family: Gilmer;
    }
  }
`;

export const Link = styled.a`
  font-size: 14px;
  font-weight: bold;
  color: #707070;
  width: 120px;
  margin: 0 0 10px 0; 


`;

export const ErrorContainer = styled.p`
  font-size: 14px;
  color: #b73535;
`;