import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'react-flexbox-grid'
import Body from '@components/MobileBody'
import CategoryCard from '@components/CategoryCard'
import { Creators } from '../../actions'

class Category extends Component {
  componentDidMount() {
    const { fetching, categories, getCategories } = this.props

    if (!fetching && categories === null) {
      getCategories()
    }
  }

  _renderCategories = () => {
    const { categories } = this.props

    if (categories) {
      return categories.map((category, index) => {
        const { name, image, slug, subcategories } = category

        return (
          <Col key={`category-${index}`} xs={12} lg={4} style={{marginBottom: 15}}>
            <CategoryCard name={name} image={image} categorySlug={slug} subcategories={subcategories} />
          </Col>
        )
      })
    }
  }

  render() {
    return (
      <Body>
        <Row>
          {this._renderCategories()}
        </Row>
      </Body>
    )
  }
}

const mapStateToProps = ({ category }) => ({
  fetching: category.fetching,
  categories: category.categories
})

const mapDispatchToProps = dispatch => ({
  getCategories: () => dispatch(Creators.categoryListRequest())
})

export default connect(mapStateToProps, mapDispatchToProps)(Category)
