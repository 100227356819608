export class CreateCardTokenException extends Error {
  constructor(errors = {}, ...params) {
    super(...params)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CreateCardTokenException)
    }

    this.name = 'CreateCardTokenException'
    this.errors = errors
  }
}

export const GetCardBrand = (cardNumber) => {
  return new Promise((resolve, reject) => {
    if (cardNumber.length < 6) resolve('')

    window._PagSeguroDirectPayment.getBrand({
      cardBin: Number(cardNumber),
      success: function({brand: { name }}) {
        resolve(name)
      },
      error: function(response) {
        reject(response)
      }
    })
  })
}

export const GetInstallments = ({ amount, maxInstallmentNoInterest = 2, brand = '' }) => {
  return new Promise((resolve, reject) => {
    if (brand.length === 0) reject('Invalid card brand')
    else {
      window._PagSeguroDirectPayment.getInstallments({
        amount,
        brand,
        success: function({ installments }) {
          resolve(installments[brand])
        },
        error: function(response) {
          reject(response)
        }
      })
    }
  })
}

export const CreateCardToken = (options) => {
  return new Promise((resolve, reject) => {
    const { cardNumber, cardBrand, cardCVC, cardExpire } = options
    const [ expireMonth, expireYear ] = cardExpire.split('/')

    window._PagSeguroDirectPayment.createCardToken({
      cardNumber: cardNumber.replace(/\s/g,''), // Número do cartão de crédito
      brand: cardBrand, // Bandeira do cartão, existe um método helper na SDK do pagseguro pra obter essa informação a partir dos 6 primeiros dígitos do cartão
      cvv: cardCVC, // CVV do cartão
      expirationMonth: expireMonth, // Mês da expiração do cartão
      expirationYear: expireYear, // Ano da expiração do cartão, é necessário os 4 dígitos.
      success: function({ card: { token }}) {
          resolve(token)
      },
      error: function({errors}) {
        let _errors = []
        Object.keys(errors).forEach(k => {
          _errors.push({
            code: Number(k) || k,
            error: errors[k]
          })
        })
        reject(new CreateCardTokenException(_errors, 'CREATE_CARD_TOKEN_ERROR'))
      }
    })
  })
}
