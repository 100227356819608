import React from 'react'
import { SVG } from './styles.js'

const Contact = ({ height = 20, width = 20, color = '#fff', opacity = 0.80 }) => (
  <SVG 
    xmlns="http://www.w3.org/2000/svg" 
    width={width}
    height={height}
    opacity={opacity}
    viewBox="0 0 30 30"
  >
    <path 
      d="M72.518-1958.982H54a3.5,3.5,0,0,1-3.5-3.5V-1981a3.5,3.5,0,0,1,3.5-3.5H72.518a3.5,3.5,0,0,1,3.5,3.5v18.518A3.5,3.5,0,0,1,72.518-1958.982Zm-14.2-21.323a4.692,4.692,0,0,0-2.367.843,3.8,3.8,0,0,0-1.124,2.385,8.326,8.326,0,0,0,.985,4.939,20.4,20.4,0,0,0,7.1,7.413,13.556,13.556,0,0,0,5.139,1.5,2.526,2.526,0,0,0,.766-.1c2.78-.82,2.889-2.363,2.941-3.1a2.559,2.559,0,0,1,.031-.295c.1-.492-.9-1.043-1.774-1.529l-.008,0c-.143-.079-.278-.154-.407-.228-.244-.14-.46-.293-.669-.44a2.147,2.147,0,0,0-1.1-.529.793.793,0,0,0-.431.133,4.148,4.148,0,0,0-1.212,1.387c-.23.389-.382.646-.776.662-2.716-.111-6.084-5-6.44-5.652s-.092-1.269.855-1.994c.784-.6,1.224-1.185,1.069-1.423-.033-.051-.16-.29-.335-.62l0,0a20.979,20.979,0,0,0-1.948-3.291A.677.677,0,0,0,58.316-1980.305Z"
      transform="translate(-50.5 1984.5)"
      fill={color}
      opacity={opacity}
    />
  </SVG>
)

export default Contact
