import ReactGA from 'react-ga'
import { store } from '../store/configureStore'
import { storeConfig } from '../redux/StartupRedux'

const getConfig = ({ startup }) => storeConfig(startup)

export function addImpression(products, action) {
    if (!store) return null

    const config = getConfig(store.getState())

    if (config && products) {
        ReactGA.ga('require', 'ec')
        products.map(({ ean, model, laboratory, list = 'Home', categories, price }, index) => {
            return ReactGA.ga('ec:addImpression', {
                'id': ean.toString(), // Product ID (string).
                'name': model, // Product name (string).
                'brand': laboratory ? laboratory.name : 'Não possui', // Product laboratory (string).
                'list': list, // Product list (string).
                'position': index, // Product position (number).
                'category': categories && categories.length > 0 ? categories[0].name : 'Não possui',
                'price': price.toFixed(2)
            });
        })
        if (action) {
            ReactGA.ga("ec:setAction", action)
        }
        ReactGA.ga('send', 'pageview')
    }
}

export function addProduct({ ean, model, laboratory, category, list, position, price, quantity }) {
    if (!store) return null
    const config = getConfig(store.getState())

    if (config) {
        ReactGA.ga('require', 'ec')
        ReactGA.ga('ec:addProduct', {
            'id': ean.toString(), // Product ID (string).
            'name': model, // Product name (string).
            'category': category ? category : 'Não possui', // Product category (string).
            'brand': laboratory ? laboratory : 'Não possui', // Producgigt brand (string).
            'position': position,
            'list': list,
            'price': price,
            'quantity ': quantity
        })
        ReactGA.ga('ec:setAction', 'click', {
            'list': list
        })
        ReactGA.ga("send", "event", list, "click", "");
    }

}

export function viewProduct({ ean, model, laboratory, categories, position, price, quantity }) {
    if (!store) return null
    const config = getConfig(store.getState())

    if (config) {
        ReactGA.ga('require', 'ec')
        ReactGA.ga('ec:addProduct', {
            'id': ean.toString(), // Product ID (string).
            'name': model, // Product name (string).
            'category': categories && categories.length > 0 ? categories[0].name : 'Não possui', // Product category (string).
            'brand': laboratory ? laboratory : 'Não possui', // Producgigt brand (string).
            'position': 0,
            'price': price,
            'quantity ': quantity
        })
        ReactGA.ga("ec:setAction", "detail");
        ReactGA.ga("send", "pageview")
    }

}

export function purchase({ id, affiliation, revenue, tax, shipping, coupon }, products) {

    if (!store) return null

    const config = getConfig(store.getState())

    if (config) {

        ReactGA.ga('require', 'ec')
        products.map(({ ean, quantity, model, price, categories, manufacturer }, index) => {
            return ReactGA.ga('ec:addProduct', {
                "id": '' + ean,
                "name": model,
                "price": Number(price).toFixed(2),
                "brand": manufacturer && manufacturer.length > 0 ? manufacturer[0].name : 'Não possui',
                "category": categories && categories.length > 0 ? categories[0].name : 'Não possui',
                "variant": "Não possui",
                "dimension1": "",
                "quantity": quantity,
                "position": index,
            })
        })
        ReactGA.ga('ec:setAction', 'purchase', { // Transaction details are provided in an actionFieldObject.
            'id': '' + id, // (Required) Transaction id (string).
            'affiliation': affiliation, // Affiliation (string).
            'revenue': revenue, // Revenue (number).
            'tax': tax, // Tax (number).
            'shipping': shipping, // Shipping (number).
            'coupon': coupon ? coupon : 'Sem coupon' // Transaction coupon (string).
        });
        ReactGA.ga('send', 'pageview')
        setStep(3)
        ReactGA.ga('send', 'pageview')
    }
}

export function setStep(step, option) {

    if (!store) return null

    const config = getConfig(store.getState())

    if (config) {
        ReactGA.ga('require', 'ec')
        ReactGA.ga('ec:setAction', 'checkout', {
            'step': step, // A value of 1 indicates this action is first checkout step.
            'option': option
        })
        ReactGA.ga('send', 'pageview')
    }
}

export function addToCart({ ean, model, price = 0.00, quantity = 0, categories, laboratory, view }) {
    if (!store) return null

    const config = getConfig(store.getState())
    if (config) {
        if (!view) {
            return
        }
        ReactGA.ga('require', 'ec')
        ReactGA.ga('ec:addProduct', {
            'id': '' + ean,
            'name': model,
            'category': categories && categories.length > 0 ? categories[0].name : 'Não possui',
            'brand': laboratory ? laboratory : 'Não possui',
            'price': price.toFixed(2),
            'quantity': 1
        });
        ReactGA.ga('ec:setAction', 'add');
        ReactGA.ga("send", "event", view, "click", "addToCart");
    }
}