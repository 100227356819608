import React from 'react'
import { SVG } from './styles.js'

const List = ({ height = 28, width = 28, color = '#ccc', opacity = 0.80, style = {} }) => (
  <SVG 
    xmlns="http://www.w3.org/2000/svg" 
    width={width}
    height={height}
    opacity={opacity}
    style={style}
    viewBox="0 0 24 24">
      <g fill="none"><path d="M0 0h24v24H0V0z"/>
        <path opacity={opacity} d="M0 0h24v24H0V0z"/>
      </g>
      <path 
        opacity={opacity}
        fill={color}
        d="M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7zm-4 6h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7z"
      />
  </SVG>
)

export default List
