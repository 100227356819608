import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Container, Button, Dropdown, DropdownItem } from './styles'

/**
 * Selectable component
 */
export class SelectableDropdown extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    icon: PropTypes.object,
    dropdown: PropTypes.bool,
    color: PropTypes.string,
    width: PropTypes.any,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onClick: PropTypes.func
  }

  static defaultProps = {
    placeholder: '',
    icon: null,
    color: '#ccc',
    width: null,
    selected: false,
    disabled: false,
    dropdown: false,
    onClick: () => { }
  }

  constructor(props) {
    super(props)

    this.state = {
      selected: props.selected,
      open: false, // Dropdown closed by default
      hide: true
    }
  }

  toggleDropwdown = () => {
    this.setState(state => ({
      open: !state.open
    }), () => {
      const { hide } = this.state

      if (hide) {
        this.setState({
          hide: false
        })
      } else {
        setTimeout(() => {
          this.setState({
            hide: true
          })
        }, 1000)
      }
    })
  }

  closeDropdown = () => {
    this.setState({
      open: false
    }, () => {
      setTimeout(() => {
        this.setState({
          hide: true
        })
      }, 1000)
    })
  }

  componentWillReceiveProps(newProps) {
    const { selected } = newProps

    this.setState({
      selected
    })
  }

  _handleSelect = () => {
    const { onChange } = this.props

    this.setState(state => ({
      selected: !state.selected
    }), () => {
      if (onChange !== undefined) {
        onChange(this.state.selected)
      }
    })
  }

  render() {
    const { color, width, icon, placeholder, dropdown, disabled, children, onClick } = this.props
    const { selected, open, hide } = this.state

    return (
      <Container width={width}>
        <Button color={color} open={dropdown ? open : false} selected={selected} disabled={disabled} onClick={() => {
          dropdown ? this.toggleDropwdown() : this._handleSelect()
          onClick()
        }} onBlur={this.closeDropdown}>
          {icon}{placeholder}
        </Button>
        {
          dropdown && !hide ? (
            <Dropdown className={open ? 'open' : 'close'}>
              {children}
            </Dropdown>
          ) : null
        }
      </Container>
    )
  }
}

/**
 * Dropdown item component
 */
export class SelectableDropdownItem extends Component {
  static propTypes = {
    text: PropTypes.string,
    icon: PropTypes.object,
    onSelect: PropTypes.func
  }

  static defaultProps = {
    text: '',
    icon: null,
    onSelect: () => { }
  }

  render() {
    const { text, icon, onSelect } = this.props

    return (
      <DropdownItem onClick={onSelect}>
        <div className="icon-container">
          {icon}
        </div>
        {text}
      </DropdownItem>
    )
  }
}

