import React from 'react'
import { SVG } from './styles.js'

const Arrow = ({ width = 50, height = 50, style = {}, onClick = () => {}, disabled = false}) => (
  <SVG 
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    style={style}
    onClick={onClick}
    viewBox="0 0 50 50">
      <path
        d="M45,50H5a5.006,5.006,0,0,1-5-5V5A5.006,5.006,0,0,1,5,0H45a5.006,5.006,0,0,1,5,5V45A5.006,5.006,0,0,1,45,50ZM8,22v7H21V42h7V29H41V22H28V9H21V22Z"
        fill={disabled ? '#A8A8A8' : '#00bf91'}
      />
  </SVG>
)

export default Arrow
