import React from 'react'
import { SVG } from './styles.js'

const DeliveryBoy = ({ height = 37, width = 40 }) => (
  <SVG 
    xmlns="http://www.w3.org/2000/svg" 
    width={width}
    height={height}
    viewBox="0 0 39.828 37.5"
  >
    <g transform="translate(-135 -608)">
    <g transform="translate(135 608)">
      <g transform="translate(12.467 6.7)">
        <path  d="M24.257,13.511c.649-.517.778-.517,1.038.259.778,1.422,1.168,1.422,2.205.388l.778-.776a1.328,1.328,0,0,1,2.335.776,6.41,6.41,0,0,1-.13,2.716c-.389,1.164-1.3,1.422-2.205.647l-.649-.647-.519-.517a.628.628,0,0,0-.908-.129c-.259.259-.13.647.259.905.259.129.649.388.908.517a6.854,6.854,0,0,1,3.114,3.75c.259.647.519.905,1.168.905a5.964,5.964,0,0,1,4.93,2.974c.13.259.389.517.259.776a.474.474,0,0,1-.13.388c-.13,0-.259,0-.259-.129-.259-.259-.649-.517-.908-.776-2.335-1.94-4.541-2.069-7.135-.517-1.038.647-1.038.647-1.427-.517-.908-2.457-1.686-4.914-2.595-7.5-.259-.776-.649-1.422-.908-2.069-.13-.259-.259-.388-.519-.259a10.732,10.732,0,0,1-5.578-1.293,7.449,7.449,0,0,1-.908-.517c-.259-.129-.519-.129-.519.259a14.2,14.2,0,0,0-.259,2.328c0,.517.259.776.908.905a14.491,14.491,0,0,0,2.205.259,4.428,4.428,0,0,1,3.243,1.422,3.1,3.1,0,0,1,.908,1.552c.649,1.94,1.427,3.621,2.076,5.56.13.259.13.517.259.776a2.067,2.067,0,0,1-3.5,1.681,5.237,5.237,0,0,1-.649-1.164c-.519-1.422-1.168-2.845-1.686-4.138a2.08,2.08,0,0,0-1.816-1.422l-5.059-.776A2.924,2.924,0,0,1,9.857,17.39a17.127,17.127,0,0,1,1.168-9.828,4.015,4.015,0,0,1,3.243-2.457A2.006,2.006,0,0,1,16.343,6.14c.389.776.778,1.552.13,2.328-.259.259-.13.517,0,.776a6.968,6.968,0,0,0,4.411,2.716c.519.129,1.168,0,1.686.129,1.038.129,1.3.517,1.427,1.552C24.127,13.381,24.257,13.381,24.257,13.511Z" transform="translate(-9.61 -5.081)" fill="#a8a8a8"/>
      </g>
      <g transform="translate(0 9.44)">
        <path d="M9.6,18.967H2.076A1.837,1.837,0,0,1,0,16.9V9.269A1.906,1.906,0,0,1,2.076,7.2H9.73a1.906,1.906,0,0,1,2.076,2.069v7.5A2.106,2.106,0,0,1,9.6,18.967ZM2.595,16.381H9.081V9.916H2.595v6.466Z" transform="translate(0 -7.2)" fill="#a8a8a8"/>
      </g>
      <g transform="translate(1.382 22.241)">
        <path d="M22,27.186H16.548c-.908,0-1.038-.129-1.168-1.034a6.81,6.81,0,0,0-1.038-3.233,5.288,5.288,0,0,0-5.189-2.457,5.6,5.6,0,0,0-4.67,3.362,7.531,7.531,0,0,0-.519,2.328c-.13.776-.389,1.164-1.3,1.034H1.759c-.649-.129-.778-.259-.649-.905.389-1.293.649-2.716,1.038-4.009a5.071,5.071,0,0,0,0-3.1c0-.129-.13-.259-.13-.517-.13-.776.13-1.164.908-1.293A11.2,11.2,0,0,1,5,17.229c2.984,0,5.838-.129,8.822-.129a12.627,12.627,0,0,1,2.205.129c1.038.129,1.557.647,1.3,1.681,0,.129-.13.388-.13.517a4.354,4.354,0,0,0-.13,2.586,8.993,8.993,0,0,0,.778,2.457.822.822,0,0,0,.778.647c.259,0,.519.129.908.129h7.914c.778,0,1.3.388,1.3,1.164,0,.647-.259.905-.908.905C25.889,27.186,23.943,27.186,22,27.186Z" transform="translate(-1.065 -17.1)" fill="#a8a8a8"/>
      </g>
      <g transform="translate(4.8 27.155)">
        <path d="M9.019,31.245h0a5,5,0,0,1-3.762-1.552A4.689,4.689,0,0,1,3.7,26.072,5.2,5.2,0,0,1,8.889,20.9h0a5.145,5.145,0,0,1,5.319,5.172A5.037,5.037,0,0,1,9.019,31.245Zm-.13-7.759a2.6,2.6,0,0,0-2.595,2.586,2.549,2.549,0,0,0,.778,1.81,2.645,2.645,0,0,0,1.946.776h0a2.6,2.6,0,0,0,2.595-2.586,2.629,2.629,0,0,0-2.724-2.586Z" transform="translate(-3.7 -20.9)" fill="#a8a8a8"/>
      </g>
      <g transform="translate(29.319 27.155)">
        <path d="M27.919,31.245a5.174,5.174,0,1,1,0-10.345h0a5.2,5.2,0,0,1,3.762,1.552,5.547,5.547,0,0,1,1.427,3.621A5.037,5.037,0,0,1,27.919,31.245Zm0-7.759a2.713,2.713,0,0,0-2.724,2.586,2.549,2.549,0,0,0,.778,1.81,2.645,2.645,0,0,0,1.946.776,2.6,2.6,0,0,0,2.595-2.586,2.549,2.549,0,0,0-.778-1.81,2.18,2.18,0,0,0-1.816-.776Z" transform="translate(-22.6 -20.9)" fill="#a8a8a8"/>
      </g>
      <g transform="translate(15.957 0)">
        <path d="M16.581,8.693A4.259,4.259,0,0,1,12.3,4.3a4,4,0,0,1,1.3-3.1A4.538,4.538,0,0,1,16.711-.1h0a4.543,4.543,0,0,1,4.281,4.526,4.764,4.764,0,0,1-1.3,3.1,4.427,4.427,0,0,1-3.114,1.164Zm0-6.078a1.266,1.266,0,0,0-1.168.517,1.657,1.657,0,0,0-.519,1.293,1.745,1.745,0,0,0,1.686,1.81h0a1.668,1.668,0,0,0,1.3-.517A1.657,1.657,0,0,0,18.4,4.426a1.771,1.771,0,0,0-1.816-1.81Z" transform="translate(-12.3 0.1)" fill="#a8a8a8"/>
      </g>
    </g>
  </g>
  </SVG>
)

export default DeliveryBoy
