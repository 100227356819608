import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import CartIcon from '@components/Icons/Cart'
import Remove from '@components/Icons/Remove'
import Add from '@components/Icons/Add'
import Input from '@components/Input'
import { Grid, Row, Col } from 'react-flexbox-grid'
import * as Icons from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTooltip from 'react-tooltip'
import { Scrollbars } from 'react-custom-scrollbars'
import { Creators } from '../../actions'
import { floatToBRL } from '../../helpers/MoneyFormat'
import { isLogged } from '../../redux/LoginRedux'
import {
  CartContainer,
  CartHeader,
  CartFooter,
  TextWrapper,
  ProductName,
  ProductAction,
  ProductInfo,
  Label,
  Text
} from './styles'
import './index.css'
import GAEvents from '../../helpers/GAEvents'

class Cart extends Component {
  static propTypes = {
    buttonText: PropTypes.string,
    fixed: PropTypes.bool,
    disabled: PropTypes.bool,
    width: PropTypes.string,
    link: PropTypes.string,
    onClick: PropTypes.func
  }

  static defaultProps = {
    buttonText: 'Finalizar pedido',
    fixed: false,
    disabled: false,
    width: '100%',
    link: '',
    onClick: () => { }
  }

  constructor(props) {
    super(props)
    this.animationTimer = undefined
    this.cartRemoteFetched = false
    this.cartRemoteForceUpdate = false
    this.cartUnknowRemoteForceUpdate = false

    this.state = {
      removeAnimation: [],
      updated: false,
      freeDelivery: false,
      deliveryAnimation: false,
      isDeliveryFree: false,
    }
  }

  componentDidMount() {
    const { loggedIn, attemptGetCartRemote } = this.props

    // Get remote cart only if we are logged
    if (loggedIn) {
      this.cartRemoteFetched = true
      attemptGetCartRemote()
    }
  }

  componentWillReceiveProps(newProps) {
    const { location, attemptUpdateCartRemote, attemptGetCartRemote } = this.props
    const { products, loggedIn, isCartProceed } = newProps

    if (isCartProceed) {
      this.cartRemoteForceUpdate = true
      this.cartUnknowRemoteForceUpdate = true
    }

    if (loggedIn) {
      if (!this.cartRemoteFetched && !this.cartRemoteForceUpdate) {
        this.cartRemoteFetched = true

        attemptGetCartRemote()
      }

      if (this.props.products !== products || this.cartRemoteForceUpdate) {

        this.cartRemoteForceUpdate = false
        this.cartRemoteFetched = true // Prevent to get the remote when we have updated it

        attemptUpdateCartRemote(products)
      }
    } else {
      if (!this.cartRemoteFetched && !this.cartRemoteForceUpdate) {
        this.cartRemoteFetched = true

      }

      if (this.props.products !== products || this.cartUnknowRemoteForceUpdate) {
        this.cartUnknowRemoteForceUpdate = false
        this.cartRemoteFetched = true // Prevent to get the remote when we have updated it

        this.props.attemUpdateCartUnknowRemote(products, this.props.code)
      }

    }

    if (products && products.length > 0 && location.pathname === '/produtos') {
      this._handleFreeDelivery(products)
      this.props.attemptOrderInstallment(null)

    }
  }

  onChangeFields = ({ target: { name, value } }) => {
    const { attemUpdateCartUnknowRemote, products } = this.props

    attemUpdateCartUnknowRemote(products, value)
  }

  _verifyDeliveryFree = () => {
    const { deliveries, products } = this.props;
    const { isDeliveryFree } = this.state;
    let total = 0;

    if (products.length > 0 && deliveries.length > 0) {
      const { free_from } = deliveries[0]
      const deliveryFreeFrom = Number(free_from)

      products.forEach(product => {
        const { price, quantity } = product

        total += (quantity * Number(price.toFixed(2)))
      })

      if (deliveryFreeFrom > 0 && total > deliveryFreeFrom && !isDeliveryFree) {
        toast.success('Você conseguiu entrega grátis!', {
          position: "top-right",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: false,
          pauseOnFocusLoss: false
        })
      }
    }
  }

  _handleAddProduct = (id) => {
    const { products, loggedIn, setCartProducts, attemptUpdateCartRemote, setGlobalCart } = this.props

    const index = products.findIndex(p => p.id === id)
    if (index >= 0) {
      // Check if product has available stock!!!
      if (products[index].quantity < products[index].maxQuantity) {
        products[index].quantity += 1
      } else {
        toast.info('Desculpe, produto não tem mais estoque!', {
          position: "top-right",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: false,
          pauseOnFocusLoss: false
        })
      }

      setCartProducts(products)
      // Update remote cart only if we are logged
      if (loggedIn) {
        attemptUpdateCartRemote(products)
        // Update Global cart to remarketing
        setGlobalCart(products, 'NO')

      } else {
        this.props.attemUpdateCartUnknowRemote(products, this.props.code)
      }

      this.setState({
        updated: true
      }, () => {
        setTimeout(() => {
          this._handleFreeDelivery(products)
        }, 500);
      })
      this._verifyDeliveryFree()
    }
  }

  _removeProductsAnimation = () => {
    this.setState({
      removeAnimation: []
    })
  }

  _handleRemoveProduct = (id) => {
    const { products, loggedIn, setCartProducts, attemptUpdateCartRemote, setGlobalCart } = this.props
    const { removeAnimation } = this.state

    const index = products.findIndex(p => p.id === id)
    if (index >= 0) {
      if (products[index].quantity <= 1) {
        removeAnimation.push(id)

        setTimeout(() => {
          this._removeProductsAnimation()

          products.splice(index, 1)

          // Update remote cart only if we are logged
          if (loggedIn) {
            attemptUpdateCartRemote(products)
          } else {
            this.props.attemUpdateCartUnknowRemote(products, this.props.code)
          }

          setCartProducts(products)
        }, 500)
      } else {
        products[index].quantity -= 1

        // Update remote cart only if we are logged
        if (loggedIn) {
          attemptUpdateCartRemote(products)
          // Update Global cart to remarketing
          setGlobalCart(products, 'NO')

        } else {
          this.props.attemUpdateCartUnknowRemote(products, this.props.code)
        }

        setCartProducts(products)
      }

      this.setState({
        updated: true,
        removeAnimation
      }, () => {
        setTimeout(() => {
          this._handleFreeDelivery(products)
        }, 500);
      })
    }
  }

  _handleFreeDelivery = (products, animation = true) => {
    const { deliveries } = this.props
    const { freeDelivery, deliveryAnimation } = this.state

    // Handle free delivery
    let total = 0
    let _freeDelivery = false

    if (products.length > 0) {
      products.forEach(product => {
        const { price, quantity } = product

        total += (quantity * price)
      })
    }


    if (deliveries && deliveries.length > 0) {
      const { freeFrom, delivery_fee } = deliveries[0]
      const deliveryFreeFrom = Number(freeFrom)
      const deliveryFee = Number(delivery_fee)

      _freeDelivery = (deliveryFreeFrom > 0 && total >= deliveryFreeFrom && deliveryFee > 0 ? true : false)
    }

    if (freeDelivery !== _freeDelivery) {
      let _deliveryAnimation = false

      if (animation) {
        if (_freeDelivery) {
          _deliveryAnimation = true

          if (deliveryAnimation !== _deliveryAnimation) {
            this.animationTimer = setTimeout(() => {
              this.setState({
                deliveryAnimation: false
              })

              this.animationTimer = undefined
            }, 5000)
          }
        } else {
          if (this.animationTimer !== undefined) {
            clearTimeout(this.animationTimer)
            this.animationTimer = undefined
          }
        }
      }
      setTimeout(() => {
        this.setState({
          freeDelivery: _freeDelivery,
          deliveryAnimation: _deliveryAnimation,
          isDeliveryFree: _freeDelivery
        })
      }, 300);

    }
  }

  _renderProducts = () => {
    const { products, productsCupom } = this.props
    const { removeAnimation } = this.state

    if (products) {
      return products.map((product, index) => {
        const { id, name, quantity, maxQuantity, price } = product
        const productNewPrice = productsCupom.find(p => p.id.toString() === id)
        const total = productNewPrice ? productNewPrice.price * quantity : price * quantity
        const productPrice = productNewPrice ? productNewPrice.price : price
        const addDisabled = quantity >= maxQuantity

        const remove = removeAnimation.indexOf(id) !== -1

        return (
          <Row className={`product-row animated ${remove ? 'slideOutRight' : 'slideInLeft'}`} key={index}>
            <Col xs={12}>
              <Row>
                <ProductName>{name}</ProductName>
                <ProductAction>
                  <Remove onClick={() => this._handleRemoveProduct(id)} />
                </ProductAction>
                <ProductAction>{quantity}</ProductAction>
                <ProductAction>
                  <Add width={30} height={30} onClick={() => this._handleAddProduct(id)} disabled={addDisabled} />
                </ProductAction>
              </Row>
              {
                productNewPrice && productNewPrice.price < price ?
                  <Row>
                    <ProductInfo>{ }</ProductInfo>
                    <ProductInfo end="1">
                      <Text style={{ color: '#A8A8A8', textDecoration: 'line-through', fontSize: 14 }}>{floatToBRL(price)}</Text>
                    </ProductInfo>
                  </Row>
                  : null
              }
              <Row>
                <ProductInfo>{quantity} unidades</ProductInfo>
                <ProductInfo end="1" >{floatToBRL(productPrice)}</ProductInfo>
              </Row>
              <Row>
                <ProductInfo>Total</ProductInfo>
                <ProductInfo end="1" total>{floatToBRL(total)}</ProductInfo>
              </Row>
            </Col>
          </Row>
        )
      })
    }
  }

  _renderResume = () => {
    const { products, deliveries, loggedIn, currentAddress, orderInstallment, attemptOrderInstallment, productsCupom, currentOrderShipping } = this.props
    const { not_deliverable } = currentAddress ? currentAddress : false
    let fee = 0
    let total = 0
    let totalWithInstallment = 0

    if (products) {
      products.forEach(product => {
        const { id, price, quantity } = product
        const productNewPrice = productsCupom.find(p => p.id.toString() === id)
        const productPrice = productNewPrice ? productNewPrice.price : price

        total += (quantity * Number(productPrice.toFixed(2) || price.toFixed(2)))
      })
    }

    if (deliveries && deliveries.length > 0 && !not_deliverable) {
      deliveries.sort(function (a, b) {
        return a.delivery_fee - b.delivery_fee
      })
      const { delivery_fee, free_from } = deliveries[0]
      const deliveryFee = Number(delivery_fee)
      const deliveryFreeFrom = Number(free_from)

      if ((total < deliveryFreeFrom || deliveryFreeFrom === 0) && !currentOrderShipping) {
        fee = deliveryFee
      }

      if (currentOrderShipping) {
        fee = Number(currentOrderShipping.price)
      }
    }

    if (orderInstallment > Number(total + fee).toFixed(2)) {
      totalWithInstallment = orderInstallment;
    } else {
      attemptOrderInstallment(null)
    }
    
    return (
      <Row style={{ padding: 8 }}>
        <Col xs={12}>
          <Row>
            <ProductInfo>{loggedIn && (currentAddress || currentOrderShipping) ? 'Taxa de Entrega ' : 'Taxa de Entrega a partir de'}</ProductInfo>
            <ProductInfo end="1">
              {
                loggedIn && (currentAddress || currentOrderShipping) ? null : (
                  <FontAwesomeIcon data-tip data-for='deliveryTooltip' icon={Icons.faQuestionCircle} style={{ marginRight: 10, cursor: 'pointer' }} />
                )
              }
              {
                floatToBRL(fee)
              }
            </ProductInfo>
          </Row>
          {
            products.length > 0 ? (
              <Row>
                <ProductInfo>Total do pedido</ProductInfo>
                <ProductInfo end="1" total>{floatToBRL(total + fee)}</ProductInfo>
              </Row>
            ) : null
          }

          {totalWithInstallment ?
            <Row>
              <ProductInfo>Total parcelado</ProductInfo>
              <ProductInfo end="1">{floatToBRL(totalWithInstallment)}</ProductInfo>
            </Row>
            : null}
        </Col>

        <ReactTooltip id='deliveryTooltip'>
          A taxa de entrega pode variar conforme o bairro.<br />Prossiga no fluxo do pedido para descobrir
          a taxa para o seu endereço.<br />
        </ReactTooltip>
      </Row>
    )
  }

  _renderFreeShipping = () => {
    const { products, deliveries, currentAddress, currentOrderShipping } = this.props
    const { freeDelivery } = this.state
    const { not_deliverable } = currentAddress ? currentAddress : false
    let deliveryFree = 0
    let total = 0
    let missingForFree = 0

    if (products) {
      products.forEach(product => {
        const { price, quantity } = product

        total += (quantity * price)
      })
    }

    if (deliveries && deliveries.length > 0) {
      const { free_from } = deliveries[0]
      const deliveryFreeFrom = Number(free_from)
      deliveryFree = deliveryFreeFrom;
      if (total < deliveryFreeFrom) {
        missingForFree = deliveryFreeFrom - total
      }
    }

    return (
      <Row style={{ borderTop: '1px solid #A8A8A8', padding: 8 }}>
        <Col xs={12}>
          <Row>
            {
              ((!freeDelivery && !not_deliverable) && (total < deliveryFree))  || currentOrderShipping !== null ? (
                <Label>
                  Para alcançar entrega grátis você precisa de mais: <br />
                  <b>{floatToBRL(missingForFree)}</b> em compras!
                </Label>
              ) : (
                <Label bold>VOCÊ CONSEGUIU ENTREGA GRÁTIS!</Label>
              )
            }
          </Row>
        </Col>
      </Row>
    )
  }

  _renderCupomDiscount = () => {
    const { code } = this.props

    return (
      <Row style={{ borderTop: '1px solid #A8A8A8' }}>
        <Col xs={12}>
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', padding: '10px 0' }}>
            {
              code ?
                <ProductInfo end="1" style={{ fontSize: 15}}>
                  cupom aplicado<FontAwesomeIcon icon={Icons.faCheck} style={{ width: 30, color: '#00bf91' }} />
                </ProductInfo>
                :
                <ProductInfo>Possui cupom ?</ProductInfo>
            }
            <Input style={{ width: '50%', marginRight: 6 }} name="cupomApplied" value={code} placeholder="cupom" fontSize={14} height={10} onChange={this.onChangeFields} textAlign="center" marginRight={10} autoComplete="off" />
          </div>
        </Col>
      </Row>
    )
  }

  render() {
    const { buttonText, fixed, disabled, width, link, products, store, loggedIn, onClick, setCartProceed, deliveries, currentAddress, setCartProceedBySocialLogin, setCartProceedHome } = this.props
    const { settings } = store
    const { config_navbar_color } = settings
    const { delivery_fee, minimum_value, free_from } = deliveries[0]
    const { deliveryAnimation } = this.state
    const { not_deliverable } = currentAddress ? currentAddress : false
 
    const deliveryFree = Number(delivery_fee)
    const deliveryFreeFrom = Number(free_from)
    const hasProducts = products && products.length > 0

    let productsTotalValue = 0
    let cartLink = {
      pathname: link.length > 0 ? link : '/checkout'
    }

    let handleClick = onClick

    if (!loggedIn) {
      /*cartLink.pathname = '/registro'
      cartLink.state = {
        goTo: '/checkout'
      }*/
      cartLink = {}
      handleClick = () => {
        setCartProceed(true);
        setCartProceedBySocialLogin(true);
        setCartProceedHome(false);
      }
    }
    else {
      if (window.location.pathname !== '/checkout') {
        cartLink.pathname = '/endereco'
        cartLink.state = {
          selectAddress: true
        }

      }
    }

    if (products) {
      products.forEach(product => {
        const { price, quantity } = product

        productsTotalValue += (quantity * price)
      })
    }

    return (
      <CartContainer fixed={fixed} width={width}>
        <CartHeader bgColor={config_navbar_color}>
          <TextWrapper>
            <CartIcon />
            Seu Carrinho
          </TextWrapper>
        </CartHeader>
        <Grid className="cart-grid pyro" style={{ overflow: 'hidden', maxHeight: '80vh', paddingRight: 0, paddingLeft: 0 }}>
          {
            deliveryAnimation ? (
              <React.Fragment>
                <div className="before"></div>
                <div className="after"></div>
              </React.Fragment>
            ) : null
          }

          <Scrollbars
            autoHeight
            autoHeightMax="50vh"
            renderThumbHorizontal={() => <div style={{ display: 'none' }} />}
          >
            {this._renderProducts()}
          </Scrollbars>

          {this._renderResume()}
          {
            products.length > 0 && this._renderCupomDiscount()
          }
          {
            deliveryFree > 0 && deliveryFreeFrom > 0 && !not_deliverable ? this._renderFreeShipping() : null
          }
        </Grid>
        <CartFooter disabled={!hasProducts || (!not_deliverable && productsTotalValue < minimum_value) || disabled} onClick={() => {
          GAEvents({ category: 'Shop', action: 'Clique no botão Finalizar pedido do Carrinho', label: `Cart` })
          handleClick()
        }}>
          <Link style={{ cursor: 'unset' }} to={cartLink}>
            <TextWrapper>
              {productsTotalValue < Number(minimum_value) && !not_deliverable ? `PEDIDO MÍNIMO DE ${floatToBRL(Number(minimum_value))}` : (hasProducts ? buttonText : 'Carrinho vazio')}
            </TextWrapper>
          </Link>
        </CartFooter>
      </CartContainer>
    )
  }
}

const mapStateToProps = ({ login, address, cart, startup, delivery, order }) => ({
  cartFetching: cart.fetching,
  loggedIn: isLogged(login),
  currentAddress: address.currentAddress,
  currentOrderShipping: address.currentOrderShipping,
  products: cart.products,
  isCartProceed: cart.proceed,
  store: startup.store,
  deliveries: delivery.deliveries,
  deliveryFetching: delivery.fetching,
  orderInstallment: order.orderInstallment,
  productsCupom: cart.productsCupom,
  code: cart.code,
})

const mapDispatchToProps = dispatch => ({
  setCartProducts: (products) => dispatch(Creators.cartSetProductRequest(products)),
  getCartProducts: () => dispatch(Creators.cartListProductRequest()),
  setCartProceed: (proceed) => dispatch(Creators.cartSetProceed(proceed)),
  attemptGetCartRemote: () => dispatch(Creators.cartGetRemoteRequest()),
  attemptUpdateCartRemote: (products) => dispatch(Creators.cartUpdateRemoteRequest(products)),
  attemptGetDeliveries: ({ city_id }) => dispatch(Creators.deliveryListRequest(city_id)),
  attemptOrderInstallment: (orderInstallment) => dispatch(Creators.orderInstallmentRequest(orderInstallment)),

  setCartProceedBySocialLogin: (proceedCheckout) => dispatch(Creators.cartProceedBySocialLogin(proceedCheckout)),
  setCartProceedHome: (proceedHome) => dispatch(Creators.cartProceedHome(proceedHome)),
  attemUpdateCartUnknowRemote: (products, code) => dispatch(Creators.cartUpdateRemoteUnknowRequest(products, code)),
  setGlobalCart: (products, purchased) => dispatch(Creators.cartGlobalUpdateRequest(products, purchased))

})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cart))
