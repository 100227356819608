import { create } from 'apisauce'
import Config from '../config'

const api = create({
  baseURL: Config.correiosApi
})

const setHeaders = (opts) => api.setHeaders(opts)

const getShippingDelivery = ({ zipcode, products, sender }) => api.post('/v1/shipping', { zipcode, products, sender })
const addToCartShipping = ({ orderId, shipping, sender }) => api.put('/v1/shipping/add/cart', { orderId, shipping, sender })

export default {
  setHeaders,

  addToCartShipping,
  getShippingDelivery
}