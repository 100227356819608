import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Icons from '@fortawesome/free-solid-svg-icons'
import CartIcon from '@components/Icons/Cart'
import { ProductCardContainer, ProductContent, ProductFooter, Text, Image, DetailsButton, SpecialBadge, PriceCompare, AddButton, DeliveryFree } from './styles'
import { floatToBRL } from '../../helpers/MoneyFormat'
import Capitalize from '../../helpers/Capitalize'
import Config from '../../config'
import NoImage from '../../assets/images/noimage.png'
import NoImageGeneric from '../../assets/images/noimage-generic.png'
import GAEvents from '../../helpers/GAEvents'
import { addProduct, addToCart } from '../../helpers/ECEvents'

export default class ProductCard extends Component {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    model: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number,
    quantity: PropTypes.number,
    special: PropTypes.object,
    slug: PropTypes.string,
    control: PropTypes.shape({
      description: PropTypes.string,
      initials: PropTypes.string
    }),
    classification: PropTypes.string,
    laboratory: PropTypes.string,
    hidePrice: PropTypes.bool,
    currentCategory: PropTypes.any,
    cachedProduct: PropTypes.object,
    onAdd: PropTypes.func,
    onClick: PropTypes.func,
    index: PropTypes.number,
    list: PropTypes.string,
  }

  static defaultProps = {
    hidePrice: false,
    currentCategory: undefined,
    cachedProduct: undefined,
    onClick: () => { },
    deliveryFree: false
  }


  render() {
    const { id, list, name, model, presentation, image, price, quantity, special, slug, control, classification, laboratory, hidePrice, currentCategory, cachedProduct, onAdd, onClick, index, deliveryFree, storeColor } = this.props
    const ean = cachedProduct.ean
    const { s3Bucket } = Config
    let controlId = null
    if (control) {
      controlId = control.control_id
    }
    let productImage = image && image.split('/')[0] !== 'catalog' ? `${s3Bucket}${image}` : (classification === 'GENERICO' ? NoImageGeneric : NoImage)


    return (
      <ProductCardContainer className="animated flipInX">

        {
          (special && Number(special.discount.toFixed(0)) > 0) ? (
            <SpecialBadge>
              <span>-{special.discount.toFixed(0)}</span>%
            </SpecialBadge>
          ) : null
        }

        {
          deliveryFree && (controlId === null || controlId === 0) ? (
            <Link to={{ pathname: `/produtos/${slug}`, state: { metas: true, currentCategory, cachedProduct, historyGoBack: true } }} onClick={onClick}>
              <DeliveryFree storeColor={storeColor}>
                <span>Entrega grátis!</span>
              </DeliveryFree>
            </Link>
          ) : null

        }

        <ProductContent to={{ pathname: `/produtos/${slug}`, state: { metas: true, currentCategory, cachedProduct, historyGoBack: true } }} onClick={() => {
          onClick()
          GAEvents({ category: 'Shop', action: 'Click no card de produto', label: `Produto : ${model} - ${ean}` })
          addProduct({
            ean,
            slug,
            position: index,
            laboratory,
            category: currentCategory,
            price,
            quantity
          }, list)
        }}>
          <div style={{ marginTop: 20, width: '60%' }}>
            <Text bold>{Capitalize(name)}</Text>
            {presentation && <Text style={{ marginTop: -8, marginBottom: 0 }}>{presentation}</Text>}

            {
              laboratory ? (
                <div style={{ display: 'inline-flex', marginTop: -28 }}>
                  <Text bold>Fabricante</Text>
                  <Text>{`: ${Capitalize(laboratory.slice(0, 7).trim())}`}.</Text>
                </div>
              ) : null
            }
          </div>

          <div style={{ marginTop: 10, width: '40%' }}>
            <Image src={productImage} alt={slug} effect="blur" />
          </div>
        </ProductContent>
        <ProductFooter>
          {
            // We do not have stock? O.O -> :(
            quantity <= 0 ? (
              <span style={{ fontSize: 12, color: '#ff4646' }}>
                <FontAwesomeIcon icon={Icons.faTimesCircle} color="#ff4646" style={{ marginRight: 5 }} />
                Sem estoque
              </span>
            ) : null
          }
          {
            // Our last products, JUST BUY IT!
            quantity > 0 && quantity <= 5 ? (
              <span style={{ fontSize: 12, color: '#ffa346' }}>
                <FontAwesomeIcon icon={Icons.faExclamationCircle} color="#ffa346" style={{ marginRight: 5 }} />
                {`Resta${quantity > 1 ? 'm' : ''}`} só {quantity} {`unidade${quantity > 1 ? 's' : ''}`}
              </span>
            ) : null
          }
          {
            // We are good \o/
            quantity > 5 ? (
              <span style={{ fontSize: 12, color: '#00bf91' }}>
                <FontAwesomeIcon icon={Icons.faCheckCircle} color="#00bf91" style={{ marginRight: 5 }} />
                Em estoque
              </span>
            ) : null
          }

          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
            {
              !hidePrice ? (
                (special && Number(special.discount.toFixed(0)) > 0) ? (
                  <PriceCompare>
                    <Text style={{ color: '#A8A8A8', textDecoration: 'line-through', fontSize: 14 }}>{floatToBRL(price)}</Text>
                    <Text bold style={{ color: '#00BF91', fontSize: 16 }}>{floatToBRL(special.price)}</Text>
                  </PriceCompare>
                ) : <Text bold style={{ color: '#00BF91', fontSize: 16 }}>{floatToBRL(price)}</Text>
              ) : null
            }

            {
              !hidePrice && control === null && quantity > 0 ? (
                <AddButton onClick={() => {
                  onAdd(id)
                  GAEvents({ category: 'Shop', action: 'Click no botão Adicionar ao carrinho', label: `Produto : ${model} - ${ean}` })

                  addToCart({
                    ean,
                    model,
                    laboratory,
                    position: index,
                    view: list,
                    price: price,
                    quantity,
                  })
                }}>
                  <CartIcon style={{ marginLeft: 5 }} />
                </AddButton>
              ) : (
                <DetailsButton to={{ pathname: `/produtos/${slug}`, state: { metas: true, currentCategory } }} onClick={() => {
                  addProduct({
                    ean,
                    slug,
                    laboratory,
                    position: index,
                    list: window.location.pathname,
                    price,
                    quantity
                  }, 'detail')
                }}  >Detalhes</DetailsButton>
              )
            }
          </div>
        </ProductFooter>
      </ProductCardContainer>
    )
  }
}
