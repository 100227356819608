import { combineReducers } from 'redux'

// Reducers
import CartRedux from './CartRedux'
import ProductRedux from './ProductRedux'
import LoginRedux from './LoginRedux'
import CategoryRedux from './CategoryRedux'
import StartupRedux from './StartupRedux'
import DeliveryRedux from './DeliveryRedux'
import PaymentRedux from './PaymentRedux'
import AddressRedux from './AddressRedux'
import OrderRedux from './OrderRedux'
import BannerRedux from './BannerRedux'
import FeaturedProductRedux from './FeaturedProductRedux'
import AboutUsRedux from './AboutUsRedux'
import AccountRedux from './AccountRedux'
import SearchRedux from './SearchRedux'

export const reducers = {
  cart: CartRedux,
  product: ProductRedux,
  login: LoginRedux,
  category: CategoryRedux,
  startup: StartupRedux,
  delivery: DeliveryRedux,
  payment: PaymentRedux,
  address: AddressRedux,
  order: OrderRedux,
  banner: BannerRedux,
  featuredProduct: FeaturedProductRedux,
  account: AccountRedux,
  aboutUs: AboutUsRedux,
  search: SearchRedux
}

export default combineReducers(reducers)
