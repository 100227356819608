import React from 'react'
import { SVG } from './styles.js'

const Remove = ({ height = 30, width = 30, onClick = () => {}}) => (
  <SVG 
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    onClick={onClick}
    viewBox="0 0 30.001 30">
    <path 
      d="M75-1955H55a5.006,5.006,0,0,1-5-5v-20a5.006,5.006,0,0,1,5-5H75a5.006,5.006,0,0,1,5,5v20A5.006,5.006,0,0,1,75-1955Zm-20-17v4.2H74.8v-4.2Z"
      transform="translate(-49.999 1985)"
      fill="#ff4646"/>
  </SVG>
)

export default Remove
