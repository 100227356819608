import React from 'react'
import { SVG } from './styles.js'

const Card = ({ height = 30, width = 30, color = "#707070"}) => (
  <SVG 
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 28.024 18.323"
  >
    <g transform="translate(-1272 -108)">
      <g>
        <g>
          <g transform="translate(1272 108)" fill="none" stroke={color} strokeWidth="2">
            <rect width="28.024" height="18.324" rx="3" stroke="none"/>
            <rect x="1" y="1" width="26.024" height="16.324" rx="2" fill="none"/>
          </g>
          <rect width="28.024" height="5.389" transform="translate(1272 111.773)" fill={color}/>
          <rect width="8.623" height="2.156" rx="1.078" transform="translate(1274.694 120.934)" fill={color}/>
        </g>
      </g>
    </g>
  </SVG>
)

export default Card
