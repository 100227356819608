import styled from 'styled-components'

export const Title = styled.h2`
  line-height: 0;
  font-family: Gilmer-Bold;
`
export const Text = styled.span`
  font-family: Gilmer-Light;
  font-size: 15px;
  color: #A8A8A8;
`
