import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-flexbox-grid'
import CartIcon from '@components/Icons/Cart'
import { CartButton, CartFooter, ContainerHeader, Container, TextWrapper } from './styles'
import { floatToBRL } from '../../helpers/MoneyFormat'

class MobileCartDropdown extends Component {

  static propTypes = {
    productsQuantity: PropTypes.number,
    productsTotalValue: PropTypes.number,
    minimumValue: PropTypes.number,
    
    navbarColor: PropTypes.string,
    buttonText: PropTypes.string,
    containerClass: PropTypes.string,

    cartLink: PropTypes.object,

    disabled: PropTypes.bool,
    hasProducts: PropTypes.bool,

    onClick: PropTypes.func,
    onClose: PropTypes.func,
    onToggle: PropTypes.func,
    onNativeToggle: PropTypes.func,
  }

  static defaultProps = {
    productsQuantity: 0,
    productsTotalValue: 0,
    onNativeToggle: () => {}
  }

  state = {
    redirectTo: null
  }

  toggleDropwdown = () => {
    // Native mobile app
    const isMyPharmaBase = window.navigator ? window.navigator.userAgent === 'mypharmaBase' : false

    if (!isMyPharmaBase) {
      this.props.onToggle(!this.props.visible)
    } else {
      this.props.onNativeToggle({
        path: '/carrinho',
        push: true
      })
    }
  }

  closeDropdown = () => {
    // Native mobile app
    const isMyPharmaBase = window.navigator ? window.navigator.userAgent === 'mypharmaBase' : false

    if (!isMyPharmaBase) {
      this.props.onToggle(false)
      this.props.onClose()
    } else {
      this.props.onNativeToggle({
        path: '/produtos',
        push: false
      })
    }
  }

  render() {
    const {
      productsQuantity,
      productsTotalValue,
      minimumValue,
      navbarColor,
      buttonText,
      containerClass,
      cartLink,
      disabled,
      hasProducts,
      visible,

      onClick,
      children,
      deliverable
    } = this.props

    const canProceed = !deliverable ? productsTotalValue < minimumValue : false


    return (
      <Fragment>
        <CartButton className={containerClass} onClick={this.toggleDropwdown} color={navbarColor}>
          {
            productsQuantity > 0 ? productsQuantity : null
          }
          <CartIcon />
        </CartButton>
        <ContainerHeader visible={visible}>
          <i className="material-icons" onClick={this.closeDropdown}>close</i>
        </ContainerHeader>

        <Container visible={visible} >
          {visible && (
            <div style={{ width: '100%', height: '100%', margin: 0, overflowY: 'auto', overflowX: 'hidden' }}>
              <Row>
                <Col xs={12}>
                  {children}
                </Col>
              </Row>

              <CartFooter disabled={disabled || !hasProducts || canProceed } onClick={onClick}>
                <Link style={{cursor: 'unset'}} to={cartLink}>
                  <TextWrapper>
                    {productsTotalValue < Number(minimumValue) && !deliverable ? `PEDIDO MÍNIMO DE ${floatToBRL(Number(minimumValue))}` : (hasProducts ? buttonText : 'Carrinho vazio')}
                  </TextWrapper>
                </Link>
              </CartFooter>
            </div>
          )}
        </Container>
      </Fragment>
    )
  }
}


export default MobileCartDropdown
