import styled from 'styled-components'

export const RegisterAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const RegisterAddressContent = styled.div`
  margin-top: 20px;
  align-self: flex-start;
  width: 100%;
`
export const Title = styled.h2`
  line-height: 0;
  font-family: Gilmer-Bold;
  align-self: center;
`
