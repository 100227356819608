import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types } from '../actions'

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: false,
  data: null,
  product: null
})

export const productListRequest = (state = INITIAL_STATE, { category_id, params }) =>
  Object.assign({}, state, {
    fetching: true,
    error: false,
    data: null
  })

export const productListSuccess = (state = INITIAL_STATE, { data }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    data
  })

export const productListFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    data: null,
    error
  })

export const productDetailRequest = (state = INITIAL_STATE, { slug }) =>
  Object.assign({}, state, {
    fetching: true,
    error: false,
    product: null
  })

export const productDetailSuccess = (state = INITIAL_STATE, { product }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    product
  })

export const productDetailFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    product: null,
    error
  })

/**
 * Handlers
 */

export const HANDLERS = {
  [Types.PRODUCT_LIST_REQUEST]: productListRequest,
  [Types.PRODUCT_LIST_SUCCESS]: productListSuccess,
  [Types.PRODUCT_LIST_FAILURE]: productListFailure,

  [Types.PRODUCT_DETAIL_REQUEST]: productDetailRequest,
  [Types.PRODUCT_DETAIL_SUCCESS]: productDetailSuccess,
  [Types.PRODUCT_DETAIL_FAILURE]: productDetailFailure
}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)

export default reducer
