import { call, put } from 'redux-saga/effects'
import { Creators } from '../actions'

export function * getFeaturedProducts(api) {  
  const response = yield call(api.getFeaturedProducts)
  const { ok, data } = response

  if (ok) {
    const { products } = data

    yield put(Creators.featuredProductListSuccess(products))
  } else {
    const { error } = data

    yield put(Creators.featuredProductListFailure(error))
  }
}
