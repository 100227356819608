import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import PrettySelect from '@components/PrettySelect'
import MarkerIcon from '@components/Icons/Marker'
import { Row, Col } from 'react-flexbox-grid'
import * as moment from 'moment'
import { Title } from './styles'
import { Creators } from '../../actions'

class StoreURLsModal extends Component {
  static propTypes = {
    urls: PropTypes.any,
    mobile: PropTypes.bool
  }

  constructor(props) {
    super(props)

    this.state = {
      redirectTo: ''
    }
  }

  onChangeField = ({ target: { name, value } }) => {
    const { setStoreRedirect } = this.props

    this.setState(state => ({
      ...state,
      [name]: value
    }), () => {
      const expireIn = moment().add(2, 'days').toISOString()
      setStoreRedirect(false, expireIn)

      setTimeout(() => {
        document.location.href = `${value}?noredirect`
      }, 1000)
    })
  }

  _renderOptions = () => {
    const { urls } = this.props

    return urls.map((url, index) => {
      const { url_name, url_address } = url

      return <option key={index} value={url_address}>{url_name}</option>
    })
  }

  render() {
    const { mobile } = this.props
    const { redirectTo } = this.state

    return (
      <Row style={{margin: 35}}>
        <Col xs={12} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <MarkerIcon />

          <Title fontSize={24} bold>Nos diga onde você está,</Title>
          <Title fontSize={18} style={{marginBottom: 25}}>para te direcionarmos aos produtos da loja mais próxima :)</Title>

          <PrettySelect name="redirectTo" value={redirectTo} placeholder={mobile ? 'SELECIONE' : 'SELECIONE SUA LOCALIZAÇÃO'} fontSize={16} textAlign="center" onChange={this.onChangeField}>
            {this._renderOptions()}
          </PrettySelect>
        </Col>
      </Row>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  setStoreRedirect: (status, expireIn) => dispatch(Creators.setStoreRedirect(status, expireIn))
})

export default connect(null, mapDispatchToProps)(StoreURLsModal)