import React from 'react'
import { SVG } from './styles.js'

const About = ({ height = 20, width = 20, color = '#fff', opacity = 0.80}) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    opacity={opacity}
    viewBox="0 0 25.557 25.552">
    <g className="about-b" transform="translate(0)">
      <path 
        className="about-c"
        d="M23.032,27.553H2.731A2.4,2.4,0,0,1,.437,26.015a4.577,4.577,0,0,1-.364-1.538c0-.165-.162-16.539,0-19.491A2.9,2.9,0,0,1,1.632,2.374,3.532,3.532,0,0,1,3.069,2h19.47a3.043,3.043,0,0,1,2.378.872A2.887,2.887,0,0,1,25.55,4.9V24.57C25.55,27.469,23.29,27.553,23.032,27.553ZM11.281,11.264V22.989h3.672V11.264Zm1.9-5.492a2.108,2.108,0,0,0-1.493.595,1.928,1.928,0,0,0-.629,1.449A2,2,0,0,0,11.673,9.3a2.051,2.051,0,0,0,1.494.607l.023.011a2.05,2.05,0,0,0,1.494-.606A2,2,0,0,0,15.3,7.827a1.961,1.961,0,0,0-.629-1.448A2.048,2.048,0,0,0,13.178,5.773Z"
        transform="translate(0 -2.001)"
        fill={color}
        opacity={opacity}
      />
    </g>
  </SVG>
)

export default About
