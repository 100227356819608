import styled from 'styled-components'

export const SVG = styled.svg`
  display: inline-block;
  vertical-align: text-top;
  cursor: pointer;

  path:active {
    fill: #00a881 !important;
  }
`