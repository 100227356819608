import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import Body from '@components/Body'
import Cart from '@components/Cart'
import SearchBar from '@components/SearchBar'
import FilterDropdown from '@components/FilterDropdown'
import ProductCard from '@components/ProductCard'
import ProductInfo from '@components/ProductInfo'
import LoadingCircle from '@components/Icons/LoadingCircle'
import { Col, Row, } from 'react-flexbox-grid'
import { Formik, Form } from 'formik'
import Modal from 'react-responsive-modal'
import { toast } from 'react-toastify'
import * as moment from 'moment'
import { Creators } from '../../actions'
import { isLogged } from '../../redux/LoginRedux'
import './index.css'
import { addImpression, addToCart } from '../../helpers/ECEvents'

class Home extends Component {
  constructor(props) {
    super(props)
    const { match, location } = props
    const { categorySlug } = match.params

    // History can go back
    this.historyGoBack = false

    // Search by URL query :D
    let searchQuery = ''
    if (location && location.search) {
      if (location.search.length > 0) {
        const searchParams = new URLSearchParams(location.search)

        if (searchParams.has('q')) {
          searchQuery = searchParams.get('q')
        }
      }
    }

    this.state = {
      loading: true,
      modalOpen: false,
      categorySlugLoaded: categorySlug ? false : true,
      currentProductUrl: undefined,
      currentProduct: undefined,
      currentCategory: undefined,
      currentSubcategory: undefined,
      showFeatured: searchQuery.length === 0,
      productsRequestQuery: searchQuery,
      productsRequestNext: {
        start: 0,
        end: 50
      },
      cartProducts: [],
      products: [],
      productDeliveryFree: null,
      isDeliveryFree: false,
      dontShowAgain: false,
      deliveryFreeFromBigest: 0,
    }

    props.getCartProducts()
    props.getCategories()

    if (searchQuery.length > 0) {
      props.searchProducts({
        query: searchQuery
      })
    } else {
      // Load products only if we are not with any category setted
      if (!categorySlug) {
        props.getFeaturedProducts()
      }
    }
  }


  componentDidMount() {
    const { getProducts, setCurrentOrderShipping } = this.props
    setCurrentOrderShipping(null)
    // This may fail a lot
    setTimeout(()=> {
      addImpression(this.state.products)
    }, 2000)

    window.addEventListener('scroll', () => {
      const { _product } = this.props
      const { showFeatured, currentCategory, currentSubcategory, productsRequestQuery, productsRequestNext } = this.state

      if (productsRequestQuery.length === 0 && (currentCategory || currentSubcategory)) {
        // Validate if there's more products to load
        if (this.contentContainer && !_product.fetching && !showFeatured && productsRequestNext) {
          if ((window.scrollY + window.innerHeight) >= this.contentContainer.scrollHeight) {

            let categoryId = currentCategory.category_id
            if (currentSubcategory) {
              categoryId = currentSubcategory.category_id
            }

            this.setState({
              loading: true
            }, () => {
              getProducts(categoryId, productsRequestNext)
            })
          }
        }
      }
    })
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { match, _search, _product, _category, _featured, _cart, _delivery, currentAddress } = newProps
    const { products: cartProducts } = _cart
    const { products, loading, showFeatured, categorySlugLoaded, currentProductUrl, productsRequestQuery, productsRequestNext, currentProduct } = this.state
    const { productUrl, categorySlug, subcategorySlug } = match.params

    // Copy current state
    let newState = Object.assign({}, this.state)

    if (cartProducts && _delivery !== null && _delivery.deliveries) {
      const { deliveries } = _delivery

      if (deliveries.length === 1 && currentAddress) {
        const freeFrom = deliveries[0]['free_from']
        const delivery_fee = deliveries[0]['delivery_fee']
        let total = 0

        cartProducts.forEach(item => {
          const { price, quantity } = item

          total += (quantity * Number(price.toFixed(2)))
        })

        if (delivery_fee <= 0 || freeFrom <= 0) {
          newState.deliveryFreeFromBigest = 0
          newState.productDeliveryFree = false
        } else {

          newState.deliveryFreeFromBigest = freeFrom
          newState.productDeliveryFree = (total > freeFrom) && (delivery_fee > 0) ? true : false
          newState.dontShowAgain = newState.productDeliveryFree
        }


      } else {
        let total = 0
        const deliveryFreeFromNull = deliveries.filter(delivery => delivery.free_from <= 0).length > 0

        if (!deliveryFreeFromNull) {

          function compare(a, b) {
            return b.free_from - a.free_from
          }
          const highestValueFreeFrom = deliveries.sort(compare).filter(value => value.free_from > 0)
          const freeFrom = highestValueFreeFrom[0]['free_from']

          cartProducts.forEach(item => {
            const { price, quantity } = item

            total += (quantity * Number(price.toFixed(2)))
          })
          newState.deliveryFreeFromBigest = freeFrom
          newState.productDeliveryFree = (total > freeFrom) ? true : false
        }
      }
    }

    // Handle category load by slug
    if (!_product.fetching && !_category.fetching && _category.categories !== null && !categorySlugLoaded && (categorySlug || subcategorySlug)) {
      let category = _category.categories.find(p => p.slug === categorySlug)
      let subcategory = undefined

      // Hmmm, looks like we have subcategory selected, so we need it too
      if (subcategorySlug) {
        subcategory = category.subCategories.find(p => p.slug === subcategorySlug)
      }

      if (category) {
        const category_id = subcategory ? subcategory.category_id : category.category_id

        // Remove others filters
        newState.showFeatured = false
        newState.productsRequestQuery = ''
        newState.productsRequestNext = {
          start: 0,
          end: 50
        }
        newState.products = []

        // We do not need run this again, so we must flag
        newState.categorySlugLoaded = true

        setTimeout(() => {
          this.props.getProducts(category_id, productsRequestNext)
        }, 500)

        this.props.location.state = { metas: true, category, subcategory }

        newState.currentCategory = category
        newState.currentSubcategory = subcategory
      }
    }

    // Products load
    if (!_product.fetching && loading && !showFeatured && _product.data !== null && productsRequestQuery.length === 0) {
      newState = {
        ...newState,
        loading: false,
        productsRequestNext: _product.data.next,
        products: _product.data.products.map(p => {
          return {
            _score: 0.0,
            id: p.product_id,
            name: p.name,
            model: p.model,
            ean: Number(p.ean),
            ms: Number(p.ms),
            presentation: p.presentation,
            image: p.image,
            description: p.description,
            classification: p.classification ? p.classification : null,
            control: p.control ? p.control : null,
            laboratory: p.manufacturer,
            categories: p.categories,
            specials: p.specials || [],
            price: Number(p.price),
            quantity: Number(p.quantity),
            slug: p.slug,
            featured: false
          }
        })
      }
    }

    // Search products
    if (!_search.fetching && loading && _search.products !== null && productsRequestQuery.length > 0) {
      _search.products.forEach(p => {
        if (products.find(x => x.id === p.product_id) === undefined) {
          products.push({
            _score: p._score,
            id: p.product_id,
            name: p.name,
            model: p.model,
            ean: Number(p.ean),
            ms: Number(p.ms),
            presentation: p.presentation,
            image: p.image,
            description: p.description,
            classification: p.classification,
            control: p.control,
            laboratory: p.manufacturer,
            categories: p.categories,
            specials: p.specials || [],
            price: Number(p.price),
            quantity: Number(p.quantity),
            slug: p.slug,
            featured: false
          })
        }
      })

      newState = {
        ...newState,
        loading: false,
        products
      }
    }

    // Get featured products
    if (!_featured.fetching && loading && _featured.products !== null && productsRequestQuery.length === 0 && newState.currentCategory === undefined) {
      _featured.products.forEach(p => {
        if (products.find(x => x.id === p.product_id) === undefined) {
          products.push({
            _score: 0.0,
            id: p.product_id,
            name: p.name,
            model: p.model,
            ean: Number(p.ean),
            ms: Number(p.ms),
            presentation: p.presentation,
            image: p.image,
            description: p.description,
            classification: p.classification ? p.classification : null,
            control: p.control ? p.control : null,
            laboratory: p.manufacturer,
            categories: p.categories,
            specials: p.specials || [],
            price: Number(p.price),
            quantity: Number(p.quantity),
            slug: p.slug,
            featured: true
          })
        }
      })

      newState.products = products
      newState.loading = false
    }

    // Request product detail by slug
    if (!_product.fetching && !_featured.fetching && currentProductUrl !== productUrl) {

      newState = {  
        ...newState,
        currentProductUrl: productUrl,
        currentProduct: undefined
      }

      if (productUrl !== undefined) {
        // If we have already loaded this product, let's get it
        const cachedProduct = products.find(p => p.slug === productUrl)
        this.props.location.state = {
          ...this.props.location.state,
          cachedProduct
        }

        if (cachedProduct) {
          newState = {
            ...newState,
            modalOpen: true,
            currentProduct: cachedProduct
          }
        } else {
          this.props.getProductDetail(productUrl)
        }
      }
    }

    //We got the product detail :)
    if (!_product.fetching && currentProductUrl !== undefined && currentProduct === undefined && _product.product !== null) {
      const productDetail = _product.product
      let currentProduct = undefined

      if (productDetail) {
        currentProduct = {
          id: productDetail.product_id,
          name: productDetail.name,
          model: productDetail.model,
          ean: Number(productDetail.ean),
          ms: Number(productDetail.ms),
          presentation: productDetail.presentation,
          image: productDetail.image,
          description: productDetail.description,
          classification: productDetail.classification ? productDetail.classification : null,
          control: productDetail.control ? productDetail.control : null,
          laboratory: productDetail.manufacturer,
          categories: productDetail.categories,
          price: Number(productDetail.price),
          specials: productDetail.specials || [],
          quantity: Number(productDetail.quantity)
        }

        // Does product has loaded?
        if (products.find(p => p.id === currentProduct.id) === undefined) {
          products.push(currentProduct)
        }
      }

      newState = {
        ...newState,
        modalOpen: true,
        products,
        currentProduct
      }
    }

    // Carts products has changed
    if (cartProducts !== this.state.cartProducts) {
      newState.cartProducts = cartProducts
    }

    // Update state if has changed
    if (this.state !== newState) {
      this.setState(newState)
    }
  }

  onSearchChange = (value) => {
    const { history, searchProducts, getFeaturedProducts } = this.props

    this.setState({
      products: [],
      loading: true,
      productsRequestQuery: value,
      showFeatured: value.length === 0,
      currentCategory: undefined,
      currentSubcategory: undefined
    }, () => {
      const searchUrl = new URLSearchParams({ q: value })
      history.push({
        pathname: '/produtos',
        search: value.length > 0 ? `?${searchUrl.toString()}` : ''
      })

      if (value.length > 0) {
        searchProducts({
          query: value
        })
      } else {
        getFeaturedProducts()
      }
    })
  }

  handleFilterChange = (option) => {
    const { _category, history, getProducts, getFeaturedProducts } = this.props
    const { currentCategory } = this.state
    const selectedId = typeof option.value === 'number' ? undefined : option.value

    // Params
    const params = {
      start: 0,
      end: 50
    }

    if (selectedId) {
      // Change url to category slug, this will not re-render :D
      window.history.replaceState(null, option.metaTitle, option.slug)
  
      let category = currentCategory
      let subcategory = undefined

      if (!option.isSubcategory) {
        category = _category.categories.find(p => p.category_id.toString() === selectedId)
      } else {
        subcategory = category.subCategories.find(p => p.category_id.toString() === selectedId)
      }
      
      this.setState({
        loading: true,
        currentCategory: category,
        currentSubcategory: subcategory,
        productsRequestNext: params,
        currentProductUrl: undefined,
        currentProduct: undefined,
        showFeatured: false,
        products: [],
        productsRequestQuery: ''
      }, () => {
        this.props.location.state = { metas: true, category, subcategory }

        getProducts(selectedId, params)
      })
    } else {
      if (option.isSubcategory) {
        // Change url to category slug, this will not re-render :D
        window.history.replaceState(null, option.metaTitle, option.slug)

        this.setState({
          loading: true,
          currentSubcategory: undefined,
          productsRequestNext: params,
          products: [],
          productsRequestQuery: ''
        }, () => {
          this.props.location.state = { metas: true, category: currentCategory, subcategory: undefined }

          getProducts(currentCategory.category_id, params)
        })
      } else {
        this.setState({
          products: [],
          currentCategory: undefined,
          currentSubcategory: undefined,
          productsRequestQuery: '',
          loading: true,
          showFeatured: true
        }, () => {
          history.push({
            pathname: '/produtos',
            state: {
              metas: false,
              category: undefined,
              subcategory: undefined
            }
          })
  
          getFeaturedProducts()
        })
      }
    }
  }

  _getActiveSpecialPrice = (product) => {
    const { price, specials } = product
    const { _store } = this.props
    
    let _specials = specials.filter(s => s.store_id === _store._id)
    
    _specials = _specials.filter(p => {

      const { date_start, date_end } = p

      const dateStart = moment(date_start, 'YYYY-MM-DD')
      const dateEnd = moment(date_end, 'YYYY-MM-DD')
      const currentDate = moment()

      return currentDate.isSameOrAfter(dateStart) && currentDate.isSameOrBefore(dateEnd)

    }).map(p => {
      const specialPrice = Number(p.price)
      const discountPercentage = (price - specialPrice) / price * 100

      return {
        price: specialPrice,
        discount: discountPercentage,
        date_start: p.date_start,
        date_end: p.date_end
      }
    })

    if (_specials.length === 0) return undefined
    else return _specials[0]
  }

  _verifyDeliveryFree = () => {
    const { productDeliveryFree, dontShowAgain } = this.state;

    if (productDeliveryFree) {

      if (dontShowAgain) {
        toast.success('Você conseguiu entrega grátis!', {
          position: "top-right",
          autoClose: 2000,
          closeOnClick: true,
          pauseOnHover: false,
          pauseOnFocusLoss: false
        })
        this.setState({
          dontShowAgain: false
        })
      }
    }
  }

  _handleAddProductToCart = (id) => {
    const { _cart, setCartProducts, setGlobalCart, loggedIn } = this.props
    const { products } = this.state

    // Does product exists on our cached states?
    const product = products.find(p => p.id === id)

    if (product !== undefined) {
      let cartProducts = Object.assign([], _cart.products)

      // Check if product is already on cart, if so let's increase its quantity
      const index = cartProducts.findIndex(p => p.id === id)
      if (index >= 0) {
        // Check if product has available stock!!!
        if (cartProducts[index].quantity < cartProducts[index].maxQuantity) {
          cartProducts[index].quantity += 1

        } else {
          toast.info('Desculpe, produto não tem mais estoque!', {
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: false,
            pauseOnFocusLoss: false
          })
        }
      } else {
        // This product has active special price?
        const special = this._getActiveSpecialPrice(product)

        // If we don't have it on cart, let's add
        cartProducts.push({
          id: product.id,
          name: product.name,
          model: product.model,
          price: special && Number(special.price) < Number(product.price) ? special.price : product.price,
          slug: product.slug,
          quantity: 1,
          maxQuantity: product.quantity
        })
      }

      addToCart(product, 'Home')
      // Update Cart redux
      setCartProducts(cartProducts)

      if (loggedIn) {
        // Update Cart Global to remarketing
        setGlobalCart(cartProducts, 'NO')
      }
      this.closeProductModal()
    }
  }

  onProductCardClick = () => {
    this.historyGoBack = true
  }

  closeProductModal = () => {
    const { history } = this.props
    const { goBack, replace } = history

    this.setState({
      currentProduct: undefined,
      currentProductUrl: undefined,
      modalOpen: false,
    }, () => {
    
      if (this.historyGoBack) {
        goBack()
      } else {
        replace('/produtos')
      }
  
      // Reset history check flag
      this.historyGoBack = false

    })
  }

  _renderSearchForm = () => {
    const { productsRequestQuery } = this.state

    return (
      <Formik>
        {
          props => (
            <Form style={{ width: '100%' }}>
              <Row>
                <Col xs={12}>
                  <SearchBar placeholder="O que você está procurando?" value={productsRequestQuery} onChange={this.onSearchChange} />
                </Col>
              </Row>
            </Form>
          )
        }
      </Formik>
    )
  }

  _renderFilter = () => {
    const { _category } = this.props
    const { currentCategory, currentSubcategory } = this.state
    
    let categories = [
      {
        value: -1,
        slug: '/produtos',
        name: "Todos"
      }
    ]
    let subcategories = []

    if (_category.categories) {
      _category.categories.forEach(p => {
        const { category_id, name, slug, meta_title, meta_description } = p

        categories.push({
          value: category_id,
          slug: `/departamentos/${slug}`,
          name: name,
          metaTitle: meta_title,
          metaDescription: meta_description,
          isSubcategory: false
        })
      })
    }

    if (currentCategory) {
      const { slug: categorySlug, meta_title, meta_description } = currentCategory

      subcategories.push({
        value: -1,
        slug: `/departamentos/${categorySlug}`,
        name: "Todos",
        metaTitle: meta_title,
        metaDescription: meta_description,
        isSubcategory: true
      })

      currentCategory.subCategories.forEach(p => {
        const { category_id, name, slug, meta_title, meta_description } = p

        subcategories.push({
          value: category_id,
          slug: `/departamentos/${categorySlug}/${slug}`,
          name: name,
          metaTitle: meta_title,
          metaDescription: meta_description,
          isSubcategory: true
        })
      })
    }

    const selectedCategory = currentCategory ? currentCategory.category_id : 0
    const selectedSubcategory = currentSubcategory ? currentSubcategory.category_id : 0
  
    return (
      <Row style={{ marginLeft: 0, marginTop: 10, marginBottom: 10 }}>
        <Col>
          <FilterDropdown placeholder="Departamentos" options={categories} selected={selectedCategory} onSelect={this.handleFilterChange} />
        </Col>
        <Col>
          {
            subcategories.length > 0 ? (
              <FilterDropdown options={subcategories} selected={selectedSubcategory} onSelect={this.handleFilterChange} />
            ) : null
          }
        </Col>
      </Row>
    )
  }

  _renderProducts = () => {
    const { _store, loggedIn, addCartSingleProduct } = this.props
    const { settings } = _store
    const { config_hide_prices, config_navbar_color } = settings
    const { products, showFeatured, cartProducts, currentCategory, productDeliveryFree, deliveryFreeFromBigest, productsRequestQuery } = this.state

    const hidePrice = config_hide_prices ? config_hide_prices && !loggedIn : false


    // Wrapper addition product to cart
    const addProductToCart = (productId) => {
      this._handleAddProductToCart(productId)

      if (productsRequestQuery.length > 0) {
        const product = products.find(p =>  p.id === productId)
        
        if (product) {
          // This dispatch only feeds watcher purporses
          addCartSingleProduct(product.ean, 'search-result')
        }
      }
    }

    if (products) {
      return products.filter(p => p.featured === showFeatured).map((product, index) => {
        const { id, name, model, image, price, slug, control, classification, laboratory, presentation } = product

        const special = this._getActiveSpecialPrice(product)
        let canShow = false
        
        // Dynamic product quantity
        let { quantity } = product

        const laboratoryName = product.laboratory && product.laboratory.length > 0 ? product.laboratory[0].name : ''
       
        addImpression([{
          ...product,
          laboratory: laboratoryName,
          position: index,
          list: 'Home List'
        }])

        // In cart product
        const productInCart = cartProducts.find(x => x.id === id)
        if (productInCart) {
          quantity = productInCart.maxQuantity;  // productInCart.quantity
        }

        // Verify if product have delivery free
        if (deliveryFreeFromBigest > 0 && quantity > 0) {

          if (special) {
            canShow = special.price > deliveryFreeFromBigest ? true : false
            
          } else {
            canShow = price > deliveryFreeFromBigest ? true : false
          }
        }
  
        if (productDeliveryFree && quantity > 0) {
          canShow = true
        }
        const classificationName = Array.isArray(classification) ? classification[0].name : classification ? classification.name : ''
        return (
          <Col key={`product-${index}`} xs={12} lg={6} style={{ marginBottom: 15 }}>
            <ProductCard
              id={id}
              name={name}
              model={model}
              image={image}
              presentation={presentation}
              price={Number(price)}
              quantity={Number(quantity)}
              special={special}
              slug={slug}
              control={control}
              classification={classificationName}
              laboratory={laboratory && laboratory.length > 0 ? laboratory[0].name : ''}
              hidePrice={hidePrice}
              currentCategory={currentCategory}
              cachedProduct={product}
              onAdd={(productId) => addProductToCart(productId)}
              onClick={this.onProductCardClick}
              storeColor={config_navbar_color}
              deliveryFree={canShow}
              index={index}
              list="Home"
            />
          </Col>
        )
      })
    }
  }

  _renderProductModal = () => {
    const { _store, loggedIn, setLoginDialog, addCartSingleProduct } = this.props
    const { settings } = _store
    const { config_hide_prices, config_whatsapp_phone, config_phone, config_navbar_color } = settings
    const { currentProduct, cartProducts, modalOpen, productDeliveryFree, deliveryFreeFromBigest, productsRequestQuery } = this.state
  
    const hidePrice = config_hide_prices ? config_hide_prices && !loggedIn : false

    if (currentProduct) {
      const { id, ean, ms, name, model, presentation, description, laboratory, image, price, control, classification, categories } = currentProduct
      const special = this._getActiveSpecialPrice(currentProduct)
      let canShow = false

      // Add products to cart
      const handleAddToCart = (id) => {
        this._handleAddProductToCart(id)
        
        if (productsRequestQuery.length > 0) {
          // This dispatch only feeds watcher purporses
          addCartSingleProduct(ean, 'product-detail')
        }
      }

      // Show login modal if needed
      const handleRegister = () => {
        setLoginDialog(true)
      }

      // Dynamic product quantity
      let { quantity } = currentProduct

      // In cart product
      const productInCart = cartProducts.find(x => x.id === id)
      if (productInCart) {
        quantity = productInCart.maxQuantity; // productInCart.quantity
      }
      // Verify if product have delivery free
      if (deliveryFreeFromBigest > 0 && quantity > 0) {
        if (special) {
          canShow = special.price > deliveryFreeFromBigest ? true : false

        } else {
          canShow = price > deliveryFreeFromBigest ? true : false
        }
      }

      if (productDeliveryFree && quantity > 0) {
        canShow = true
      }
      const classificationName = Array.isArray(classification) ? classification[0].name : classification ? classification.name : ''
      return (
        <Modal open={modalOpen} onClose={this.closeProductModal} classNames={{ modal: 'product-modal' }} center>
          <ProductInfo
            id={id}
            ean={ean}
            ms={ms}
            name={name}
            model={model}
            presentation={presentation}
            description={description}
            laboratory={laboratory && laboratory.length > 0 ? laboratory[0].name : ''}
            image={image}
            price={price}
            quantity={quantity}
            special={special}
            control={control}
            classification={classificationName}
            hidePrice={hidePrice}
            storeWANumber={config_whatsapp_phone || ''}
            storePhoneNumber={config_phone || ''}
            historyGoBack={this.historyGoBack}
            onAdd={handleAddToCart}
            onRegister={handleRegister}
            storeColor={config_navbar_color}
            deliveryFree={canShow}
            categories={categories}
          />
        </Modal>
      )
    }
  }

  render() {
    const { _delivery } = this.props
    const { loading, currentProduct, currentCategory, productsRequestQuery, products } = this.state
    const { deliveries } = _delivery

    return (
      <Body>
        <Helmet>
          {!currentProduct ? <link rel="canonical" href={`${window.location.origin}/produtos`} /> : null}
        </Helmet>

        <div ref={(refs) => this.contentContainer = refs}>
          <Row>
            <Col xs={7} xl={8}>
              {this._renderSearchForm()}
              {this._renderFilter()}

              {
                // Search labels
                productsRequestQuery.length > 0 && !loading ? (
                  <Row>
                    <Col xs={12} style={{ marginBottom: 15 }}>
                      <b style={{ color: "grey" }}>
                        {
                          products.length > 0 ? `Resultados de pesquisa para: '${productsRequestQuery}'` : `Nenhum resultado para: '${productsRequestQuery}'`
                        }
                      </b>
                    </Col>
                  </Row>
                ) : null
              }

              <Row>
                {
                  !currentCategory ? (
                    <Col xs={12} style={{ marginBottom: 15 }}>
                      <b style={{ color: "grey" }}>
                        {
                          productsRequestQuery.length === 0 ? 'Destaques' : null
                        }
                      </b>
                    </Col>
                  ) : null
                }
                {this._renderProducts()}

                {
                  loading ? (
                    <Col xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                      <LoadingCircle width={100} height={100} />
                    </Col>
                  ) : null
                }
              </Row>
            </Col>
            <Col xs={5} xl={4}>
              {
                deliveries ? (
                  <Cart fixed={true} />
                ) : null
              }
            </Col>
          </Row>

          {this._renderProductModal()}
        </div>
      </Body>
    )
  }
}

const mapStateToProps = ({ cart, product, search, category, delivery, featuredProduct, startup, login, address }) => ({
  _cart: cart,
  _product: product,
  _category: category,
  _delivery: delivery,
  _featured: featuredProduct,
  _store: startup.store,
  _search: search,
  loggedIn: isLogged(login),
  currentAddress: address.currentAddress
})

const mapDispatchToProps = dispatch => ({
  searchProducts: (param) => dispatch(Creators.productSearchRequest(param)),
  setCartProducts: (products) => dispatch(Creators.cartSetProductRequest(products)),
  getCartProducts: () => dispatch(Creators.cartListProductRequest()),
  addCartSingleProduct: (product_ean, origin) => dispatch(Creators.cartAddSingleRequest(product_ean, origin)),
  getProducts: (category_id, params) => dispatch(Creators.productListRequest(category_id, params)),
  getProductDetail: (slug) => dispatch(Creators.productDetailRequest(slug)),
  getFeaturedProducts: () => dispatch(Creators.featuredProductListRequest()),
  getCategories: () => dispatch(Creators.categoryListRequest()),
  setLoginDialog: (show) => dispatch(Creators.setLoginDialogRequest(show)),
  setCurrentOrderShipping: (shipping) => dispatch(Creators.setCurrentOrderShippingRequest(shipping)),
  setGlobalCart: (products, purchased) => dispatch(Creators.cartGlobalUpdateRequest(products, purchased))
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)