import styled from 'styled-components'

export const Title = styled.p`
  font-family: Gilmer-Light;
  font-size: 26px;
  line-height: 4px;
  color: #707070;
`
export const SubTitle = styled.p`
  font-family: Gilmer-Bold;
  font-size: 26px;
  line-height: 4px;
  color: #707070;
`
export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid #a8a8a8;
  margin-bottom: 30px;
`
export const DividerContent = styled.div`
  background-color: #fff;
  width: 23px;
  margin-bottom: -8px;
  margin-left: 45%;
`
export const ProductImage = styled.img`
  max-width: 100px;
  height: auto;
`
export const ProductText = styled.p`
  font-family: Gilmer;
  font-size: 14px;
  color: #A8A8A8;
  line-height: 15px;
`
export const InfoText = styled.p`
  font-family: ${({ bold }) => `Gilmer-${bold ? 'Bold' : 'Light'}`};
  font-size: 15px;
  color: #707070;
`
export const ResumeContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 5px;
`
export const ResumeText = styled.span`
  font-family: ${({ bold }) => `Gilmer-${bold ? 'Bold' : 'Light'}`};
  font-size: 15px;
  color: #707070;
`
