import styled from 'styled-components'
import InputMasked from 'react-input-mask'

export const SectionContainer = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  margin-bottom: 40px;
`
export const SectionContent = styled.div`
  width: 95%;
  height: 80%;
  margin: auto;
  margin-bottom: 20px;
`
export const Title = styled.h3`
  font-size: 28px;
  font-family: Gilmer-Bold;
  color: #707070;
`
export const SubTitle = styled.h3`
  font-size: 16px;
  font-family: Gilmer;
  color: #707070;
  margin-bottom: 50px;
`
export const Label = styled.label`
  font-size: 14px;
  font-family: Gilmer;
  color: #707070;
  margin-bottom: 5px;
`
export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  &:not(:last-child) {
    margin-right: 20px;
  }
`
export const Input = styled.input`
  height: ${({height}) => height ? `${height}px` : '26px'}
  width: ${({width, textAlign}) => width ? `${width}px` : textAlign === 'center' ? '100%' : 'calc(100% - 10px)'};
  border-radius: 10px;
  border: 1px solid #707070; 
  color: #707070;
  font-family: Gilmer;
  font-size: ${({fontSize}) => fontSize ? `${fontSize}px` : '22px'};
  text-align: ${({textAlign}) => textAlign || 'left'};
  outline: none;
  background-color: #FFFFFF;
  padding: ${({textAlign}) => textAlign === 'center' ? '15px 0 15px 0' : '15px 0 15px 10px'};
  margin-bottom: 10px;

  &:focus {
    border: 1px solid ${({ cpfvalid }) => cpfvalid ? '#00BF91' : '#c0392b'};
  }

  &::placeholder {
    font-family: Gilmer-Light;
  }
`
export const InputMask = styled(InputMasked)`
  height: ${({height}) => height ? `${height}px` : '26px'};
  width: ${({width, textAlign}) => width ? `${width}px` : textAlign === 'center' ? '100%' : 'calc(100% - 10px)'};
  border-radius: 10px;
  border: 1px solid #707070; 
  color: #707070;
  font-family: Gilmer;
  font-size: ${({fontSize}) => fontSize ? `${fontSize}px` : '22px'};
  text-align: ${({textAlign}) => textAlign || 'left'};
  outline: none;
  background-color: #FFFFFF;
  background: ${({ image }) => image ? `url(${image}) no-repeat scroll 95% 50%` : 'unset'};
  padding: ${({textAlign}) => textAlign === 'center' ? '15px 0 15px 0' : '15px 0 15px 10px'};

  &:focus {
    border: 1px solid ${({ validfield }) => validfield && validfield === 'true' ? '#00BF91' : '#c0392b'};
  }

  &::placeholder {
    font-family: Gilmer-Light;
  }
`
