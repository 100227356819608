import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as Icons from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@components/Button'
import { AddressCardContainer, InfoContainer, ActionContainer, AddressPrimary, AddressSecondary } from './styles'
import './index.css'

export default class AddressOption extends Component {
  static propTypes = {
    selected: PropTypes.bool,
    onDelete: PropTypes.func,
    onSelect: PropTypes.func
  }

  static defaultProps = {
    onSelect: () => {}
  }

  constructor(props) {
    super(props)

    this.state = {
      hide: false,
      deleted: false,
    }
  }

  render() {
    const {
      street,
      city,
      uf,
      number,
      selected,
      onSelect,
    } = this.props

    return (
        <AddressCardContainer>
           <FontAwesomeIcon color="#a8a8a8" icon={Icons.faStore} size={"2x"} style={{marginRight: 5}}/>
          <InfoContainer>
            <AddressPrimary>
              {`RETIRAR NA LOJA`}
            </AddressPrimary>
            <AddressSecondary>
            {street}-{number} - {city} {uf}
            </AddressSecondary>
          </InfoContainer>

          <ActionContainer>
            <Button style={{width: 200}} onClick={() => onSelect()} color={selected ? 'secondary' : 'primary'}>{selected ? 'Continuar com a opção': 'Escolher esta opção'}</Button>
          </ActionContainer>
        </AddressCardContainer>
    )
  }
}
