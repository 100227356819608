import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types } from '../actions'

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: false,
  products: null
})

export const featuredProductListRequest = (state = INITIAL_STATE) =>
  Object.assign({}, state, {
    fetching: true,
    error: false,
    data: null
  })

export const featuredProductListSuccess = (state = INITIAL_STATE, { products }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    products
  })

export const featuredProductListFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    products: null,
    error
  })

/**
 * Handlers
 */

export const HANDLERS = {
  [Types.FEATURED_PRODUCT_LIST_REQUEST]: featuredProductListRequest,
  [Types.FEATURED_PRODUCT_LIST_SUCCESS]: featuredProductListSuccess,
  [Types.FEATURED_PRODUCT_LIST_FAILURE]: featuredProductListFailure
}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)

export default reducer
