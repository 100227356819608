import styled from 'styled-components'
import InputMasked from 'react-input-mask'

export const FooterContainer = styled.footer`
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
  color: #A8A8A8;
`
export const FooterRow = styled.div`
  display: flex;
  width: 62%;
`
export const FooterCol = styled.div`
  width: ${({ width }) => width};
  align-self: center;
  padding: 8px;
`
export const Img = styled.img`
  max-width: 100%;
  height: ${({ height }) => height};
`

export const FooterColEurofarma = styled.div`
  width: ${({ width }) => width};
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  >p {
    margin: 1px 0 3px 10px;
  }

  >a { 
    img {
      margin-top: 2px;
      align-self: flex-start;
      max-width: 80%;
    }
  }

`
export const ImgEurofarma = styled.img``

export const InputMask = styled(InputMasked)`
  font-size: ${({ fontSize }) => fontSize ? `${fontSize}px` : '22px'};
  width: ${({ width }) => width ? `${width}px` : '140px'};
  color: #A8A8A8;
  border: none;
  cursor: text;
  background: none;
  background-color: transparent;
`
