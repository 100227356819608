import React from 'react'
import { SVG } from './styles.js'

const Cart = ({ height = 20, width = 20, style={}}) => (
  <SVG 
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    style={style}
    viewBox="0 0 30.001 28.337">
    <path
      d="M69.881-1960.162a3.5,3.5,0,0,1,3.5-3.5,3.5,3.5,0,0,1,3.5,3.5,3.5,3.5,0,0,1-3.5,3.5A3.5,3.5,0,0,1,69.881-1960.162Zm-11.654,0a3.5,3.5,0,0,1,3.5-3.5,3.5,3.5,0,0,1,3.5,3.5,3.5,3.5,0,0,1-3.5,3.5A3.5,3.5,0,0,1,58.227-1960.162Zm-.65-5.623-3.216-16.8-3.478-.523a.975.975,0,0,1-.868-1.1,1.107,1.107,0,0,1,1.242-.789l4.273.637a1.052,1.052,0,0,1,.915.782l.829,4.336h21.6a1.193,1.193,0,0,1,.907.393.841.841,0,0,1,.161.857l-2.988,7.973a1.117,1.117,0,0,1-1.068.663H59.165l.471,2.455H75.983a1.048,1.048,0,0,1,1.121.957,1.048,1.048,0,0,1-1.121.957h-17.3A1.083,1.083,0,0,1,57.578-1965.785Z" 
      transform="translate(-50 1985)" 
      fill="#fff"
    />
  </SVG>
)

export default Cart
