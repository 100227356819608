import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/es/integration/react'
import { ConnectedRouter } from 'connected-react-router'
import { configureStore, history } from './store/configureStore'
import AppContainer from '@components/App'

// Redux store configuration
const { store, persistor } = configureStore()

export default class App extends Component {
  componentDidMount() {
    const { location } = window
    document.title = `Farmácia | Drogaria | Delivery - ${location.host}`
  }

  render() {
    return (
      <PersistGate persistor={persistor}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <AppContainer />
          </ConnectedRouter>
        </Provider>
      </PersistGate>
    )
  }
}
