import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ShipmentContainer, ShipmentHeader, ShipmentList, ShipmentItem, ShipmentListItem, CompanyLogo, TextItem } from './styles'
import { floatToBRL } from '../../helpers/MoneyFormat'
class MobileShippingOptions extends Component {

  static propTypes = {
    onSelect: PropTypes.func,
    options: PropTypes.array,
    checked: PropTypes.bool
  }

  static defaultProps = {
    options: [],
    checked: false,
    onSelect: (id) => { }
  }
  // eslint-disable-next-line 
  defaultPicture = 'https:\/\/sandbox.melhorenvio.com.br\/images\/shipping-companies\/correios.png'

  render() {
    const { options, onSelect } = this.props
 
    return (
      <ShipmentContainer>
        <ShipmentHeader>
          <li>Transportadora</li>
        </ShipmentHeader>
        <ShipmentList>
          {options && options.filter(opt => !opt.error).sort((a, b) => a.price - b.price).map(opt => {
         
            return (
              <ShipmentListItem key={opt.id}>
                <ShipmentItem key={opt.id}>
                  <li>
                    <CompanyLogo src={opt.company.picture || this.defaultPicture} />
                  </li>
                  <li>
                    <TextItem>{opt.name}</TextItem>
                  </li>
                  <li>
                    <TextItem>
                      {opt.delivery_range.min} - {opt.delivery_range.max} dias
                    </TextItem>
                  </li>
                  <li>
                    <TextItem>{floatToBRL(Number(opt.price))}</TextItem>
                  </li>
                  <li>
                    <input type="radio" name="shipping_option" value={opt.id} id={`option_${opt.id}`} onChange={({ target: { value } }) => onSelect(value)} />
                    <label htmlFor={`option_${opt.id}`}><span></span></label>
                  </li>
                </ShipmentItem>
              </ShipmentListItem>
            )
          })}
        </ShipmentList>
      </ShipmentContainer>
    )
  }
}

export default MobileShippingOptions