import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import Button from '@components/Button'
import Body from '@components/MobileBody'
import Input from '@components/Input'
import { connect } from 'react-redux'
import { RegisterAddressContainer, Title, RegisterAddressContent, InputMask, Link, ErrorContainer } from './styles'
import { Creators } from "../../actions";
import LoadingCircle from '@components/Icons/LoadingCircle'
import { Redirect } from 'react-router-dom'
import ShippingOptions from "@components/MobileShippingOptions"
import AddressCard from "@components/MobileAddressCard"
import ErrorCodes from '../../helpers/ErrorCodes'
import { isLogged } from '../../redux/LoginRedux'

class CalculateShipping extends Component {
  static defaultProps = {
    fetching: false,
    shipping: null
  }
  constructor(props) {
    super(props)

    this.state = {
      action: 'list-address',
      error: null,
      shippingoptions: [],
      searchShipping: false,
      shipping_sender: null,
      addresses: [],
      address: {
        postcode: '',
        street: '',
        number: '',
        neighborhood: '',
        complement: '',
        state_address: '',
        city: ''
      }
    }
  }

  componentDidMount() {
    const { attemptGetAddresses, loggedIn } = this.props

    if (loggedIn) {
      attemptGetAddresses()
    }
  }

  componentWillReceiveProps(newProps) {
    let newState = Object.assign({}, this.state)
    const { shipping, fetching, shippingError, addresses, _address, store } = newProps

    if (!fetching && !shipping && shippingError) {
      newState.error = ErrorCodes(shippingError)
    }

    if (store) {
      const { settings } = store
      const { config_shipping_courier, config_best_shipping } = settings

      newState.shipping_sender = config_shipping_courier && !config_best_shipping ? 'courier' :
        config_best_shipping && !config_shipping_courier ? 'bestshipping' : null
    }

    if (!_address.fetching && shipping && !shippingError) {
      newState.address.street = shipping.address.street
      newState.address.neighborhood = shipping.address.neighborhood
      newState.address.postcode = shipping.address.zipcode.replace(/[^0-9]/g, '')
      newState.address.state_address = shipping.address.state
      newState.address.city = shipping.address.city
    }

    if (!_address.fetching && shipping && !shippingError && newState.searchShipping) {
      newState.shippingoptions = shipping.shipping
      newState.searchShipping = false
    }

    if (!_address.fetching && addresses) {
      newState.addresses = []
      addresses.filter(a => a.not_deliverable === false && a.addressType && a.addressType === 'delivery_company').forEach(addr => {
        const { address_id, street, number, complement, neighborhood, postcode, not_deliverable, addressType } = addr
        newState.addresses.push({
          id: address_id,
          number,
          street,
          complement,
          neighborhood,
          postcode,
          not_deliverable,
          addressType
        })
      })
    }

    if (this.state !== newState) {
      this.setState(newState)
    }
  }

  validateFields = () => {
    const { address } = this.state

    const streetValid = address.street.length > 0
    const numberValid = address.number.length > 0
    const zipcodeValid = address.postcode.length === 8
    const neighborhoodValid = address.neighborhood.length > 0

    const allValid = zipcodeValid && streetValid && numberValid && neighborhoodValid

    return {
      allFields: allValid,
      validNumber: numberValid,
      validStreet: streetValid,
      validZipocde: zipcodeValid,
      validNeighBorhood: neighborhoodValid
    }
  }

  handleSubmit = () => {
    const { attemptRegisterAddress, attemptGetAddresses } = this.props
    const { address } = this.state

    this.setState({
      action: 'list-address'
    }, () => {
      attemptRegisterAddress(address)

      setTimeout(() => {
        attemptGetAddresses()
      }, 800)
    })
  }

  onSelectAddress = (id) => {
    const { attempGetShipping, setCurrentShippingAddress, setCurrentAddress, products } = this.props
    const { addresses, shipping_sender } = this.state
    const address = addresses.find(a => a.id.toString() === id.toString())

    if (address) {
      this.setState({
        address,
        searchShipping: true,
        shippingoptions: []
      }, () => {
        setCurrentAddress(null)
        setCurrentShippingAddress(address)
        attempGetShipping(address.postcode, products, shipping_sender)
      })
    }
  }

  onChangeFields = ({ target: { name, value } }) => {
    this.setState((state) => ({
      ...state,
      address: {
        ...state.address,
        [name]: value
      }
    }))
  }

  onSelectShipping = (id) => {
    const { shippingoptions } = this.state
    const { setCurrentOrderShipping } = this.props
    const shipping = shippingoptions.find(opt => Number(opt.id) === Number(id))

    if (shipping) {
      setCurrentOrderShipping(shipping)
    }
  }

  onChangeAddNewAddress = () => {
    this.setState({
      action: 'add-address',
      shippingoptions: [],
      address: {
        postcode: '',
        street: '',
        number: '',
        neighborhood: '',
        complement: '',
        state_address: '',
        city: ''
      }
    }, () => {
      this.props.setCurrentOrderShipping(null)
      this.props.setCurrentShippingAddress(null)
    })
  }

  onChangeFields = ({ target: { name, value } }) => {
    if (name === 'shipping') {
      const { shippingoptions } = this.state

      const option = shippingoptions.find(x => x.name.toString() === value.toString())

      if (option) {
        this.setState((state) => ({
          ...state,
          shipping: option
        }))
      }
    }

    this.setState((state) => ({
      ...state,
      address: {
        ...state.address,
        [name]: value
      }
    }))
  }

  handleSearchShipping = ({ target: { name, value } }) => {
    const { attempGetShipping, products } = this.props
    const { shipping_sender } = this.state
    if (value.replace(/[^0-9]/g, '').length === 8) {

      this.setState((state) => ({
        ...state,
        address: {
          ...state.address,
          [name]: value.replace(/[^0-9]/g, '')
        }
      }), () => {
        setTimeout(() => {
          this.setState((state) => ({
            ...state,
            shippingoptions: [],
            error: null,
            address: {
              ...state.address,
              street: '',
              number: '',
              complement: '',
              neighborhood: '',
            }
          }), () => {
            attempGetShipping(this.state.address.postcode, products, shipping_sender)
          })
        }, 1200)
      })
    }
  }

  handleDelete = (id) => {
    const { attemptDeleteAddress, attemptGetAddresses } = this.props
    attemptDeleteAddress(id)

    setTimeout(() => {
      attemptGetAddresses()
    }, 800)

    this.setState({
      shippingoptions: []
    })
  }

  _renderAddressList = () => {
    const { addresses, address } = this.state

    if (addresses.length > 0) {
      return addresses.filter(a => a.not_deliverable === false && a.addressType && a.addressType === 'delivery_company').map((addr, index) => {
        const { id, street, number, neighborhood, postcode } = addr
        const { city } = neighborhood
        const { zone } = city
        const selected = id === address.id
        return (
          <AddressCard
            key={index}
            id={id}
            uf={zone.code}
            slideIn={true}
            street={street}
            number={number}
            postcode={postcode}
            city={city.name}
            selected={selected}
            slideInOffet={index + 1}
            neighborhood={neighborhood.name}
            onDelete={this.handleDelete}
            onSelect={this.onSelectAddress}
          />
        )
      })
    }
  }

  _registerAddressForm = () => {
    const { address, error } = this.state
    const fields = this.validateFields()

    return (
      <RegisterAddressContent>
        <Row>
          <Col xs={12}>
            <InputMask
              type="tel"
              required
              id="zipcode"
              name="postcode"
              autoComplete="off"
              placeholder="Digite seu CEP"
              mask="99999-999"
              height={10}
              fontSize={16}
              onChange={this.handleSearchShipping}
            />
            <Row style={{ marginTop: -5 }} >
              <Col xs={12} lg={2} md={6} sm={6}>
                <Link href="https://buscacepinter.correios.com.br/app/endereco/index.php" target="_blank">Não sei meu CEP</Link>
              </Col>
            </Row>
            {!fields.validZipocde && <ErrorContainer>CEP é obrigátorio</ErrorContainer>}
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col xs={12} lg={6} md={6} sm={6}>
            <Input name="street" placeholder="Rua" autoComplete="off" value={address.street} fontSize={16} height={10} textAlign="center" onChange={this.onChangeFields} />
            {!fields.validStreet && <ErrorContainer>rua é obrigátorio</ErrorContainer>}
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col xs={12} lg={2} md={6} sm={6}>
            <Input name="number" placeholder="Número" autoComplete="off" value={address.number} type="number" fontSize={16} height={10} textAlign="center" onChange={this.onChangeFields} />
            {!fields.validNumber && <ErrorContainer>número é obrigátorio</ErrorContainer>}
          </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
          <Col xs={12} lg={6} md={6} sm={6}>
            <Input name="neighborhood" placeholder="Bairro" autoComplete="off" value={address.neighborhood} fontSize={16} height={10} textAlign="center" onChange={this.onChangeFields} />
            {!fields.validNeighBorhood && <ErrorContainer>bairro é obrigátorio</ErrorContainer>}
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={6} md={6} sm={6}>
            <Input name="complement" placeholder="Complemento" autoComplete="off" value={address.complement} fontSize={16} height={10} maxLength={39} textAlign="center" style={{ marginTop: 10 }} onChange={this.onChangeFields} />
          </Col>
        </Row>

        {error &&
          <Row style={{ marginTop: 15 }}>
            <Col xs={12} lg={12} md={6} sm={6}>
              <ErrorContainer>{error}</ErrorContainer>
            </Col>
          </Row>
        }
      </RegisterAddressContent>
    )
  }

  render() {
    const { shippingoptions, action } = this.state
    const { fetching, products, currentOrderShipping } = this.props
    const disabled = this.validateFields().allFields

    return (
      <Body>
        <RegisterAddressContainer>
          <Title>Calcular Frete</Title>
          {action === 'list-address' && this._renderAddressList()}
          {action === 'add-address' && this._registerAddressForm()}
          {shippingoptions.length > 0 &&
            <ShippingOptions
              options={shippingoptions}
              selected={currentOrderShipping}
              onSelect={this.onSelectShipping}
            />}

          <Row style={{ justifyContent: "center", marginTop: 15 }}>
            {fetching ? <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
              <LoadingCircle width={50} height={50} />
            </div> :
              action === 'add-address' ?
                <Button
                  style={{ fontSize: 16 }}
                  disabled={!disabled}
                  onClick={this.handleSubmit}
                >Salvar endereço
                </Button>
                :
                <Button
                  style={{ fontSize: 16 }}
                  onClick={this.onChangeAddNewAddress}
                >Adicionar novo endereço
                </Button>
            }
          </Row>
        </RegisterAddressContainer>

        {currentOrderShipping && <Redirect to="/checkout" />}

        {products.length <= 0 && <Redirect to="/produtos" />}
      </Body>
    )
  }
}

const mapStateToProps = ({ delivery, cart, address, startup, login }) => ({
  _address: address,
  store: startup.store,
  products: cart.products,
  shipping: delivery.shipping,
  fetching: delivery.fetching,
  addresses: address.addresses,
  shippingError: delivery.error,
  currentAddress: address.currentAddress,
  currentOrderShipping: address.currentOrderShipping,
  loggedIn: isLogged(login),
})

const mapDispatchToProps = dispatch => ({
  attempGetShipping: (zipcode, products, sender) => dispatch(Creators.deliveryShippingRequest(zipcode, products, sender)),
  setCurrentAddress: (address) => dispatch(Creators.setCurrentAddressRequest(address)),
  attemptGetAddresses: () => dispatch(Creators.addressListRequest()),
  attemptRegisterAddress: ({ address_id, firstname, lastname, street, neighborhood_id, complement, postcode, not_deliverable = false, addressType = 'delivery_company', neighborhood, city, state_address, number }) => dispatch(Creators.addressRegisterRequest(address_id, firstname, lastname, street, neighborhood_id, complement, postcode, not_deliverable, addressType, neighborhood, city, state_address, number)),
  attemptDeleteAddress: (address_id) => dispatch(Creators.addressDeleteRequest(address_id)),
  setCurrentOrderShipping: (shipping) => dispatch(Creators.setCurrentOrderShippingRequest(shipping)),
  setCurrentShippingAddress: (address) => dispatch(Creators.setCurrentShippingAddressRequest(address))
})


export default connect(mapStateToProps, mapDispatchToProps)(CalculateShipping)