import styled from 'styled-components'

export const BannerContainer = styled.div`
  width: 100vw;
  height: 190px;
  background-color: ${({ color }) => color};
  @media(min-width: 640px) {
    height: 200px;
  }
`
export const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`