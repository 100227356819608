import React, { Component } from 'react'
import { connect } from 'react-redux'
import Body from '@components/MobileBody'
import ContactCard from '@components/ContactCard'
import { Col, Row } from 'react-flexbox-grid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as BrandIcons from '@fortawesome/free-brands-svg-icons'
import * as Icons from '@fortawesome/free-solid-svg-icons'
import GAEvents from '../../helpers/GAEvents'
import { List, InputMask } from './styles'

class Contact extends Component {
  render() {
    const { store } = this.props
    const { settings } = store
    const {
      config_address,
      config_store_city,
      config_store_number,
      config_phone,
      config_email,
      config_whatsapp_phone,
      config_whatsapp_button
    } = settings

    /*
      <li>
        <FontAwesomeIcon icon={BrandIcons.faFacebookF} style={{marginRight: 15}} />
        Facebook
      </li>
      <li>
        <FontAwesomeIcon icon={BrandIcons.faTwitter} style={{marginRight: 8}} />
        Twitter
      </li>
      <li>
        <FontAwesomeIcon icon={BrandIcons.faInstagram} style={{marginRight: 10}} />
        Instagram
      </li>
    */

    return (
      <Body>
        <Row>
          <Col xs={12}>
            <List style={{ margin: '30px 0 0 16px' }}>
              {
                config_whatsapp_button && config_whatsapp_phone && config_whatsapp_phone.length > 0 && (
                  <li>
                    <a href={`https://wa.me/55${config_whatsapp_phone.replace(/\D+/g, '')}`} target="_blank" rel="noopener noreferrer" onClick={() => { GAEvents({ category: 'Contato', action: 'Click no botão do WhatsApp na página de Contatos' }) }}>
                      <FontAwesomeIcon icon={BrandIcons.faWhatsapp} size="1x" style={{ marginRight: 10, color: '#46C655' }} />
                      WhatsApp
                    </a>
                  </li>
                )
              }
              {config_phone && config_phone.length > 0 &&
                <li>
                  <a href={`tel:${config_phone}`} style={{ outline: 'none' }} onClick={() => { GAEvents({ category: 'Contato', action: 'Click no botão de Telefone' }) }}>
                    <FontAwesomeIcon icon={Icons.faPhone} rotation={90} style={{ marginRight: 8 }} />
                    <InputMask value={config_phone} disabled fontSize={15} mask="(99) 9999-9999" /> 
                  </a>
                </li>
              }
            </List>
          </Col>
        </Row>
        <Row style={{ marginTop: 25 }}>
          <Col xs={9} xsOffset={1}>
            <ContactCard title={config_store_city}>
              <List>
                <li>{config_address}, {config_store_number}</li>
                <li>{<InputMask value={config_phone} disabled fontSize={12} mask="(99) 9999-9999" /> }</li>
                <li>{config_email}</li>
              </List>
            </ContactCard>
          </Col>
        </Row>
      </Body>
    )
  }
}

const mapStateToProps = ({ startup }) => ({
  store: startup.store
})

export default connect(mapStateToProps, null)(Contact)
