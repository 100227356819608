import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const AddressCardContainer = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 85vw;
  height: 120px;
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 1px 3px 10px rgba(0,0,0, 0.2);
  padding: 0px 15px 0px 10px;
  margin-bottom: 20px;
   
  &:hover {
    box-shadow: 1px 3px 20px rgba(0,0,0, 0.2);
    transition: box-shadow .5s;
  }
`
export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`
export const AddressPrimary = styled.div`
  font-family: Gilmer-Light;
  font-size: 15px;
  color: #A8A8A8;
`