import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Container, HeaderBar, BottomBar, Content, Text } from './styles'

class Splash extends Component {
  static propTypes = {
    hide: PropTypes.bool
  }

  static defaultProps = {
    hide: false
  }

  constructor(props) {
    super(props)

    this.state = {
      isHiding: false,
      hideContent: true,
      hideBackgroud: false,
      hideAll: false
    }
  }

  componentDidMount() {
    // Show loading text
    this.setState({
      hideContent: false
    })
  }

  componentWillReceiveProps(newProps) {
    const { hide } = newProps
    
    // Everything is loaded, so we can dismiss this splash
    if (hide && hide !== this.state.isHiding) {
      this.setState({
        isHiding: true
      }, () => {
        this.dismiss()
      })
    }
  }

  dismiss = () => {
    const { onFinish } = this.props

    setTimeout(() => {
      this.setState({
        hideContent: true
      })
    }, 500)

    setTimeout(() => {
      this.setState({
        hideBackgroud: true
      })
    }, 1500)

    setTimeout(() => {
      this.setState({
        hideAll: true
      }, () => {
        onFinish()
      })
    }, 2000)
  }

  render() {
    const { store } = this.props
    const { hideContent, hideBackgroud, hideAll } = this.state

    let color = '#3498db'
    if (store) {
      const { settings } = store
      const { config_navbar_color } = settings

      if (config_navbar_color) {
        color = config_navbar_color
      }
    }

    if (hideAll) return null

    return (
      <Container style={{zIndex: 99999}}>
        <HeaderBar className={hideBackgroud ? 'animated slideOutUp' : ''} color={color} />
        <BottomBar className={hideBackgroud ? 'animated slideOutDown' : ''} color={color} />
        
        <Content className={hideContent ? 'animated fadeOut' : ''}>
          <Text className="tracking-in-contract-bck-top">Carregando</Text>
          <Text className="tracking-in-contract-bck-bottom">as melhores ofertas</Text>
        </Content>
      </Container>
    )
  }
}

const mapStateToProps = ({ startup }) => ({
  store: startup.store
})

export default connect(mapStateToProps, null)(Splash)
