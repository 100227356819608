import React,{ Component } from "react";
import { AcceptBtn, Container, Row, Text, TextLinked } from "./styles";



export default class CookiePopup extends Component {

  render(){
    const { allowCookies } = this.props
    
    return (
      <Container>
        <Text>
        Este site está em conformidade com a Lei Geral de Proteção de Dados (LGPD) e utiliza cookies para oferecer uma melhor experiência ao visitante. Ao navegar em nosso site, você concorda com a utilização de cookies 
        </Text>
        <Row>
          <Text>
            e com a nossa
            <TextLinked 
              target="_blank" 
              rel="noopener noreferrer" 
              href="https://myp-public.s3-us-west-2.amazonaws.com/static/policy.pdf" 
              download 
            >
                Política de Privacidade.
            </TextLinked>
          </Text>
          <AcceptBtn onClick={allowCookies} >
              Concordo  
          </AcceptBtn>
        </Row>
      </Container>
    )
  }
}