import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import LaddaButton, { XL } from '@zumper/react-ladda'
import Input from '@components/Input'
import { Container, Title } from './styles'
import { Creators } from '../../actions'
import ErrorCode from '../../helpers/ErrorCodes'

class ResetPassword extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      canSave: false,
      isValidToken: true,
      error: null,
      success: false,
      inputs: {
        password: '',
        repeatPassword: ''
      }
    }
  }

  componentDidMount() {
    const { attemptValidateToken, match } = this.props
    const { token } = match.params

    attemptValidateToken(token)
  }

  componentWillReceiveProps(newProps) {
    const { fetching, error, validToken } = newProps
    const { loading } = this.state
    let newState = Object.assign({}, this.state)

    if (!fetching && !error && validToken === false) {
      newState.isValidToken = false
    }

    if (loading && !fetching) {
      if (error) {
        newState.error = ErrorCode(error)
        newState.success = false
      } else {
        newState.error = null
        newState.success = true
      }
    }

    if (this.state !== newState) {
      this.setState(newState)
    }
  }

  onChangeField = ({ target: { name, value } }) => {
    this.setState(state => ({
      ...state,
      inputs: {
        ...state.inputs,
        [name]: value
      }
    }), () => {
      // Validate register fields
      this.validateRegisterFields()
    })
  }

  validateRegisterFields = () => {
    const { canSave, inputs } = this.state
    const { password, repeatPassword } = inputs
    const bValid = password.length > 3 && password === repeatPassword

    if (bValid !== canSave) {
      this.setState({
        canSave: bValid
      })
    }
  }

  _save = () => {
    const { attemptResetPassword, match } = this.props
    const { loading, inputs } = this.state
    const { password } = inputs
    const { token } = match.params

    if (!loading) {
      this.setState({
        loading: true
      }, () => {
        attemptResetPassword({
          token,
          password
        })
      })
    }
  }

  render() {
    const { loading, canSave, isValidToken, success, inputs } = this.state
    const { password, repeatPassword } = inputs

    return (
      <Container>
        {
          !success ? (
            <React.Fragment>
              <Title fontSize={24} style={{marginBottom: 20}} bold>Alteração de senha</Title>

              <Input type="password" name="password" value={password} placeholder="Digite sua nova senha" onChange={this.onChangeField} style={{ marginBottom: 10 }} height={10} fontSize={16} />
              <Input type="password" name="repeatPassword" value={repeatPassword} placeholder="Confirme sua nova senha" onChange={this.onChangeField} style={{ marginBottom: 10 }} height={10} fontSize={16} />

              <LaddaButton
                type="submit"
                className="btn-ladda-primary"
                loading={loading}
                size={XL}
                spinnerSize={30}
                spinnerColor="#ddd"
                spinnerLines={12}
                onClick={this._save}
                disabled={!canSave}
              >
                Salvar
              </LaddaButton>
            </React.Fragment>
          ) : (
            <Title fontSize={24} style={{marginBottom: 20}} bold>Senha alterada com sucesso! :D</Title>
          )
        }

        {
          // Redirect to home if toke is not valid
          !isValidToken ? <Redirect to="/" /> : null
        }
      </Container>
    )
  }
}

const mapStateToProps = ({ login }) => ({
  fetching: login.fetching,
  error: login.error,
  validToken: login.validResetToken
})

const mapDisptachToProps = dispatch => ({
  attemptValidateToken: (token) => dispatch(Creators.validateResetPasswordTokenRequest(token)),
  attemptResetPassword: ({ token, password }) => dispatch(Creators.resetPasswordRequest(token, password))
})

export default connect(mapStateToProps, mapDisptachToProps)(ResetPassword)
