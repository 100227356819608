import styled from 'styled-components'

export const EmptyOrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Text = styled.span`
  font-family: Gilmer-Light;
  font-size: 15px;
  color: #A8A8A8;
`
