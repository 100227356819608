import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { OrderProgressContainer, ConnectionContainer, Circle, Connector, Description } from './styles'

export default class OrderProgress extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
      active: PropTypes.bool
    })),
    color: PropTypes.string
  }

  static defaultProps = {
    data: [],
    color: '#00BF91'
  }

  constructor(props) {
    super(props)

    this.connectorsRefs = []
    this.state = {
      connectorsPosition: []
    }
  }

  componentDidMount() {
    this._updatePositions();
    window.addEventListener('resize', () => {
      this._updatePositions()
    })
  }

  _updatePositions = () => {
    const { connectorsPosition } = this.state

    this.connectorsRefs.forEach((ref, index) => {
      connectorsPosition[index] = ref.offsetLeft
    })

    this.setState({
      connectorsPosition
    })
  }

  _renderConnection = () => {
    const { data, color } = this.props

    return data.map((item, index) => {
      const { active } = item

      return (
        <React.Fragment key={index}>
          <Circle active={active} ref={(refs) => this.connectorsRefs[index] = refs} color={color} />
          {(index + 1) < data.length ? <Connector active={data[index + 1].active} color={color} /> : null}
        </React.Fragment>
      )
    })
  }

  _renderDescriptions = () => {
    const { data } = this.props
    const { connectorsPosition } = this.state

    if (connectorsPosition.length > 0) {
      return data.map((item, index) => {
        const { text } = item
        const offsetLeft = connectorsPosition[index]

        return <Description key={index} offsetLeft={offsetLeft}>{text}</Description>
      })
    }
  }

  render() {
    return (
      <OrderProgressContainer>
        <ConnectionContainer>
          {this._renderConnection()}
        </ConnectionContainer>
        <ConnectionContainer>
          {this._renderDescriptions()}
        </ConnectionContainer>
      </OrderProgressContainer>
    )
  }
}
