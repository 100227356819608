import { create } from 'apisauce'
import Config from '../config'

const api = create({
  baseURL: Config.searchEngineApi
})

const searchProducts = ({ fingerprint, store, tenant = '', query }) => api.post('v2/search', { fingerprint, store, tenant, query })
const categoryProducts = ({ tenant, storeId, category_id, params }) => api.get(`v2/category/${tenant}/${storeId}/${category_id}`, params)

export default {
  searchProducts,
  categoryProducts
}
