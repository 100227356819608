import styled from 'styled-components'

export const OrderProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px;
`
export const ConnectionContainer = styled.div`
  display: flex;
  flex-direction: row;
`
export const Circle = styled.div`
  height: 10px;
  width: 10px;
  background: transparent;
  border: 3px solid ${({ active, color }) => active ? color : '#A8A8A8' };
  border-radius: 100%;
`
export const Connector = styled.div`
  height: 5px;
  width: calc(100% - 350px);
  box-sizing: border-box;
  background-color: ${({ active, color }) => active ? color : '#A8A8A8' };
  margin-top: 5px;
`
export const Description = styled.p`
  position: absolute;
  width: 100px;
  font-family: Gilmer-Light;
  font-size: 15px;
  text-align: center;
  color: #707070;
  left: ${({ offsetLeft }) => `${offsetLeft - 50}px`};
`
