import styled from 'styled-components'

export const Nav = styled.nav`
  position: relative;
  height: 50px;
  background-color: ${({ bgColor }) => bgColor || ' #1999F9'};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  @media(min-width: 768px) {
    height: 60px;
    justify-content: flex-start;
    padding: 0 0 0 270px;
    max-width: calc(100% - 316px);
    width: unset;
  }
  @media(min-width: 1000px) {
    justify-content: flex-start;
    padding: 0 0 0 calc(19% + 270px);
    width: unset;
  }
`
export const NavBarItem = styled.span`
  flex-direction: column;
  text-decoration: none;
  cursor: pointer;
  color: #ccc;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  padding: 8px 15px;
  height: 34px;

  &:last-child {
    border-right: 0;
  }

  @media(min-width: 768px) {
    font-size: 16px;
    line-height: 16px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    &:first-child {
      padding: 0 15px 0 0;
      text-align: left;
    }
  }
  @media(min-width: 1000px) {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 50px;
    &:first-child {
      padding: 0 50px 0 0;
      text-align: left;
    }
  }
`
export const NavBarItemDivider = styled.span`
  height: 50%;
  border-right: 1px solid #eee;
`
export const Indicator = styled.hr`
  height: 3px;
  width: 0px;
  margin: 0;
  background: white;
  border: none;
  border-radius: 50px;
  opacity: .8
  transition: width 500ms;

  &.hover {
    width: 95px;
  }
  &.active {
    opacity: 1;
  }
`
