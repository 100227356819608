import styled from 'styled-components'

export const ShipmentContainer = styled.div`
  width: 100%;
  font-weight: 600;
  margin: 35px 0 35px 0;
  font-family: Gilmer-Light;
`
export const ShipmentHeader = styled.ul`
 display: flex;
 justify-content: center;
 border-bottom: 2px solid #dadee8;
`
export const ShipmentList = styled.ul``

export const ShipmentListItem = styled.li`
 border-bottom: 1px solid #dadee8;
`
export const ShipmentItem = styled.ul`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  li {
    padding: 10px 0 10px 0;
  }
`

export const CompanyLogo = styled.img`
  max-width: 80px; 
  max-height: 40px; 
  display: block;
  margin: 0 auto 0 0;
`

export const TextInfo = styled.span`
  font-family: Gilmer-Light;
  text-transform: uppercase;
`
export const TextItem = styled.span`
  font-family: Gilmer-Light;
`