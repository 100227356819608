import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  width: ${({ width }) => width ? `${width}px` : 'auto'};
  max-height: 300px;
`
export const Button = styled.button`
  display: flex;
  border-radius: 6px;
  color: ${({ selected }) => selected ? '#eee' : '#858585'};
  border: ${({ selected, color }) => !selected ? `1px solid ${color}` : 'none'};
  border-bottom: ${({ open, selected, color }) => !selected && !open ? `1px solid ${color}` : 'none'};
  background-color: ${({ selected, color }) => selected ? color : 'transparent'};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  font-family: Gilmer-Light;
  font-weight: 500;
  font-size: 16px;
  padding: 8px;
  width: 100%;

  &:disabled {
    border: 1px solid #ccc;
    background-color: #ccc;
  }
`
export const Dropdown = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  width: calc(100% - 20px);
  top: 31px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0px 3px 4px rgba(0,0,0,0.1);
  border-radius: 0 0 15px 15px;
  border-right: 1px solid #858585;
  border-left: 1px solid #858585;
  border-bottom: 1px solid #858585;
  color: #A8A8A8;
  padding: 9px;
  z-index: 1000 !important;

  &.open {
    min-height: 100px;
    opacity: 1;
    transition: min-height .5s, opacity .2s
  }

  &.close {
    min-height: 0px;
    opacity: 0;
    transition: min-height .5s, opacity .5s;
  }
`
export const DropdownItem = styled.div`
  display: flex;
  width : '100%';
  flex-direction: row;
  cursor: pointer;
  align-items : center;
  user-select: none;
  margin-top: 8px;
  .icon-container{
    height:100%;
    width:100%;
    max-width:20px;
    margin-right:10px;
  }
`


