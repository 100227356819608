import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types } from '../actions'

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: false,
  deliveries: null,
  schedule: null,
  cities: null,
  neighborhoods: null,
  regions: null,
  shipping: null,
})

export const deliveryListRequest = (state = INITIAL_STATE, { neighborhood_id }) =>
  Object.assign({}, state, {
    fetching: true,
    error: false,
  })

export const deliveryListSuccess = (state = INITIAL_STATE, { deliveries }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    deliveries
  })

export const deliveryListFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    deliveries: null,
    error
  })


export const deliveryScheduleRequest = (state = INITIAL_STATE) =>
  Object.assign({}, state, {
    fetching: true,
    error: false,
    schedule: null
  })

export const deliveryScheduleSuccess = (state = INITIAL_STATE, { schedule }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    schedule
  })

export const deliveryScheduleFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    schedule: null,
    error
  })

export const cityListRequest = (state = INITIAL_STATE) =>
  Object.assign({}, state, {
    fetching: true,
    error: false,
    cities: null
  })

export const cityListSuccess = (state = INITIAL_STATE, { cities }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    cities
  })

export const cityListFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    cities: null,
    error
  })

export const neighborhoodListRequest = (state = INITIAL_STATE) =>
  Object.assign({}, state, {
    fetching: true,
    error: false,
    neighborhoods: null
  })

export const neighborhoodListSuccess = (state = INITIAL_STATE, { neighborhoods }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    neighborhoods
  })

export const neighborhoodListFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    neighborhoods: null,
    error
  })

export const regionsListRequest = (state = INITIAL_STATE, { neighborhood_id }) =>
  Object.assign({}, state, {
    fetching: true,
    error: false
  })

export const regionsListSuccess = (state = INITIAL_STATE, { regions }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    regions
  })

export const deliveryShippingRequest = (state = INITIAL_STATE, { zipcode, products, sender }) =>
  Object.assign({}, state, {
    fetching: true,
    error: false,
    shipping: null

  })

export const deliveryShippingSuccess = (state = INITIAL_STATE, { shipping }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    shipping
  })

export const deliveryShippingFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    error,
    shipping: null,
    shippingByCorreios: null,
    shippingAddress: null
  })

/**
 * Handlers
 */

export const HANDLERS = {
  [Types.DELIVERY_LIST_REQUEST]: deliveryListRequest,
  [Types.DELIVERY_LIST_SUCCESS]: deliveryListSuccess,
  [Types.DELIVERY_LIST_FAILURE]: deliveryListFailure,

  [Types.DELIVERY_SCHEDULE_REQUEST]: deliveryScheduleRequest,
  [Types.DELIVERY_SCHEDULE_SUCCESS]: deliveryScheduleSuccess,
  [Types.DELIVERY_SCHEDULE_FAILURE]: deliveryScheduleFailure,

  [Types.CITY_LIST_REQUEST]: cityListRequest,
  [Types.CITY_LIST_SUCCESS]: cityListSuccess,
  [Types.CITY_LIST_FAILURE]: cityListFailure,

  [Types.NEIGHBORHOOD_LIST_REQUEST]: neighborhoodListRequest,
  [Types.NEIGHBORHOOD_LIST_SUCCESS]: neighborhoodListSuccess,
  [Types.NEIGHBORHOOD_LIST_FAILURE]: neighborhoodListFailure,

  [Types.REGIONS_LIST_REQUEST]: regionsListRequest,
  [Types.REGIONS_LIST_SUCCESS]: regionsListSuccess,

  [Types.DELIVERY_SHIPPING_REQUEST]: deliveryShippingRequest,
  [Types.DELIVERY_SHIPPING_SUCCESS]: deliveryShippingSuccess,
  [Types.DELIVERY_SHIPPING_FAILURE]: deliveryShippingFailure,
}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)

export default reducer
