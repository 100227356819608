import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types } from '../actions'

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: false,
  categories: null
})

export const categoryListRequest = (state = INITIAL_STATE) =>
  Object.assign({}, state, {
    fetching: true,
    error: false,
    categories: null
  })

export const categoryListSuccess = (state = INITIAL_STATE, { categories }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    categories
  })

export const categoryListFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    categories: null,
    error
  })

/**
 * Handlers
 */

export const HANDLERS = {
  [Types.CATEGORY_LIST_REQUEST]: categoryListRequest,
  [Types.CATEGORY_LIST_SUCCESS]: categoryListSuccess,
  [Types.CATEGORY_LIST_FAILURE]: categoryListFailure
}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)

export default reducer
