import styled from 'styled-components'

export const LogoContainer = styled.div`
  border-radius: 50%;
  background-color: #F8F8F8;
  position: absolute;
  z-index: 1;
  box-shadow: 2px 4px 10px rgba(0,0,0, 0.2);
  border: 3px solid #CCCCCC;

  img {
    border-radius: 50%;
  }

  ${({ mobile }) => mobile ? `
    position: sticky;
    align-self: flex-start;
    margin-top: -20%;
    margin-left: 5%;
    height: 100px;
    width: 100px;

    img {
      width: 100px;
      height: 100px;
    }
  ` : `
    @media(max-width: 768px) {
      left: 26px;
      top: 100px;
      height: 120px;
      width: 120px;

      img {
        width: 120px;
        height: 120px;
      }
  }
    @media(min-width: 768px) {
      left: 26px;
      top: 50px;
      height: 215px;
      width: 215px;

      img {
        width: 215px;
        height: 215px;
      }
    }
    @media(min-width: 1000px) {
      height: 234px;
      width: 234px;
      top: 30px;
      left: 19%;

      img {
        width: 234px;
        height: 234px;
      }
    }
  `}
`
