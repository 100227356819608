import ReactGA from 'react-ga'
import { store } from '../store/configureStore'
import { storeConfig } from '../redux/StartupRedux'

const getConfig = ({ startup }) => storeConfig(startup)

/**
 * Dispatch Analytics events
 * 
 * @param {Object} options
 * @param {String} options.category 
 * @param {String} options.action 
 * @param {String} options.label 
 */
export default ({ category, action,label }) => {
  if (!store) return null

  const config = getConfig(store.getState())

  if (config) {
    const { config_analytics_id } = config
    
    if (config_analytics_id && config_analytics_id.length > 0) {
      if(!label){
        ReactGA.event({ category, action })
      }else{
        ReactGA.event({ category, action,label })
      }
    }
  }
}
