import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types } from '../actions'

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: false,
  addresses: null,
  address: null,
  currentAddress: null,
  deleted_id: null,
  currentOrderShipping: null,
  currentShippingAddress: null
})

export const addressListRequest = (state = INITIAL_STATE) =>
  Object.assign({}, state, {
    fetching: true,
    error: false,
    addresses: null,
    address: null,
  })

export const addressListSuccess = (state = INITIAL_STATE, { addresses }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    addresses
  })

export const addressListFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    addresses: null,
    error
  })

export const setCurrentAddressRequest = (state = INITIAL_STATE, { address }) =>
  Object.assign({}, state, {
    currentAddress: address
  })

export const addressRegisterRequest = (state = INITIAL_STATE, { address_id, firstname, lastname, street, neighborhood_id, complement, postcode, not_deliverable = false, addressType, neighborhood, city, state_address, number }) =>
  Object.assign({}, state, {
    fetching: true,
    error: false,
    address: null,
  })

export const addressRegisterSuccess = (state = INITIAL_STATE, { address }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    address,
  })

export const addressRegisterFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    address: null,
    error
  })

export const addressUpdateRequest = (state = INITIAL_STATE, { address_id, firstname, lastname, street, neighborhood_id, complement, postcode }) =>
  Object.assign({}, state, {
    fetching: true,
    error: false,
    address: null
  })

export const addressUpdateSuccess = (state = INITIAL_STATE, { address }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    address
  })

export const addressUpdateFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    address: null,
    error
  })

export const addressDeleteRequest = (state = INITIAL_STATE, { address_id }) =>
  Object.assign({}, state, {
    fetching: true,
    error: false,
    deleted_id: null
  })

export const addressDeleteSuccess = (state = INITIAL_STATE, { deleted_id }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    deleted_id
  })

export const addressDeleteFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    deleted_id: null,
    error
  })

export const setCurrentOrderShippingRequest = (state = INITIAL_STATE, { shipping }) => 
  Object.assign({}, state, {
    currentOrderShipping: shipping
  })

export const setCurrentShippingAddressRequest = (state = INITIAL_STATE, { address }) => 
  Object.assign({}, state, {
    currentShippingAddress: address
  })

/**
 * Handlers
 */

export const HANDLERS = {
  [Types.ADDRESS_LIST_REQUEST]: addressListRequest,
  [Types.ADDRESS_LIST_SUCCESS]: addressListSuccess,
  [Types.ADDRESS_LIST_FAILURE]: addressListFailure,

  [Types.SET_CURRENT_ADDRESS_REQUEST]: setCurrentAddressRequest,

  [Types.ADDRESS_REGISTER_REQUEST]: addressRegisterRequest,
  [Types.ADDRESS_REGISTER_SUCCESS]: addressRegisterSuccess,
  [Types.ADDRESS_REGISTER_FAILURE]: addressRegisterFailure,

  [Types.ADDRESS_UPDATE_REQUEST]: addressUpdateRequest,
  [Types.ADDRESS_UPDATE_SUCCESS]: addressUpdateSuccess,
  [Types.ADDRESS_UPDATE_FAILURE]: addressUpdateFailure,

  [Types.ADDRESS_DELETE_REQUEST]: addressDeleteRequest,
  [Types.ADDRESS_DELETE_SUCCESS]: addressDeleteSuccess,
  [Types.ADDRESS_DELETE_FAILURE]: addressDeleteFailure,
  [Types.SET_CURRENT_ORDER_SHIPPING_REQUEST]: setCurrentOrderShippingRequest,
  [Types.SET_CURRENT_SHIPPING_ADDRESS_REQUEST]: setCurrentShippingAddressRequest,
}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)

export default reducer
