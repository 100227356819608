import styled from 'styled-components'

export const SVG = styled.svg`
  margin-bottom: 8px;
  display: inline-block;
  vertical-align: text-top;
  @media(min-width: 768px) {
    margin-right: 8px;
    margin-bottom: 0;
  }
`