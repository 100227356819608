import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Body from '@components/Body'
import PillsIcon from '@components/Icons/Pills'
import EmptyOrderHistory from '@components/EmptyOrderHistory'
import { Col, Row } from 'react-flexbox-grid'
import Spinner from 'react-spinkit'
import { Title, Divider, DividerContent, Label, Value } from './styles'
import { Creators } from '../../actions'
import { floatToBRL } from '../../helpers/MoneyFormat'
import moment from 'moment'

class OrderHistory extends Component {

  componentDidMount() {
    const { attemptGetOrders } = this.props

    attemptGetOrders()
  }

  _renderItems = () => {
    const { orders } = this.props

    if (orders && orders.length > 0) {
      return orders.map((order, index) => {
        const { order_id, orderProducts, orderStatus, orderTotals, date_added } = order
        const total = orderTotals.find(p => p.code === 'total').value

        return (
          <React.Fragment key={index}>
            <Divider>
              <DividerContent>
                <PillsIcon width={23} height={20} />
              </DividerContent>
            </Divider>

            <Row style={{marginTop: 20}}>
              <Col xs={3}>
                <Label>Data</Label>
                <Value>
                  {moment(date_added).format('DD/MM/YY')}
                </Value>
              </Col>

              <Col xs={3}>
                <Label>Produtos</Label>
                {
                  orderProducts.map((p, index) => {
                    const { product: { name } } = p

                    return <Value key={index}>{`- ${name}`}</Value>
                  })
                }
              </Col>

              <Col xs={3}>
                <Label>Valor total</Label>
                <Value>{floatToBRL(Number(total))}</Value>
              </Col>

              <Col xs={3}>
                <Label>Status</Label>
                <Value>{orderStatus.name}</Value>
              </Col>
            </Row>

            <Row style={{marginTop: 25}}>
              <Col xs={12} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Link className="pretty-link" to={`/pedido/${order_id}`}>Ver detalhes do pedido</Link>
              </Col>
            </Row>
          </React.Fragment>
        )
      })
    } else {
      return <EmptyOrderHistory />
    }
  }

  render() {
    const { fetching } = this.props

    return (
      <Body>
        <Row>
          <Col xs={12} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Title>Histórico de Pedidos</Title>
          </Col>
        </Row>
        {
          fetching ? <Spinner name="pacman" color="#1999f9" style={{marginTop: 20, marginLeft: '50%'}}/> : this._renderItems()
        }
      </Body>
    )
  }
}

const mapStateToProps = ({ order }) => ({
  fetching: order.fetching,
  orders: order.orders
})

const mapDispatchToProps = dispatch => ({
  attemptGetOrders: () => dispatch(Creators.orderListRequest())
})

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory)
