import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Icons from '@fortawesome/free-solid-svg-icons'
import { EmptyOrderContainer, Text } from './styles'

export default class EmptyAddress extends Component {
  render() {
    return (
      <EmptyOrderContainer>
        <FontAwesomeIcon icon={Icons.faFrownOpen} size="10x" color="#a8a8a8" style={{marginBottom: 15}} />
        <Text>Ops, você ainda não realizou nenhum pedido!</Text>
      </EmptyOrderContainer>
    )
  }
}
