import styled from 'styled-components'

export const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  user-select: none;
  z-index: 990 !important;
`
export const DropdownItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  outline: none;
`
export const Label = styled.span`
  font-family: Gilmer;
  font-size: 16px;
  color: #A8A8A8;
  margin-right: 10px;
`
export const Dropdown = styled.div`
  display: ${({ open }) => open ? 'flex' : 'none' };
  flex-direction: column;
  min-width: ${({ width }) => `${width}px`}
  max-height: 300px;
  top: 90%;
  border-radius: 0 0 15px 15px;
  height: auto;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 4px rgba(0,0,0,0.1);
  color: #A8A8A8;
  padding: 8px;
  position: absolute;
  z-index: 995 !important;
  align-items: center;
  overflow-y: auto;
`
export const DropdownItem = styled.p`
  font-family: Gilmer;
  font-size: 16px;
  color: #A8A8A8;
  line-height: 5px;
  cursor: pointer;

  &:hover {
    color: #7f7f7f;
  }
`
