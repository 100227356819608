import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types } from '../actions'

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: false,
  banners: null
})

export const bannerListRequest = (state = INITIAL_STATE) =>
  Object.assign({}, state, {
    fetching: true,
    error: false,
    banners: null,
  })

export const bannerListSuccess = (state = INITIAL_STATE, { banners }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    banners
  })

export const bannerListFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    banners: null,
    error
  })


/**
 * Handlers
 */

export const HANDLERS = {
  [Types.BANNER_LIST_REQUEST]: bannerListRequest,
  [Types.BANNER_LIST_SUCCESS]: bannerListSuccess,
  [Types.BANNER_LIST_FAILURE]: bannerListFailure
}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)

export default reducer
