import React from 'react'
import { SVG } from './styles.js'

const Search = ({ height = 20, width = 20}) => (
  <SVG 
    xmlns="http://www.w3.org/2000/svg" 
    width={width}
    height={height}
    viewBox="0 0 30 30">
     <path 
      d="M29.45,26.946l-8.214-8.217a11.627,11.627,0,0,0,2.25-6.926,12.112,12.112,0,0,0-1.351-5.449A11.707,11.707,0,0,0,0,11.718a11.855,11.855,0,0,0,11.786,11.8,11.589,11.589,0,0,0,6.946-2.275l.009-.006,8.206,8.211a1.771,1.771,0,1,0,2.5-2.505ZM11.777,21.168a9.483,9.483,0,0,1-9.429-9.442,9.322,9.322,0,0,1,9.36-9.374,9.483,9.483,0,0,1,9.429,9.442,9.322,9.322,0,0,1-9.36,9.374Z"
      fill="#ffffff"
      fillRule="evenodd"/>
  </SVG>
)

export default Search
