import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Body from '@components/Body'
import Button from '@components/Button'
import SearchIcon from '@components/Icons/Search'
import Icon404 from '../../assets/images/404.svg'
import { Container, FormContainer, Title, Input, InputButton, InputContainer, ButtonContainer } from './styles'

class Page404 extends Component {
  constructor(props) {
    super(props)

    this.state = {
      doSearch: false,
      searchText: ''
    }
  }

  onSearchChange = ({target: { value }}) => {
    this.setState({
      searchText: value
    })
  }

  doSearch = () => {
    const { searchText } = this.state
  
    if (searchText.length > 0) {
      this.setState({
        doSearch: true
      })
    }
  }

  render() {
    const { doSearch, searchText } = this.state

    let redirectUrl = '/produtos'

    if (searchText.length > 0) {
      redirectUrl += `?q=${searchText}`
    }

    return (
      <Body>
        <Container>
          <Title>404: Página não encontrada.</Title>

          <img src={Icon404} alt="404" width={500} />
          
          <FormContainer>
            <InputContainer>
              <Input placeholder="O que você está procurando?" value={searchText} onChange={this.onSearchChange} />
            </InputContainer>
            <ButtonContainer>
              <InputButton type="submit" tabIndex={-1} onClick={this.doSearch}>
                <SearchIcon />
              </InputButton>
            </ButtonContainer>

            <span style={{marginLeft: 25, marginRight: 15}}>Ou</span>
            <Button style={{backgroundColor: '#707070'}} onClick={() => this.setState({doSearch: true})}>
              Ir à página inicial
            </Button>
          </FormContainer>
        </Container>

        {
          doSearch ? <Redirect to={redirectUrl} /> : null
        }
      </Body>
    )
  }
}

export default Page404
