import styled from 'styled-components'

export const AddressCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  height: 130px;
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 1px 3px 10px rgba(0,0,0, 0.2);
  padding: 0px 40px 0px 40px;
  margin-bottom: 20px;

  &:hover {
    box-shadow: 1px 3px 20px rgba(0,0,0, 0.2);
    transition: box-shadow .5s;
  }
`
export const FakeAddressCardContainer = styled.div`
  width: 100%;
  height: 130px;
  content: ' ';
  margin-bottom: 20px;
`
export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`
export const AddressPrimary = styled.div`
  font-family: Gilmer-Light;
  font-size: 15px;
  color: #6d6d6d;
`
export const AddressSecondary = styled.div`
  font-family: Gilmer-Light;
  font-size: 15px;
  color: #A8A8A8;
`
export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
  margin-left: auto;
`
export const ActionDivider = styled.div`
  height: 60px;
  width: 1px;
  content: ' ';
  margin: 0px 40px 0px 40px;
  background-color: #A8A8A8
`
export const Title = styled.h2`
  font-size: 18px;
  align-self: center;
`

export const DeliveryContainer = styled.div`
  display: flex; 
  flex-direction: column;
  font-family: Gilmer-Light;
`
export const ButtonLink = styled.a`
  font-size: 16px;
  font-weight: bold;
  color: blue;
  width: 120px;
  margin-top: 20px;
  cursor: pointer;
  text-decoration: underline;
`;