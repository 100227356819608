import { call, put, select } from 'redux-saga/effects'
import { Creators } from '../actions'
import { loadedStore, loggedStore } from '../redux/StartupRedux'
import { loggedToken, loggedUser } from '../redux/LoginRedux'
import { getFingerPrint } from '../redux/SearchRedux'
import { getCoupom, getFingerPrintCart } from '../redux/CartRedux'

const storeId = ({ startup }) => loadedStore(startup)
const userToken = ({ login }) => loggedToken(login)
const userLogged = ({ login }) => loggedUser(login)
const fingerprintData = ({ search }) => getFingerPrint(search)
const accessTokenStore = ({ startup }) => loggedStore(startup)
const coupom = ({ cart }) => getCoupom(cart)
const fingerPrintCart = ({ cart }) => getFingerPrintCart(cart)


export function * getCart(api) {
  const fingerprint = yield select(fingerPrintCart)
  const token = yield select(userToken)

  api.paymentSetHeaders({
    Authorization: `Bearer ${token}`
  })

  const response = yield call(api.getCart, { fingerprint: fingerprint })
  const { ok, data, problem } = response

  if (ok) {
    const { cart } = data
    const { products, productsCupom } = cart

    yield put(Creators.cartGetRemoteSuccess(products, productsCupom))
  } else {
    let error = problem
    if (data) {
      error = data.error
    }

    yield put(Creators.cartGetRemoteFailure(error))
  }
}

export function * updateCart(api, { products }) {
  const store_id = yield select(storeId)
  const token = yield select(userToken)
  const code = yield select(coupom)
  const fingerprint = yield select(fingerPrintCart)

  api.paymentSetHeaders({
    Authorization: `Bearer ${token}`,
    'store-id': store_id
  })

  const response = yield call(api.updateCart, { products, code, fingerprint: fingerprint })
  const { ok, data, problem } = response
  const { cart } = data

  if (ok) {
    
    yield put(Creators.cartUpdateRemoteSuccess(cart.products, cart.productsCupom, cart.fingerprint))
    
  } else {
    let error = problem
    if (data) {
      error = data.error
    }

    yield put(Creators.cartUpdateRemoteFailure(error))
  }
}

export function * addSingle(api, { product_ean, origin }) {
  const store_id = yield select(storeId)
  const user = yield select(userLogged)
  const fingerprint = yield select(fingerprintData)
  
  api.setHeaders({
    'store-id': store_id
  })

  const response = yield call(api.addSingleToCart, { product_ean, origin, fingerprint, customer_id: user ? user.customer_id : null })
  const { ok, data, problem } = response

  if (ok) {
    yield put(Creators.cartAddSingleSuccess())
  } else {
    let error = problem
    if (data) {
      error = data.error
    }

    yield put(Creators.cartAddSingleFailure(error))
  }
}

export function * updateCartUnknow(api, { products, code }) {
  const token = yield select(accessTokenStore)
  const fingerprint = yield select(fingerPrintCart)
  const user = yield select(userLogged)

  api.paymentSetHeaders({
    Authorization: `Bearer ${token}`,
  })

  const response = yield call(api.updateCartUnknow, { products, code, fingerprint: fingerprint, user: user })
  const { ok, data, problem } = response
  const { cart } = data

  if (ok) {
    yield put(Creators.cartUpdateRemoteUnknowSuccess(cart.productsCupom, cart.fingerprint, cart.code))
  } else {
    let error = problem
    if (data) {
      error = data.error
    }

    yield put(Creators.cartUpdateRemoteFailure(error))
  }

}

export function * updateGlobalCart(api, { products, purchased }) {
  const token = yield select(userToken)

  api.paymentSetHeaders({
    Authorization: `Bearer ${token}`
  })

  const response = yield call(api.updateGlobalCart, { products, purchased })

  const { ok, data, problem } = response
  const { send } = data

  if (ok) {
    yield put(Creators.cartGlobalUpdateSuccess(send))

  } else {
    let error = problem
    if (data) {
      error = data.error
    }

    yield put(Creators.cartUpdateRemoteFailure(error))
  }
}