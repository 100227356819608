import { call, put, select } from 'redux-saga/effects'
import { Creators } from '../actions'
import { loadedStore } from '../redux/StartupRedux'

const storeId = ({ startup }) => loadedStore(startup)

export function * getCategories(api) {
  const store_id = yield select(storeId)
  api.setHeaders({
    'store-id': store_id
  })
  
  const response = yield call(api.getCategories)
  const { ok, data } = response

  if (ok) {
    const { categories } = data

    yield put(Creators.categoryListSuccess(categories))
  } else {
    const { error } = data

    yield put(Creators.categoryListFailure(error))
  }
}
