const ERROR_CODES = {
  'email_password_invalid': 'E-mail/senha incorreto',
  'email_already_exist': 'E-mail já em uso',
  'email_not_found': 'E-mail não encontrado!',
  'network_error': 'Não foi possível contactar o servidor',
  'connection_error': 'Sem conexão à internet',
  'invalid_creditcard': 'Cartão de crédito inválido!',
  'payment_option_not_found': 'Método de pagamento não disponível, por favor entre em contato conosco.',
  'store_not_informed': 'Identificador da loja não autorizado.',
  'not_found_credentials_in_store': 'Credenciais para login social não cadastrados.',
  'authentication_failure': 'Falha login verfique suas credenciais ou redes sociais.',
  'missing_email': 'Campo email é obrigatório.', 
  'invalid_session': 'Este login é inválido.',
  'social_login_error': 'Erro ao logar com redes sociais.',
  'Invalid verification code format.': 'O Código fornecido é inválido',
  'Error validating client secret.': 'App id ou app secret inválidos',
  'invalid_client': 'App id ou app secret inválidos',
  'ceppromiseerror': 'CEP inválido.',
  'zipcode_store_not_found': 'CEP da loja não configurado ou inválido',
  'cep_promise': 'CEP inválido ou não encontrado na base',
  'courier_shipping_promise': 'Erro no gateway do correios',
  'best_shipping_promise': 'Erro no gateway do melhor envio',
  'add_cart_promise': 'Erro ao adicionar ao carrinho do melhor envio',
}

export default (code = '') => {
  return ERROR_CODES[code.toLowerCase()] || code.toLowerCase()
}
