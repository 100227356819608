import styled from 'styled-components'

export const ShipmentContainer = styled.div`
  margin: 35px 0 35px 0;
  font-family: Gilmer-Light;
  font-weight: 600;
`

export const ShipmentHeader = styled.ul`
 border-bottom: 2px solid #dadee8;
 width: 100%;
 display: flex;

 li {
   width: 20%;
 }
`
export const ShipmentList = styled.ul``

export const ShipmentListItem = styled.li`
 border-bottom: 1px solid #dadee8;
 width: 100%;
`
export const ShipmentItem = styled.ul`
   width: 100%;
   display: flex;

   li {
     width: 20%;
     padding: 10px 0 10px 0;
    }
`

export const CompanyLogo = styled.img`
  max-width: 100px; 
  max-height: 40px; 
  display: block;
  margin: 0 auto 0 0;
`

export const Button = styled.button`
background: #00bf91;
border: none; 
font-family: 'Gilmer-Light';
color: #FFF;
padding: 10;
border-radius: 8; 
cursor: pointer 
`