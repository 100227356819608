import React from 'react'
import { Field } from 'formik'
import { Select } from './styles'

const SelectField = ({ options, ...props }) => (
  <Field component={Select} {...props}>
    <option value="">Selecione uma opção</option>
    {options.map(({ value, label }) => (
      <option key={`${value}${label}`} value={value}>{label}</option>
    ))}
  </Field>
)

export default SelectField
