import React, { Component } from 'react'
import Body from '@components/Body'
import Input from '@components/Input'
import Button from '@components/Button'
import { Col, Row } from 'react-flexbox-grid'
import { Title } from './styles'

class Profile extends Component {
  render() {
    return (
      <Body>
        <Title>Dados pessoais</Title>
        <Row>
          <Col xs={12} md={6}>
           <Input placeholder="Nome" fontSize={16} height={10} />
          </Col>
          <Col xs={12} md={6}>
            <Input placeholder="E-mail" fontSize={16} height={10} />
          </Col>
        </Row>
        <Row style={{marginTop: 15}}>
          <Col xs={12} md={6} style={{display: 'flex', flexDirection: 'row'}}>
            <Input type="number" placeholder="(DDD)" fontSize={16} height={10} width={60} style={{marginRight: 10}} />
            <Input placeholder="99900 - 0202" fontSize={16} height={10} />
          </Col>
        </Row>

        <Title style={{marginTop: 80}}>Senha</Title>

        <Row>
          <Col xs={12} md={6}>
           <Input type="password" placeholder="Nova senha" fontSize={16} height={10} />
          </Col>
          <Col xs={12} md={6}>
            <Input type="password" placeholder="Confirmar senha" fontSize={16} height={10} />
          </Col>
        </Row>

        <Row style={{marginTop: 20}}>
          <Col xs={12} style={{textAlign: 'center'}}>
            <Button style={{width: '25%'}}>Atualizar</Button>
          </Col>
        </Row>
      </Body>
    )
  }
}

export default Profile
