import styled from 'styled-components'

export const Select = styled.select`
  border-radius: 5px;
  border: 1px solid #A8A8A8;
  width: 100%;
  height: 40px;
  outline: none;
  @media(min-width: 768px) {
    height: 55px;
  }
`