import styled from 'styled-components'

export const HeaderContainer = styled.div`
  position: relative;
  width: 100vw;
  top: 0;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media(min-width: 768px) {
    display: block;
  }
`

export const InfoContainer = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, .5);
  border-radius: 10px;
  width: 40%;
  color: #ffffff;
  top: 315px;
  text-align: left;
  padding: 15px;
  @media(min-width: 768px) {
    top: 106px;
    left: calc(2% + 270px);
    h1, h4 {
      margin: 0;
    }
  }
  @media(min-width: 1000px) {
    top: 105px;
    left: calc(19% + 270px);
    h1, h4 {
      margin: 0;
    }
  }
`
export const UsernameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`
export const Dropdown = styled.div`
  display: ${({ open }) => open ? 'flex' : 'none' };
  flex-direction: column;
  min-width: ${({ width }) => `${width}px`};
  top: 90%;
  right: 0;
  border-radius: 0 0 15px 15px;
  height: auto;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 4px rgba(0,0,0,0.1);
  color: #A8A8A8;
  padding: 8px;
  position: absolute;
  z-index: 1;
  align-items: center;
`
export const DropdownItem = styled.p`
  font-family: Gilmer;
  font-size: 16px;
  color: #A8A8A8;
  line-height: 5px;
  cursor: pointer;

  &:hover {
    color: #7f7f7f;
  }
`
export const WhatsAppButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: #25D366;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  right: 20px;
  bottom: 90px;
  color: #FFFFFF;
  z-index: 990;
  box-shadow: 0 4px 4px rgba(0,0,0,0.05), 0 4px 4px rgba(0,0,0,0.13);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);

  &:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
`