import styled from 'styled-components'

export const Title = styled.h1`
  font-family: Gilmer-Bold;
  font-size: 18px;
  text-align: center;
  color: #707070;
  line-height: 20px;
  margin: 20px 0 5px 0;
`
export const Caption = styled.p`
  font-size:16px;
  color: #ccc;
  text-align: center;
`
export const Container = styled.div`
  width: 100%;
  height: 45vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
