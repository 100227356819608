import styled from 'styled-components'
import InputMasked from "react-input-mask"

export const RegisterAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const RegisterAddressContent = styled.div`
  margin-top: 20px;
  align-self: flex-start;
  width: 100%;
`
export const Title = styled.h2`
  line-height: 0;
  font-family: Gilmer-Bold;
  align-self: center;
`
export const SubTitle = styled.h4`
  line-height: 0;
  font-family: Gilmer-Bold;
  align-self: center;
  color: #707070;
  margin-left: 0;
`
export const InputMask = styled(InputMasked)`
  height: ${({ height }) => height ? `${height}px` : '26px'};
  width: ${({ width, textAlign }) => width ? `${width}px` : textAlign === 'center' ? '100%' : 'calc(100% - 10px)'};
  border-radius: 10px;
  border: 1px solid #707070; 
  color: #707070;
  font-family: Gilmer;
  font-size: ${({ fontSize }) => fontSize ? `${fontSize}px` : '22px'};
  text-align: ${({ textAlign }) => textAlign || 'left'};
  outline: none;
  background-color: #FFFFFF;
  padding: ${({ textAlign }) => textAlign === 'center' ? '15px 0 15px 0' : '15px 0 15px 10px'};
  margin-bottom: 10px;

  &:focus {
    border: 1px solid ${({ validfield }) => validfield ? '#00BF91' : '#c0392b'};
  }

  &::placeholder {
    font-family: Gilmer-Light;
  }
`
export const Link = styled.a`
  font-size: 14px;
  font-weight: bold;
  color: #707070;
  width: 120px;
  margin-top: 20px;
  color: blue;
  text-decoration: underline;
  text-align: center;
`;

export const ErrorContainer = styled.p`
  color: #ff6347;
  font-size: 14px;
  font-weight: bold;
  margin: 5px
`