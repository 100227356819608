import styled from 'styled-components'
import InputMasked from 'react-input-mask'

export const Title = styled.h2`
  font-family: ${({ bold }) => `Gilmer-${bold ? 'Bold' : 'Light'}`};
  font-size: ${({ fontSize }) => fontSize ? `${fontSize}px` : '15px'};
  text-align: center;
  color: #707070;
  line-height: 20px;
  margin: 20px 0 5px 0;
`
export const Error = styled.p`
  color: #b73535;
  font-size: 14px;
  margin-top: 2px;
  margin-left: 5px;
`
export const TextLink = styled.p`
  font-size: 14px;
  text-decoration: underline;
  color: #3498db;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`
export const InputMask = styled(InputMasked)`
  height: ${({height}) => height ? `${height}px` : '26px'};
  width: ${({width, textAlign}) => width ? `${width}px` : textAlign === 'center' ? '100%' : 'calc(100% - 10px)'};
  border-radius: 10px;
  border: 1px solid #707070; 
  color: #707070;
  font-family: Gilmer;
  font-size: ${({fontSize}) => fontSize ? `${fontSize}px` : '22px'};
  text-align: ${({textAlign}) => textAlign || 'left'};
  outline: none;
  background-color: #FFFFFF;
  background: ${({ image }) => image ? `url(${image}) no-repeat scroll 95% 50%` : 'unset'};
  padding: ${({textAlign}) => textAlign === 'center' ? '15px 0 15px 0' : '15px 0 15px 10px'};

  &:focus {
    border: 1px solid ${({ validfield }) => validfield && validfield === 'true' ? '#00BF91' : '#c0392b'};
  }

  &::placeholder {
    font-family: Gilmer-Light;
  }
`
export const InputGroup = styled.div`
  margin-bottom: 5px;
`
export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid #a8a8a8;
  margin: 0px 0 15px 0;
`
export const Textinfo = styled.p`
  font-family: Gilmer-Light;
  color: #707070;
  font-size: 15px;
  align-self: center;
  margin: 0;

`