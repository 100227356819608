/* eslint-disable no-useless-escape */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Row, Col } from 'react-flexbox-grid'
import LaddaButton, { XL } from '@zumper/react-ladda'
import Input from '@components/Input'
import { Creators } from '../../actions'
import ErrorCode from '../../helpers/ErrorCodes'
import { Title, Error, TextLink, InputMask, InputGroup, Separator, DivSpacer, SocialLoginContainer, ActionButton, ButtonFB, ButtonGO } from './styles'
import IconFB from '../../components/Icons/Facebook';
import IconGmail from '../../assets/images/google.svg';
import * as Icons from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GAEvents from '../../helpers/GAEvents'

// Google Analytics
import ReactGA from 'react-ga'
import './index.css'

class RegisterOrLogin extends Component {
  // Hacking trick, here we should know if the component is mounted or not
  _isMounted = false

  static propTypes = {
    mobile: PropTypes.bool,
    appId: PropTypes.string,
    clientId: PropTypes.string
  }

  static defaultProps = {
    mobile: false
  }

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      canContinue: false,
      action: 'findSocialAccount',
      accountName: '',
      loginError: null,
      registerError: null,
      recoverError: null,
      fullNameValid: false,
      telephoneValid: false,
      passwordValid: false,
      repeatPasswordValid: false,
      inputs: {
        email: '',
        telephone: '',
        password: '',
        repeatPassword: '',
        name: '',
      },
      loaginFacebook: false,
      loadingGoogle: false,
    }
  }
  componentWillMount() {
    const {appId, clientId } = this.props;

    if (appId || clientId) {
      this.setState({
        action: 'findSocialAccount'
      })
    }else {
      this.setState({
        action: 'findAccount'
      })
    }

  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentWillReceiveProps(newProps) {
    const { fetchingAccount, fetchingLogin, errorLogin, accountExist, accountName } = newProps
    const { loading} = this.state
    let newState = Object.assign({}, this.state)

    // Login user
    if (loading && !fetchingLogin && (newState.action === 'login' || newState.action === 'register' || newState.action === 'recover')) {
      newState.loading = false

      if (errorLogin) {
        if (newState.action === 'login') {
          newState.inputs.password = ''
          newState.loginError = ErrorCode(errorLogin)
        }
        else if (newState.action === 'recover') {
          newState.recoverError = ErrorCode(errorLogin)
        }
         else {
          newState.registerError = ErrorCode(errorLogin)
        }

        newState.canContinue = false
      }

      if (!errorLogin && newState.action === 'recover') {
        newState.action = 'recover-success'
      }

      if (!errorLogin && (newState.action === 'login' || newState.action === 'register')) {
        this.props.onFinish()
      }
    }

    // Find account
    if (loading && !fetchingAccount && newState.action === 'findAccount') {
      newState.loading = false
    
      if (accountExist) {
        newState.accountName = accountName
        newState.action = 'login'
        

      } else {
        newState.action = 'register'
        
      }
    }

    // Update state if needed
    if (newState !== this.state && this._isMounted) {
      this.setState(newState)
    }
  }

  onChangeField = ({ target: { name, value } }) => {
    this.setState(state => ({
      ...state,
      inputs: {
        ...state.inputs,
        [name]: value
      }
    }), () => {
      // Validate register fields
      this.validateRegisterFields()
    })
  }

  validateRegisterFields = () => {
    const { canContinue, action, inputs, fullNameValid, telephoneValid, passwordValid, repeatPasswordValid, registerError, emailValid } = this.state
    const { email, name, telephone, password, repeatPassword } = inputs
    
    let bValid = canContinue
    let bFullNameValid = fullNameValid
    let bTelephoneValid = telephoneValid
    let bPasswordValid = passwordValid
    let bRepeatPasswordValid = repeatPasswordValid
    let registerValidationError = registerError
    let bEmailValid = emailValid

    if (action === 'findAccount' || action === 'recover') {
      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      bValid = emailRegex.test(email.toLowerCase())
    }

    if (action === 'register') {
      const nameRegex = /^[a-záàâãéèêíïóôõöúçñ]([-']?[a-záàâãéèêíïóôõöúçñ]+)*( [a-záàâãéèêíïóôõöúçñ]([-']?[a-záàâãéèêíïóôõöúçñ]+)*)+\s?$/i
      const telephoneOnlyDigits = /^\d+$/.test(telephone.replace(/\s/g,'').replace(/\(/g, '').replace(/\)/g, '').replace(/\-/g, ''))

      // Enable continue button?
      bValid = nameRegex.test(name) && telephoneOnlyDigits && password.length > 3 && password === repeatPassword
      
      // Feedback for full name completion
      bFullNameValid = nameRegex.test(name)

      // Feedback for correct telephone number
      bTelephoneValid = telephoneOnlyDigits

      // Password validation error
      if (password.length > 0) {
        if (password.length <= 3) {
          registerValidationError = 'Senha devem ser no mínimo 4 caracteres.'
          bPasswordValid = false
        } else {
          registerValidationError = null
          bPasswordValid = true
        }

        if (repeatPassword.length <= 3) {
          bRepeatPasswordValid = false
        } else {
          if (password !== repeatPassword) {
            registerValidationError = 'Confirmação de senha não é igual.'
            bRepeatPasswordValid = false
          } else {
            registerValidationError = null
            bRepeatPasswordValid = true
          }
        }
      }
    }

    if (action === 'login') {
      bValid = password.length > 3
    }

    this.setState({
      canContinue: bValid,
      fullNameValid: bFullNameValid,
      telephoneValid: bTelephoneValid,
      registerError: registerValidationError,
      passwordValid: bPasswordValid,
      repeatPasswordValid: bRepeatPasswordValid,
      emailValid : bEmailValid
    })
  }

  onSubmit = (ev) => {
    const { canContinue, loading } = this.state
    ev.preventDefault()

    if (canContinue && !loading) {
      this._next()
    }
  }

  _next = () => {
    const { attemptFindAccount, attemptLogin, attemptRegister, attemptRecoverPassword } = this.props
    const { action, inputs } = this.state
    const { email, name, telephone, password } = inputs

    // Dispatch to account finder
    if (action === 'findAccount') {
      GAEvents({category : 'Login',action : 'Click no botão Continuar',label : 'Check if email exists'})
      this.setState({
        loading: true,
        canContinue: false
      }, () => {
        setTimeout(() => {
          attemptFindAccount(email)
        }, 500)
      })
    }

    // Dispatch to account login
    if (action === 'login') {
      GAEvents({category : 'Login',action : 'Click no botão Continuar',label : 'Login'})
      this.setState({
        loading: true,
        canContinue: false,
        loginError: null
      }, () => {
        setTimeout(() => {
          attemptLogin(email, password)
        }, 500)
      })
    }

    // Dispatch to create new account
    if (action === 'register') {
      GAEvents({category : 'Login',action : 'Click no botão Continuar',label : 'Register'})
      this.setState({
        loading: true,
        canContinue: false,
        registerError: null
      }, () => {
        const fullName = name.split(' ')
        const firstname = fullName[0]
        const lastname = fullName.slice(1).toString().replace(/\,/g, ' ')

        setTimeout(() => {
          attemptRegister({
            firstname,
            lastname,
            telephone: telephone,
            email: email.toLowerCase(),
            password: password
          })
        }, 500)
      })
    }

    // Dispatch to recover account password
    if (action === 'recover') {
      GAEvents({category : 'Login',action : 'Click no botão Continuar',label : 'Recover Password'})
      this.setState({
        loading: true,
        canContinue: false,
        recoverError: null
      }, () => {
        setTimeout(() => {
          attemptRecoverPassword(email)
        }, 500)
      })
    }
  }
  

  _renderRecoverPassword = () => {
    const { mobile } = this.props
    const { inputs, recoverError, action } = this.state
    const { email } = inputs
    ReactGA.modalview('/recover/password')

    return (
      <form onSubmit={this.onSubmit}>
        { 
          action === 'recover-success' ? <Title fontSize={mobile ? 16 : 20} bold>E-mail com mais informações foi enviado!</Title> : (
            <React.Fragment>
              <Title fontSize={24} bold>Recuperação de senha</Title>
              { recoverError ? <Error className="animated slideInDown">{recoverError}</Error> : null }

              <Input type="email" name="email" value={email} placeholder="Digite seu E-mail" onChange={this.onChangeField} style={{ marginBottom: 5, marginTop: 15 }} height={10} fontSize={16} />
            </React.Fragment>
          )
        }
      </form>
    )
  }

  _renderLogin = () => {
    const { mobile } = this.props
    const { accountName, loginError, inputs } = this.state
    const { password } = inputs
    ReactGA.modalview('/login')

    return (
      <form onSubmit={this.onSubmit} style={{textAlign: 'center'}}>
        <Title fontSize={mobile ? 20 : 24} bold>Olá {accountName}! Informe sua senha</Title>
        { loginError ? <Error className="animated slideInDown">{loginError}</Error> : null }

        <Input type="password" name="password" value={password} placeholder="Senha" onChange={this.onChangeField} style={{ marginBottom: 5,  marginTop: 15 }} height={10} fontSize={16} />
        <TextLink onClick={() => this.setState({ action: 'recover', canContinue: true })}>Esqueceu a senha?</TextLink>
      </form>
    )
  }

  _renderRegister = () => {
    const { mobile } = this.props
    const { inputs, fullNameValid, telephoneValid, passwordValid, repeatPasswordValid, registerError } = this.state
    const { name, telephone, password, repeatPassword } = inputs
    ReactGA.modalview('/register')
    //const error = !fullNameValid && name.length > 0 ? 'Informe seu Nome e Sobrenome por favor.' : registerError

    return (
      <form onSubmit={this.onSubmit}>
        <Title fontSize={mobile ? 20 : 24} bold>Falta pouco para finalizar seu cadastro!</Title>
        { registerError ? <Error className="animated slideInDown">{registerError}</Error> : null }

        <InputGroup style={{marginTop: 15}}>
          <Input type="text" name="name" value={name} validfield={fullNameValid} placeholder="Nome Completo" maxlength={60} height={10} fontSize={16} onChange={this.onChangeField} />
          { !fullNameValid && name.length > 0 ? <Error className="animated slideInDown">Informe seu Nome e Sobrenome por favor.</Error> : null }
        </InputGroup>

        <InputGroup style={{marginBottom: 15}}>
          <InputMask type="tel" name="telephone" value={telephone} validfield={telephoneValid.toString()} placeholder="Número de Celular" mask="(99) 9 9999-9999" onChange={this.onChangeField} height={10} fontSize={16} />
          { !telephoneValid && telephone.length > 0 ? <Error className="animated slideInDown">Número de Celular incompleto. Informe todos os espaços em branco.</Error> : null }
        </InputGroup>

        <Input type="password" name="password" value={password} placeholder="Senha" onChange={this.onChangeField} style={{ marginBottom: 5 }} height={10} fontSize={16} validfield={passwordValid} />
        <Input type="password" name="repeatPassword" value={repeatPassword} placeholder="Confirmação de senha" onChange={this.onChangeField} style={{ marginBottom: 10 }} height={10} fontSize={16} validfield={repeatPasswordValid} />
      </form>
    )
  }

  _renderFindAccount = () => {
    const { mobile } = this.props
    const { inputs } = this.state
    const { email } = inputs
    ReactGA.modalview('/account/find')

    return (
      <form onSubmit={this.onSubmit}>
        <Title fontSize={mobile ? 20 : 24} bold>Informe o seu e-mail para continuar</Title>

        <Input type="email" name="email" value={email} placeholder="Digite seu E-mail" onChange={this.onChangeField} style={{ marginBottom: 5, marginTop: 15 }} height={10} fontSize={16} />
      </form>
    )
  }
  _renderLoginSocial = () => {
    const { appId, clientId, mobile, google_url, facebook_url } = this.props;
    const { action, loadingGoogle } = this.state;

    /*
    * Handle login Google
    */
   const loginGoogle = () => {
     if (google_url) window.location.replace(google_url)
    }
    /**
     *  Handle login Facebook
    */
    const loginFacebook = () => {
      if (facebook_url) window.location.replace(facebook_url)
    }

    if (action === 'findSocialAccount') {
      return(
        <SocialLoginContainer>
          <Title fontSize={24} bold>Como deseja continuar ?</Title>
          { 
            appId ? (
             <ButtonFB mobile={mobile} onClick={loginFacebook}>
               <div>
                <IconFB width={20} height={20}/>
               </div>
               <span>
                 Continuar com facebook
               </span>
               </ButtonFB>
            ): null
          }

        { 
          clientId ? (
            <ButtonGO mobile={mobile} onClick={loginGoogle}>
               <div>
                <img src={IconGmail} alt="google-img" width={23}/>
               </div>
               <span>
               {loadingGoogle ? 'Aguarde um instante' : 'Continuar com google'}
               </span>
               </ButtonGO>
          ) : null
        }
        {
          appId || clientId ? (
            <React.Fragment>
              <DivSpacer/>
              <Separator>
                <span>ou</span>
                </Separator>
                <DivSpacer/>
            </React.Fragment>
          ) : null
        }
        </SocialLoginContainer>
      );
    }
  }

  render() {
    const { action, loading, canContinue } = this.state
    const { mobile, appId, clientId } = this.props

    return (
      <React.Fragment>
        <Row style={{margin: '0px 35px 20px 35px'}}>
          { 
            action === 'findAccount' && (appId || clientId) ? (
            <div style={{position: 'absolute', left:20}}>
              <FontAwesomeIcon 
              onClick={() => this.setState({action: 'findSocialAccount'})} 
              style={{width:25, height:25, color:"#00BF91"}} 
              icon={Icons.faAngleLeft}/>
              </div>
            ): null
          }
          <Col xs={12} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            {
              // Find account
              action === 'findAccount' ? this._renderFindAccount() : null
            }

            {
              // Register account
               action === 'register' ? this._renderRegister() : null
            }

            {
              // Login account
              action === 'login' ? this._renderLogin() : null
            }

            {
              // Recover password
              action === 'recover' || action === 'recover-success' ? this._renderRecoverPassword() : null
            }
          </Col>

        </Row>
        {
          action === 'findSocialAccount' ? (
            <Row style={{margin: mobile ? 1 :'0px 40px 0px 40px', maxWidth: '400px'}}>
              {this._renderLoginSocial()}
              <ActionButton 
              style={{width: '100%'}}
              onClick={() => this.setState({ action: 'findAccount' })}
              >
                Continuar com email
              </ActionButton>
            </Row>
          ) : null
        }

        {
          action !== 'recover-success' && action !== 'findSocialAccount' ? (
            <Row style={{margin: '0px 40px 0px 40px'}}>
              <LaddaButton
                type="submit"
                className="btn-ladda-primary"
                size={XL}
                spinnerSize={30}
                spinnerColor="#ddd"
                spinnerLines={12}
                onClick={this._next}
                disabled={!canContinue || loading}
              >
                Continuar
              </LaddaButton>
            </Row>
          ) : null
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ account, login, startup }) => ({
  fetchingAccount: account.fetching,
  errorAccount: account.error,
  accountExist: account.exist,
  accountName: account.name,

  fetchingLogin: login.fetching,
  errorLogin: login.error,

  google_url: startup.store.googleUrl,
  facebook_url: startup.store.facebookUrl
})

const mapDisptachToProps = dispatch => ({
  attemptFindAccount: (email) => dispatch(Creators.findAccountRequest(email)),
  attemptLogin: (email, password) => dispatch(Creators.loginRequest(email, password)),
  attemptRegister: ({ firstname, lastname, telephone, email, password }) => dispatch(Creators.registerRequest(firstname, lastname, telephone, email, password)),
  attemptRecoverPassword: (email) => dispatch(Creators.recoverPasswordRequest(email)),
})

export default connect(mapStateToProps, mapDisptachToProps)(RegisterOrLogin)
