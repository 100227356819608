import styled from 'styled-components'

export const SVG = styled.svg`
  margin-right: 8px;
  display: inline-block;
  vertical-align: text-top;

  .a, .c {
    fill: none;
  }
  .b {
    clip-path: url(#a);
  }
  .d, .f {
    fill: ${({ fill }) => fill};
  }
  .d {
    stroke: ${({ stroke }) => stroke};
  }
  .e, .f {
    stroke: none;
  }
`