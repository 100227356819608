import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types } from '../actions'

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: false,
  user: null,
  exist: null,
  name: null
})

export const accountUpdateRequest = (state = INITIAL_STATE, { firstname, lastname, telephone, email, birthdate }) =>
  Object.assign({}, state, {
    fetching: true,
    error: false,
    user: null
  })

export const accountUpdateSuccess = (state = INITIAL_STATE, { user }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    user
  })

export const accountUpdateFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    error: error,
    user: null
  })

export const findAccountRequest = (state = INITIAL_STATE, { email }) =>
  Object.assign({}, state, {
    fetching: true,
    error: false,
    exist: false,
    name: null
  })

export const findAccountSuccess = (state = INITIAL_STATE, { exist, name }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    exist,
    name
  })

export const findAccountFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    exist: false,
    name: null,
    error
  })


  /**
 * Handlers
 */

export const HANDLERS = {
  [Types.ACCOUNT_UPDATE_REQUEST]: accountUpdateRequest,
  [Types.ACCOUNT_UPDATE_SUCCESS]: accountUpdateSuccess,
  [Types.ACCOUNT_UPDATE_FAILURE]: accountUpdateFailure,

  [Types.FIND_ACCOUNT_REQUEST]: findAccountRequest,
  [Types.FIND_ACCOUNT_SUCCESS]: findAccountSuccess,
  [Types.FIND_ACCOUNT_FAILURE]: findAccountFailure
}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)

export default reducer
