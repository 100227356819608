import React from 'react'
import { LogoContainer } from './styles.js'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Logo = ({ image, alt = '', mobile = false, effect = 'blur' }) => (
  <LogoContainer mobile={mobile}>
    <LazyLoadImage alt={alt} src={image} effect={effect} />
  </LogoContainer>
)

export default Logo