import styled from 'styled-components'

export const CartButton = styled.div`
  background-color: ${({ color }) => color || '#707070'};
  border-radius: 5px 0px 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 18vh;
  right: 0;
  width: 91px;
  height: 40px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  font-size: 11px;
  color: #FFFFFF;
  line-height: 11px;
`
export const CartFooter = styled.div`
  margin-top: 5%;
  margin-bottom: 5%;
  background-color: ${({ disabled }) => disabled ? '#A8A8A8' : '#00bf91'};
  border: 0 1px 1px 1px solid #00bf91;
  color: #FFFFFF;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  padding: 5px 0;
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  pointer-events: ${({ disabled }) => disabled ? 'none' : 'auto'};

  &:active {
    background-color: ${({ disabled }) => disabled ? 'unset' : '#00a881 !important'};
  }
`
export const ContainerHeader = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 994;
  width: ${({ visible }) => visible ? '100%' : '0'};
  background-color: #FFFFFF;
  height: 56px;
  transition: .3s ease;
  display: flex;
  justify-content: flex-end;
  i {
    display: ${({ visible }) => visible ? 'block' : 'none'};
    padding: ${({ visible }) => visible ? '16px' : '0'};
  }
`
export const Container = styled.div`
  position: fixed;
  z-index: 995;
  top: 56px;
  bottom: 0;
  right: 0;
  width: ${({ visible }) => visible ? 'calc(100% - 32px)' : '0'};
  transition: .3s ease;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ visible }) => visible ? '0 16px' : '0'};  
`
export const TextWrapper = styled.div`
  padding: 5px;
`
