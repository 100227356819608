import { call, put, select } from 'redux-saga/effects'
import { Creators } from '../actions'
import { loggedToken } from '../redux/LoginRedux'

const userToken = ({ login }) => loggedToken(login)

export function * accountUpdate(api, { firstname, lastname, telephone, email, birthdate }) {
  const token = yield select(userToken)

  api.setHeaders({
    Authorization: `Bearer ${token}`
  })

  const response = yield call(api.accountUpdate, { firstname, lastname, telephone, email, birthdate })
  const { ok, data, problem } = response

  if (ok) {
    let { user } = data
    user.accessToken = token

    yield put(Creators.accountUpdateSuccess(user))
  } else {
    let error = problem
    if (data) {
      error = data.error
    }
    yield put(Creators.accountUpdateFailure(error))
  }
}

export function * findAccount(api, { email }) {
  const response = yield call(api.findAccount, { email })
  const { ok, data, problem } = response

  if (ok) {
    let { exist, name } = data

    yield put(Creators.findAccountSuccess(exist, name))
  } else {
    let error = problem
    if (data) {
      error = data.error
    }
    yield put(Creators.findAccountFailure(error))
  }
}
