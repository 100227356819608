import React, { Component } from "react"
import * as Icons from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AddressCardContainer, InfoContainer, AddressPrimary } from './styles'
import './index.css'

export default class CorreiosCard extends Component {
  render() {
    return (
      <AddressCardContainer to={{ pathname: 'endereco/calculate' }}>
        <FontAwesomeIcon icon={Icons.faTruckMoving} style={{ width: 35, height: 35, color: '#a8a8a8', margin: '0 10px 0 30px' }} />
        <InfoContainer>
          <AddressPrimary>
            CALCULAR FRETE
          </AddressPrimary>
        </InfoContainer>
      </AddressCardContainer>
    )
  }
}