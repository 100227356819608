import React from 'react'
import { SVG } from './styles.js'

const DolarSign = ({ height = 42, width = 42, color = '#a8a8a8' }) => (
  <SVG 
    xmlns="http://www.w3.org/2000/svg" 
    width={width}
    height={height}
    viewBox="0 0 21 37"
  >
    <text
      transform="translate(0 30)"
      fill={color}
      fontSize="31"
      fontFamily="Gotham-Bold, Gotham"
      fontWeight="700"
      letterSpacing="-0.011em"
    >
      <tspan x="0" y="0">$</tspan>
    </text>
  </SVG>
)

export default DolarSign
