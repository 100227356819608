import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: 0;
`
export const HeaderBar = styled.div`
  position: absolute;
  height: 50vh;
  width: 100vw;
  background-color: ${({ color }) => color};
  -moz-transition: background-color .2s ease-in;
  -o-transition: background-color .2s ease-in;
  -webkit-transition: background-color .2s ease-in;
  top: 0;
  z-index: 998;
`
export const BottomBar = styled.div`
  position: absolute;
  height: 50vh;
  width: 100vw;
  background-color: ${({ color }) => color};
  -moz-transition: background-color .2s ease-in;
  -o-transition: background-color .2s ease-in;
  -webkit-transition: background-color .2s ease-in;
  bottom: 0;
  z-index: 998;
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
`
export const Text = styled.span`
  font-size: 28px;
  font-family: Gilmer-Bold;
  color: #fff;
`
