import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types } from '../actions'

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: false,
  paymentMethods: null,
  gatewaySession: null
})

export const paymentMethodListRequest = (state = INITIAL_STATE) =>
  Object.assign({}, state, {
    fetching: true,
    error: false,
    paymentMethods: null
  })

export const paymentMethodListSuccess = (state = INITIAL_STATE, { paymentMethods }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    paymentMethods
  })

export const paymentMethodListFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    paymentMethods: null,
    error
  })

export const paymentGatewaySessionRequest = (state = INITIAL_STATE, { payment_option_id }) =>
  Object.assign({}, state, {
    fetching: true,
    error: false,
    gatewaySession: null
  })

export const paymentGatewaySessionSuccess = (state = INITIAL_STATE, { gatewaySession }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    gatewaySession
  })

export const paymentGatewaySessionFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    gatewaySession: null,
    error
  })

/**
 * Handlers
 */

export const HANDLERS = {
  [Types.PAYMENT_METHOD_LIST_REQUEST]: paymentMethodListRequest,
  [Types.PAYMENT_METHOD_LIST_SUCCESS]: paymentMethodListSuccess,
  [Types.PAYMENT_METHOD_LIST_FAILURE]: paymentMethodListFailure,

  [Types.PAYMENT_GATEWAY_SESSION_REQUEST]: paymentGatewaySessionRequest,
  [Types.PAYMENT_GATEWAY_SESSION_SUCCESS]: paymentGatewaySessionSuccess,
  [Types.PAYMENT_GATEWAY_SESSION_FAILURE]: paymentGatewaySessionFailure
}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)

export default reducer
