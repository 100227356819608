import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Config from '../../config'
import { CardContainer, Content, Text, TextLink, Image } from './styles'

export default class CategoryCard extends Component {
  static propTypes = {
    name: PropTypes.string,
    image: PropTypes.string,
    categorySlug: PropTypes.string,
    subcategories: PropTypes.arrayOf(PropTypes.object)
  }

  _renderSubcategories = () => {
    const { subcategories, categorySlug } = this.props

    if (subcategories) {
      let limitedSubcategories = subcategories
      if (subcategories.length > 4) {
        limitedSubcategories = subcategories.slice(0, 3)
      }

      return limitedSubcategories.map((subcategory, index) => {
        const { name, slug } = subcategory

        return <TextLink key={`subcategory-${index}`} to={{ pathname: `/departamentos/${categorySlug}/${slug}` }} color="#3498db">{`- ${name}`}</TextLink>
      })
    }
  }

  render() {
    const { name, image, categorySlug } = this.props
    const { s3Bucket } = Config

    const categoryImage = image ? `${s3Bucket}${image}` : `${s3Bucket}mockups/icon-sem-imagem-padrao.png`

    return (
      <CardContainer>
        <Content>
          <div style={{width: '50%'}}>
            <Text fontSize={16} bold>{name}</Text>
            {this._renderSubcategories()}
          </div>

          <Link to={{ pathname: `/departamentos/${categorySlug}` }} style={{display: 'flex', alignItems: 'center'}}>
            <Image src={categoryImage} effect="blur" />
          </Link>
        </Content>
      </CardContainer>
    )
  }
}
