import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CardContainer, Title, Divider, Content } from './styles'

export default class ContactCard extends Component {
  static propTypes = {
    title: PropTypes.string
  }

  render() {
    const { title, children } = this.props

    return (
      <CardContainer>
        <Title>{title}</Title>
        <Divider />
        <Content>
          {children}
        </Content>
      </CardContainer>
    )
  }
}
