import styled  from 'styled-components'

export const Title = styled.h2`
  font-family: ${({ bold }) => `Gilmer-${bold ? 'Bold' : 'Light'}`};
  font-size: ${({ fontSize }) => fontSize ? `${fontSize}px` : '15px'};
  text-align: center;
  color: #707070;
  line-height: 20px;
  margin: 20px 0 5px 0;
`
export const Error = styled.p`
  color: #b73535;
  font-size: 14px;
  margin-top: 2px;
  margin-left: 5px;
`
export const TextLink = styled.p`
  font-size: 14px;
  text-decoration: underline;
  color: #3498db;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`

export const GoogleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ContentError = styled.div`
  width: ${({ mobile }) => mobile ? '100%' : '50%'};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px 20px;
`

export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-size: 16px;
    flex-shrink: inherit;
    font-family: Gilmer
  }
`