import React from 'react'
import { SVG } from './styles.js'
import './index.css'

const Pills = ({ width = 40, height = 37 }) => (
  <SVG 
    xmlns="http://www.w3.org/2000/svg" 
    width={width}
    height={height}
    viewBox="0 0 40 37"
  >
    <g transform="translate(-663 -850)">
      <path className="pills-a" d="M33.3,25.789H20.428l-1.884,1.884h3.6V39.145h-8.78a5.741,5.741,0,0,1-5.552-4.319c-.068,0-.136,0-.2,0a9.187,9.187,0,0,1-1.76-.171,7.632,7.632,0,0,0,7.516,6.369H33.3a7.62,7.62,0,0,0,0-15.24Zm-1.736,13.26H25.254a.808.808,0,0,1,0-1.615h6.309a.808.808,0,0,1,0,1.615Zm3.219,0h-.313a.808.808,0,1,1,0-1.615h.313a.808.808,0,0,1,0,1.615Zm-7.689-32.8a7.64,7.64,0,0,0-10.777,0L2.223,20.346A7.62,7.62,0,0,0,13,31.123L27.094,17.029a7.639,7.639,0,0,0,0-10.776ZM11.668,29.79a5.736,5.736,0,0,1-8.112-8.112L9.764,15.47l8.112,8.112Zm12.8-12.934L20,21.317a.808.808,0,0,1-1.142-1.142l4.462-4.461a.807.807,0,1,1,1.142,1.142Zm2.276-2.276-.221.221a.808.808,0,0,1-1.142-1.142l.221-.221a.808.808,0,0,1,1.142,1.142Z" transform="translate(663 845.971)"/>
    </g>
  </SVG>
)

export default Pills
