/* eslint-disable no-useless-escape */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Button from '@components/Button'
import Modal from 'react-responsive-modal'
import { Creators } from '../../actions'
import { SectionContainer, SectionContent, Title, SubTitle, FormGroup, Label, Input, InputMask } from './styles'

class CompleteCheckout extends Component {
  static propTypes = {
    show: PropTypes.bool,
    address: PropTypes.object,
    customer: PropTypes.object,
    mobile: PropTypes.bool,
    onFinish: PropTypes.func.isRequired
  }

  static defaultProps = {
    show: false,
    mobile: false
  }

  constructor(props) {
    super(props)

    this.state = {
      saving: false,
      finishing: false,
      inputs: {
        customerBirthdate: '',
        customerTelephone: '',
        addressPostcode: '',
        addressComplement: ''
      }
    }
  }

  componentDidMount() {
    const { customer, address } = this.props
    const { birthdate, telephone } = customer
    const { postcode, complement } = address

    this.setState({
      inputs: {
        customerBirthdate: birthdate || '',
        customerTelephone: telephone || '',
        addressPostcode: postcode || '',
        addressComplement: complement || ''
      }
    })
  }

  componentWillReceiveProps(newProps) {
    const { onFinish, setCurrentAddress, setUser } = this.props
    const { addressFetching, customerFetching, updatedAddress, updatedCustomer } = newProps
    let newState = Object.assign({}, this.state)

    if (newState.saving && updatedAddress && updatedCustomer && !addressFetching && !customerFetching) {
      newState.saving = false
      newState.finishing = true

      let address = {
        id: updatedAddress.address_id,
        number: 'S/N',
        street: updatedAddress.street,
        complement: updatedAddress.complement,
        neighborhood: updatedAddress.neighborhood,
        postcode: updatedAddress.postcode
      }

      setCurrentAddress(address)
      setUser(updatedCustomer)
      
      setTimeout(() => {
        onFinish({
          customer: updatedCustomer,
          address
        })
      }, 500)
    }

    // Save status if has changed
    if (newState !== this.state) {
      this.setState(newState)
    }
  }

  onChangeField = ({ target: { name, value } }) => {
    this.setState(state => ({
      ...state,
      inputs: {
        ...state.inputs,
        [name]: value
      }
    }))
  }

  _finish = () => {
    const { customer, address, attemptUpdateAddress, attempUpdateCustomer } = this.props
    const { inputs } = this.state
    const { customerBirthdate, customerTelephone, addressPostcode, addressComplement } = inputs
    
    this.setState({
      saving: true
    }, () => {
      const { neighborhood_id } = address.neighborhood

      attemptUpdateAddress({
        address_id: address.id,
        firstname: customer.firstname,
        lastname: customer.lastname,
        street: address.street,
        neighborhood_id: neighborhood_id,
        postcode: addressPostcode,
        complement: addressComplement
      })

      attempUpdateCustomer({
        ...customer,
        birthdate: customerBirthdate,
        telephone: customerTelephone
      })
    })
  }

  _validateFields = () => {
    const { inputs } = this.state
    const { customerBirthdate, customerTelephone, addressPostcode } = inputs

    const birthdateOnlyDigits = /^\d+$/.test(customerBirthdate.replace(/\s/g,'').replace(/\//g, ''))
    const telephoneOnlyDigits = /^\d+$/.test(customerTelephone.replace(/\s/g,'').replace(/\(/g, '').replace(/\)/g, '').replace(/\-/g, ''))
    const postcodeOnlyDigits = /^\d+$/.test(addressPostcode.replace(/\s/g,'').replace(/\-/g, ''))

    return {
      all: birthdateOnlyDigits && telephoneOnlyDigits && postcodeOnlyDigits,
      birthdate: birthdateOnlyDigits,
      telephone: telephoneOnlyDigits,
      postcode: postcodeOnlyDigits
    }
  }

  _renderCustomerMissingData = () => {
    const { customer, mobile } = this.props
    const { birthdate, telephone } = customer

    // There's something missing?
    const somethingMissing = !birthdate || !telephone

    if (somethingMissing) {
      const { customerBirthdate, customerTelephone } = this.state.inputs
      const validation = this._validateFields()
      
      return (
        <SectionContainer>
          <SectionContent>
            <SubTitle>Por favor, confirme seus dados cadastrais.</SubTitle>

            <div style={{display: 'flex', flexDirection: mobile ? 'column' : 'row'}}>
              <FormGroup>
                <Label>Data de Nascimento</Label>
                <InputMask
                  type="tel"
                  id="customerBirthdate"
                  name="customerBirthdate"
                  value={customerBirthdate}
                  placeholder="Ex: 23/05/1995"
                  mask="99/99/9999"
                  height={10}
                  fontSize={16}
                  validfield={validation.birthdate.toString()}
                  onChange={this.onChangeField}
                />
              </FormGroup>

              <FormGroup>
                <Label>Número de telefone</Label>
                <InputMask
                  type="tel"
                  id="customerTelephone"
                  name="customerTelephone"
                  value={customerTelephone}
                  placeholder="Ex: (11) 9 9188-8888"
                  mask="(99) 9 9999-9999"
                  height={10}
                  fontSize={16}
                  validfield={validation.telephone.toString()}
                  onChange={this.onChangeField}
                />
              </FormGroup>
            </div>
          </SectionContent>
        </SectionContainer>
      )
    }
  }

  _renderAddressMissingData = () => {
    const { address, mobile } = this.props
    const { postcode } = address

    if (!postcode || postcode.length === 0) {
      const { addressPostcode, addressComplement } = this.state.inputs
      const validation = this._validateFields()

      return (
        <SectionContainer>
          <SectionContent>
            <SubTitle>Por favor, confirme dados do seu endereço atual.</SubTitle>

            <div style={{display: 'flex', flexDirection: mobile ? 'column' : 'row'}}>
              <FormGroup>
                <Label>CEP</Label>
                <InputMask
                  type="tel"
                  id="addressPostcode"
                  name="addressPostcode"
                  value={addressPostcode}
                  autoComplete="postcode"
                  placeholder="Ex: 59031-100"
                  mask="99999-999"
                  height={10}
                  fontSize={16}
                  validfield={validation.postcode.toString()}
                  onChange={this.onChangeField}
                />
              </FormGroup>

              <FormGroup>
                <Label>Complemento</Label>
                <Input 
                  type="text"
                  name="addressComplement"
                  value={addressComplement}
                  placeholder="Ex: Apartamento 01"
                  fontSize={16}
                  height={10}
                  onChange={this.onChangeField}
                />
              </FormGroup>
            </div>
          </SectionContent>
        </SectionContainer>
      )
    }
  }

  render() {
    const { show } = this.props
    const { saving, finishing } = this.state

    const modalOptions = {
      open: show,
      center: true,
      closeOnEsc: false,
      closeOnOverlayClick: false,
      showCloseIcon: false,
      styles: {
        modal: {
          width: '80%'
        }
      },
      onClose: () => {}
    }

    return (
      <Modal {...modalOptions}>
        {
          !finishing ? (
            <React.Fragment>
              <Title>Estamos quase lá...</Title>

              {this._renderCustomerMissingData()}
              {this._renderAddressMissingData()}

              <Button style={{marginLeft: 'calc(100% - 106px)'}} disabled={!this._validateFields().all || saving} onClick={this._finish}>Finalizar</Button>
            </React.Fragment>
          ) : (
            <Title>Salvando informações...</Title>
          )
        }
      </Modal>
    )
  }
}

const mapStateToProps = ({ address, account }) => ({
  addressFetching: address.fetching,
  addressError: address.error,
  updatedAddress: address.address,
  customerFetching: account.fetching,
  customerError: account.error,
  updatedCustomer: account.user
})

const mapDispatchToProps = dispatch => ({
  attemptUpdateAddress: ({ address_id, firstname, lastname, street, neighborhood_id, complement, postcode }) => dispatch(Creators.addressUpdateRequest(address_id, firstname, lastname, street, neighborhood_id, complement, postcode)),
  attempUpdateCustomer: ({ firstname, lastname, telephone, email ,birthdate }) => dispatch(Creators.accountUpdateRequest(firstname, lastname, telephone, email, birthdate)),
  setCurrentAddress: (address) => dispatch(Creators.setCurrentAddressRequest(address)),
  setUser: (user) => dispatch(Creators.setUserRequest(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(CompleteCheckout)
