import React, { Component } from 'react'
import { Title, Caption, Container } from './styles'
import Button from '@components/Button'
import api from '../../services/API'
class Unsubscribe extends Component {

  async componentDidMount(){
    const { match } = this.props
    const { email } = match.params
    const { unsubscribeCustomer } = api
    await unsubscribeCustomer({ email })
  }

  render() {

    return (
      <Container>
        <Title>
          Se desinscreveu com sucesso
        </Title>
        <Caption>
          Você não receberá emails promocionais.
        </Caption>
        <Button>Voltar à tela inicial</Button>
      </Container>
    )
  }
}

export default Unsubscribe