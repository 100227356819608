import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const Title = styled.h3`
  font-family: Gilmer-Bold;
  color: #707070;
`
export const FormContainer = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 40px;
`
export const InputContainer = styled.div`
  width: calc(40% - 40px);
  @media(min-width: 768px) {
    width: calc(40% - 55px);
  }
`
export const ButtonContainer = styled.div`
  width: 40px;
`
export const Input = styled.input`
  border-radius: 8px 0 0 8px;
  width: 100%;
  height: 28px;
  padding: 5px;
  border: 2px solid #707070;
  font-family: Gilmer;
  font-size: 14px;
  color: #707070;
  outline: none;
  @media(min-width: 768px) {
    height: 28px;
  }

  &::placeholder {
    font-family: Gilmer-Light;
  }
`
export const InputButton = styled.button`
  border-radius: 0 5px 5px 0;
  height: 42px;
  width: 40px;
  border: 0;
  background-color: #707070;
  cursor: pointer;
  @media(min-width: 768px) {
    height: 42px;
    width: 55px;
  }
`
