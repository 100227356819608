import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types } from '../actions'

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: false,
  fingerprint: null,
  products: null
})

export const productSearchRequest = (state = INITIAL_STATE, { param }) =>
  Object.assign({}, state, {
    fetching: true,
    error: false,
    products: null
  })

export const productSearchSuccess = (state = INITIAL_STATE, { products, fingerprint }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    products,
    fingerprint
  })

export const productSearchFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    products: null,
    error
  })

/**
 * Handlers
 */

export const HANDLERS = {
  [Types.PRODUCT_SEARCH_REQUEST]: productSearchRequest,
  [Types.PRODUCT_SEARCH_SUCCESS]: productSearchSuccess,
  [Types.PRODUCT_SEARCH_FAILURE]: productSearchFailure
}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)

export default reducer

export const getFingerPrint = searchState => searchState.fingerprint
