import styled from 'styled-components'

export const SVG = styled.svg`
  margin-right: 8px;
  display: inline-block;
  vertical-align: text-top;

  .about-a {
    fill: none;
  }
  .about-b {
    opacity: ${({ opacity }) => opacity};
    clip-path: url(#a);
  }
  .about-c {
    fill:#fff;
  }
`