import { call, put, select } from 'redux-saga/effects'
import { Creators } from '../actions'
import { loadedStore, getStoreData } from '../redux/StartupRedux'
import { getFingerPrint } from '../redux/SearchRedux'

const storeData = ({ startup }) => getStoreData(startup)
const storeId = ({ startup }) => loadedStore(startup)
const fingerprintData = ({ search }) => getFingerPrint(search)

export function * getProducts(api, { category_id, params }) {
  const storeInfo = yield select(storeData)
  const tenant = storeInfo ? storeInfo.tenant || '' : ''
  const storeId = storeInfo._id
  
  const response = yield call(api.categoryProducts, { tenant, storeId, category_id, params })
  const { ok, data } = response

  if (ok) {
    yield put(Creators.productListSuccess(data))
  } else {
    const { error } = data

    yield put(Creators.productListFailure(error))
  }
}

export function * getProduct(api, { slug }) {
  const store_id = yield select(storeId)
  const fingerprint = yield select(fingerprintData)

  api.setHeaders({
    'store-id': store_id
  })

  const response = yield call(api.getProductBySlug, {
    slug,
    fingerprint
  })
  const { ok, data } = response

  if (ok) {
    const { product } = data

    yield put(Creators.productDetailSuccess(product))
  } else {
    const { error } = data

    yield put(Creators.productDetailFailure(error))
  }
}
