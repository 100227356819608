import React from 'react'
import { SVG } from './styles.js'

const LoadingCircle = ({ height = 200, width = 200, color = '#1999F9' }) => (
  <SVG 
    xmlns="http://www.w3.org/2000/svg" 
    width={width}
    height={height}
    viewBox={`0 0 100 100`}
  >
    <circle cx="50" cy="50" fill="none" stroke={color} strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138" transform="rotate(27.3334 50 50)">
      <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite" />
    </circle>
  </SVG>
)

export default LoadingCircle
