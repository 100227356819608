import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Autosuggest from 'react-autosuggest'
import './styles.css'
import Arrow from '@components/Icons/Arrow'

export default class Autocomplete extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: '',
      filteredSuggestions: []
    }

    this.onChange = this.onChange.bind(this)
    this.renderSuggestion = this.renderSuggestion.bind(this)
    this.getSuggestionValue = this.getSuggestionValue.bind(this)
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this)
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this)
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this)
    this.getSelected = this.getSelected.bind(this)
    this.handleError = this.handleError.bind(this)
  }
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    suggestions: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    field: PropTypes.string,
    identifier: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string,
  }

  static defaultProps = {
    suggestions: [],
    disabled: false,
    field: 'name',
  }

  onChange(event, { newValue }) {
    const { selected, field, onChange, name } = this.props
    const target = event.target

    if (selected && newValue !== this.getSelected()[field]) {
      onChange({
        target: {
          value: '',
          name
        }
      })
    }

    this.setState({
      value: newValue
    }, () => {
      this.handleError(target, newValue)
    })
  }

  handleError(target, newValue) {
    const { filteredSuggestions } = this.state
    const { field, onChange, identifier, name } = this.props

    // search string is > 0 and we do not have any suggestion to match
    if (newValue.length >= 0 && filteredSuggestions.length === 0) {
      target.style.borderColor = 'red'

      // we do have some match
    } else {
      target.style.borderColor = '#00BF91'

      // if we have a exatch match,auto select it
      const found = filteredSuggestions.find(value => value[field].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() === newValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase())

      if (found) {
        onChange({
          target: {
            value: found[identifier],
            name
          }
        })
      }

    }

    // if the search string is empty,lets reset the error feedback
    if (newValue.length === 0) {
      target.style.borderColor = '#00BF91'
    }
  }

  getSelected() {
    const { suggestions, identifier, selected } = this.props
    return suggestions.find(suggestion => suggestion[identifier] === selected)
  }

  onSuggestionSelected(event, { suggestion }) {
    const { onChange, identifier, name } = this.props
    onChange({
      target: {
        value: suggestion[identifier],
        name
      }
    })
  }

  renderInputComponent(inputProps) {
    return (
      <div className="input-container" >
        <input {...inputProps} />
        <Arrow className="icon" />
      </div>
    )
  }

  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    const { suggestions, field } = this.props

    if (inputLength === 0) {
      return suggestions
    }

    return suggestions.filter(value =>
      value[field].toLowerCase().trim().includes(inputValue)
    )
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      filteredSuggestions: []
    });
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      filteredSuggestions: this.getSuggestions(value)
    });
  };

  getSuggestionValue = value => {
    const { field } = this.props
    return value[field]
  }

  renderSuggestion = value => {
    const { field, selected, identifier } = this.props
    return (
      <div className={value[identifier] === selected ? 'highlighted' : ''} >
        {value[field]}
      </div>
    )
  }

  onSubmitForm(e) {
    e.preventDefault()
  }

  render() {
    const { value, filteredSuggestions } = this.state
    const { disabled, placeholder, id } = this.props

    const inputProps = {
      placeholder: placeholder,
      value,
      onChange: this.onChange,
      disabled,
      autoComplete: "off",
    }

    return (
      <form
        autoComplete="off"
        onSubmit={this.onSubmitForm}
      >
        <Autosuggest
          suggestions={filteredSuggestions}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          id={id}
          inputProps={inputProps}
          shouldRenderSuggestions={() => true}
          onSuggestionSelected={this.onSuggestionSelected}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          renderInputComponent={this.renderInputComponent}
        />
      </form>
    )
  }
}