import styled from 'styled-components'

export const Text = styled.h2`
  font-family: ${({ bold }) => `Gilmer-${bold ? 'Bold' : 'Light'}`};
  font-size: ${({ fontSize }) => fontSize ? `${fontSize}px` : '15px'};
  text-align: center;
  color: #707070;
  line-height: 20px;
  margin: 20px 0 5px 0;
`
export const BtnGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 15px;
  margin-right: 18%;
  margin-left: 18%;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;

    & > * {
      margin-top: 15px;
    }
  }
`
