import React from 'react'
import { SVG } from './styles.js'

const Gmail = ({ height = 21, width = 31 }) => (
  <SVG 
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 31.383 21.547"
    >
      <g transform="translate(-402 -733)">
        <path fill="#ffffff" d="M1095.452,715.1v16.1a2.362,2.362,0,0,1-.782,1.341,2.892,2.892,0,0,1-1.908.6q-5.949,0-11.9,0-7.036,0-14.071,0a2.894,2.894,0,0,1-1.908-.6,2.361,2.361,0,0,1-.782-1.341V715.045c.082.052.168.1.245.158l13.424,9.878,2.046,1.5c.1-.068.182-.12.26-.177q6-4.423,12.008-8.846Q1093.762,716.326,1095.452,715.1Z" transform="translate(-662.1 21.409)"/><path fill="#fff" d="M1065.366,707.126a2.637,2.637,0,0,1,1.892-.938c.125-.01.252-.011.378-.011q13.406,0,26.812,0a2.787,2.787,0,0,1,2.3.986L1081.09,718.7Z" transform="translate(-663.366 26.826)"/>
      </g>
  </SVG>
)

export default Gmail
