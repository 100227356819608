import styled from 'styled-components'
import InputMasked from 'react-input-mask'
import Button from '../../components/Button'

export const Title = styled.h2`
  font-family: ${({ bold }) => `Gilmer-${bold ? 'Bold' : 'Light'}`};
  font-size: ${({ fontSize }) => fontSize ? `${fontSize}px` : '15px'};
  text-align: center;
  color: #707070;
  line-height: 20px;
  margin: 20px 0 5px 0;
`
export const Error = styled.p`
  color: #b73535;
  font-size: 14px;
  margin-top: 2px;
  margin-left: 5px;
`
export const TextLink = styled.p`
  font-size: 14px;
  text-decoration: underline;
  color: #3498db;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`
export const InputMask = styled(InputMasked)`
  height: ${({height}) => height ? `${height}px` : '26px'};
  width: ${({width, textAlign}) => width ? `${width}px` : textAlign === 'center' ? '100%' : 'calc(100% - 10px)'};
  border-radius: 10px;
  border: 1px solid #707070; 
  color: #707070;
  font-family: Gilmer;
  font-size: ${({fontSize}) => fontSize ? `${fontSize}px` : '22px'};
  text-align: ${({textAlign}) => textAlign || 'left'};
  outline: none;
  background-color: #FFFFFF;
  background: ${({ image }) => image ? `url(${image}) no-repeat scroll 95% 50%` : 'unset'};
  padding: ${({textAlign}) => textAlign === 'center' ? '15px 0 15px 0' : '15px 0 15px 10px'};

  &:focus {
    border: 1px solid ${({ validfield }) => validfield && validfield === 'true' ? '#00BF91' : '#c0392b'};
  }

  &::placeholder {
    font-family: Gilmer-Light;
  }
`
export const InputGroup = styled.div`
  margin-bottom: 5px;
`
export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid #a8a8a8;
  margin: 0px 0 15px 0;
`
export const Textinfo = styled.p`
  font-family: Gilmer-Light;
  color: #707070;
  font-size: 15px;
  align-self: center;
  margin: 0;

`
export const DivSpacer = styled.div`
  width: 0;
  height: 10px;
`
export const SocialLoginContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  h2 {
    margin: 0 0 20px 0;
  }
`
export const Separator = styled.div`
  display: flex;
  align-items: center;

  ::before {
    border-bottom: 1px solid #999;
    content: "";
    flex: 1;
  }
  ::after {
    border-bottom: 1px solid #999;
    content: "";
    flex: 1;
  }
  span {
    margin: 0 20px 0 20px;
    font-family: Gilmer-Light;
    color: #707070;
    font-size: 18px;
    font-weight: bold;
  }
`
export const ActionButton = styled(Button)`
  border-radius: 5px;
  font-family: Gilmer-Light;
`

export const ButtonFB = styled.button`
  display: flex;
  align-items: center;
  font-family: Gilmer-Light;
  justify-content: center;
  background: #4267b2;
  outline: 0;
  margin-bottom: 5px;
  border: 0;
  border-radius: 5px;
  padding: 5px;
  color: #fff;
  cursor: pointer;
  transition: background 0.3;
  width: 100%;

  &:hover {
    opacity: 0.9;
  }



  div {
    background: #fff;
    border-radius: 5px;
    margin-right: 10px;
  }
  span {
    font-size: ${({mobile}) => mobile ? '14px' : '16px'};
    width: 100%;

  }
`
export const ButtonGO = styled.button`
  display: flex;
  align-items: center;
  font-family: Gilmer-Light;
  justify-content: center;
  background: #499df3;
  outline: 0;
  margin-bottom: 5px;
  border: 0;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  color: #fff;
  transition: background 0.3;
  width: 100%;

  &:hover {
    opacity: 0.9;
  }

  div {
    background: #fff;
    border-radius: 5px;
    padding: 2px;
    margin-right: 10px;
  }
  span {
    font-size: ${({mobile}) => mobile ? '14px' : '16px'};
    width: 100%;
  }
`