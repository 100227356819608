import axios from 'axios'

export class CreateStoneTokenException extends Error {
    constructor(errors = {}, ...params) {
      super(...params)
  
      if (Error.captureStackTrace) {
        Error.captureStackTrace(this, CreateStoneTokenException)
      }
  
      this.name = 'CreateStoneTokenException'
      this.errors = errors
    }
  }

export async function getToken(card){
    try{
    const key = 'pk_test_Lw7B2OxuzVIxQK09'
    const date = card.dateStone.split('/')
    const number = card.number.replace(/[^0-9]/g, '')

    const body = {
        "type": "card",
        "card": {
            "number": number,
            "holder_name": card.holder_name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9 ]/g, " "),
            "exp_month": Number(date[0]),
            "exp_year": Number(date[1]),
            "cvv": card.cvv,
            "label": "Sua bandeira"
            }
        }
        
    const res = await axios.post(`https://api.pagar.me/core/v5/tokens?appId=${key}`, body)
    return res
    }catch(err){
      console.log(err.response) // undefined
      if(err.response.data.errors){
        if(err.response.data.errors["request.card"]) 
          throw new CreateStoneTokenException("Seu cartão está inválido, reveja seus dados e tente novamente.")
        if(err.response.data.errors["request.card.exp_month"]) 
          throw new CreateStoneTokenException("A data do seu cartão é inválida!")
        if(err.response.data.errors["request.card.brand"]) 
        throw new CreateStoneTokenException("O número do seu cartão é inválido!!!")
      } else {
        alert("Bugou")
      }

    }
}

export default getToken