import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import DeliveryBoy from '@components/Icons/DeliveryBoy'
import Button from '@components/Button'
import * as Icons from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AddressCardContainer, InfoContainer, ActionContainer, AddressPrimary, Title, DeliveryContainer, ButtonLink } from './styles'
import Modal from 'react-responsive-modal'
import './index.css'

export default class AddressOption extends Component {
  static propTypes = {
    goToCheckout: PropTypes.bool,
    cities: PropTypes.array
  }
  static defaultProps = {
    cities: [],
    goToCheckout: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      selected: false,
      modalOpen: false

    }
  }
  handleSelect = () => {
    const { selected } = this.state;
    this.setState({ selected: !selected })
  }

  handleCloseModal = () => {
    this.setState((state) => ({
      ...state,
      modalOpen: !this.state.modalOpen
    }))
  }

  _renderCitiesModal = () => {
    const { modalOpen } = this.state
    const { cities } = this.props

    return (
      <Modal open={modalOpen} onClose={this.handleCloseModal} classNames={{ modal: 'modal-cities' }} center>
        <DeliveryContainer>
          <Title>Regiões de entrega</Title>
          <ul>
            {cities && cities.map(city => (
              <li key={city._id}>
                <div>
                  {city.name} - {city.state.code}
                </div>
              </li>
            ))}
          </ul>
        </DeliveryContainer>
      </Modal>
    )

  }

  render() {
    const { state, mobile, hasAddress, cities } = this.props;
    const { selected } = this.state;

    return (
      <React.Fragment>
        <AddressCardContainer mobile={mobile}>
          <DeliveryBoy width="35" height="35" />
          <InfoContainer>
            <AddressPrimary>
              {
                cities && cities.length === 1 ? `MOTOBOY (somente em ${cities.map(city => `${city.name} - ${city.state.code}`).join(' , ')})` :
                  <React.Fragment>
                    MOTOBOY {' '}
                    (<ButtonLink onClick={this.handleCloseModal}>Consultar cidades</ButtonLink>)
                  </React.Fragment>
              }
            </AddressPrimary>
          </InfoContainer>
          {
            hasAddress &&
            <ActionContainer>
              <Link to={{ pathname: '/endereco/add', state: { selectAddress: (state && state.selectAddress) } }}>
                <Button style={{ width: 280 }} color={selected ? 'secondary' : 'primary'} >
                  <FontAwesomeIcon icon={Icons.faPlusCircle} style={{ marginRight: 10 }} />
                  {'Adicionar um novo endereço'}
                </Button>
              </Link>
            </ActionContainer>
          }
          {
            !hasAddress && <ActionContainer>
              <Link to={{ pathname: '/endereco/add', state: { selectAddress: (state && state.selectAddress) } }}>
                <Button style={{ width: 200 }} onClick={this.handleSelect} color={selected ? 'secondary' : 'primary'} >{'Adicionar endereco'}</Button>
              </Link>
            </ActionContainer>
          }

        </AddressCardContainer>
        {this._renderCitiesModal()}
      </React.Fragment>
    )
  }
}
