import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ShipmentContainer, ShipmentHeader, ShipmentList, ShipmentItem, ShipmentListItem, CompanyLogo } from './styles'
import { floatToBRL } from '../../helpers/MoneyFormat'
import Button from '@components/Button'

class ShippingOptions extends Component {

  static propTypes = {
    options: PropTypes.array
  }
  // eslint-disable-next-line 
  defaultPicture = 'https:\/\/sandbox.melhorenvio.com.br\/images\/shipping-companies\/correios.png'

  static defaultProps = {
    id: null,
    options: [],
    onSelect: () => { }
  }
  render() {
    const { options, onSelect, selected } = this.props

    return (
      <ShipmentContainer>
        <ShipmentHeader>
          <li>Transportadora</li>
          <li>Modalidade</li>
          <li>Prazo</li>
          <li>Preço</li>
          <li></li>
        </ShipmentHeader>
        <ShipmentList>
          {options && options.filter(opt => !opt.error).sort((a, b) => a.price - b.price).map(opt => {

            const disabled = selected && selected.id === opt.id

            return (
              <ShipmentListItem key={opt.id}>
                <ShipmentItem key={opt.id}>
                  <li>
                    <CompanyLogo src={opt.company.picture || this.defaultPicture} />
                  </li>
                  <li>{opt.name}</li>
                  <li>{opt.delivery_range.min} - {opt.delivery_range.max} dias úteis</li>
                  <li>{floatToBRL(Number(opt.price))}</li>
                  <li>
                    <Button
                      disabled={disabled}
                      onClick={() => onSelect(opt.id)}
                    >{disabled ? 'Selecionado' : 'Selecionar'}</Button></li>
                </ShipmentItem>
              </ShipmentListItem>
            )
          })}
        </ShipmentList>
      </ShipmentContainer>
    )
  }
}

export default ShippingOptions