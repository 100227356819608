import React from 'react'
import { SVG } from './styles.js'

const Pin = ({ height = 30, width = 30 }) => (
  <SVG 
    xmlns="http://www.w3.org/2000/svg" 
    width={width}
    height={height}
    viewBox="0 0 23.772 33.578"
  >
    <path d="M11.634.061a11.744,11.744,0,0,1,7.65,2.584,11.6,11.6,0,0,1,4.2,6.738,12.032,12.032,0,0,1-.659,6.941c-.608,1.571-1.317,3.09-2.077,4.61A72.646,72.646,0,0,1,16.7,27.419c-.912,1.317-1.925,2.634-2.888,3.9-.557.709-1.165,1.419-1.723,2.128-.152.2-.253.2-.456,0-.912-1.216-1.875-2.381-2.786-3.6-1.064-1.368-2.027-2.837-3.04-4.256a45.887,45.887,0,0,1-4-7.042A20.841,20.841,0,0,1,.133,13.639a10.307,10.307,0,0,1,.3-4.965,11.774,11.774,0,0,1,2.28-4.306A11.733,11.733,0,0,1,9.506.264,5.242,5.242,0,0,1,11.634.061Zm.2,16.719a4.976,4.976,0,0,0,4.914-4.864A4.887,4.887,0,0,0,11.837,7a4.8,4.8,0,0,0-4.864,4.864A4.876,4.876,0,0,0,11.837,16.78Z" 
      transform="translate(0.038 -0.021)" 
      fill="#a8a8a8"
    />
  </SVG>
)

export default Pin
