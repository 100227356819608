import React from 'react'
import { SVG } from './styles.js'
import './index.css'

const TrashAlt = ({ width = 25, height = 34, style = {}, onClick = () => {} }) => (
  <SVG 
    xmlns="http://www.w3.org/2000/svg" 
    width={width}
    height={height}
    style={style}
    onClick={onClick}
    viewBox="0 0 25 34"
  >
    <g transform="translate(-1087.823 -734)">
      <g transform="translate(1094.86 749.647)">
        <path className="trash-alt-a" d="M14.447,42.32a.971.971,0,0,1-.947-.947V30.447a.947.947,0,1,1,1.895,0V41.373A.971.971,0,0,1,14.447,42.32Z" transform="translate(-13.5 -29.5)"/>
        <g transform="translate(4.484)">
          <path className="trash-alt-a" d="M21.547,42.32a.971.971,0,0,1-.947-.947V30.447a.947.947,0,1,1,1.895,0V41.373A.93.93,0,0,1,21.547,42.32Z" transform="translate(-20.6 -29.5)"/>
        </g>
        <g transform="translate(9.031)">
          <path className="trash-alt-a" d="M28.747,42.32a.971.971,0,0,1-.947-.947V30.447a.947.947,0,1,1,1.895,0V41.373A.971.971,0,0,1,28.747,42.32Z" transform="translate(-27.8 -29.5)"/>
        </g>
      </g>
      <g transform="translate(1090.033 742.187)">
        <path className="trash-alt-a" d="M22.307,45.356H5.32a.856.856,0,0,1-.827-.884L3.5,20.41a1.035,1.035,0,0,1,.221-.695.841.841,0,0,1,.607-.316H23.189a.841.841,0,0,1,.607.316,1.035,1.035,0,0,1,.221.695l-.882,24.061A.857.857,0,0,1,22.307,45.356ZM6.092,43.461H21.534l.827-22.166H5.21Z" transform="translate(-3.5 -19.4)"/>
      </g>
      <g transform="translate(1087.823 738.357)">
        <path className="trash-alt-a" d="M24.153,13.152H.847A.924.924,0,0,1,0,12.2V7.847A.924.924,0,0,1,.847,6.9H24.153A.924.924,0,0,1,25,7.847V12.2A.924.924,0,0,1,24.153,13.152ZM1.693,11.257H23.307V8.795H1.693Z" transform="translate(0 -6.9)"/>
      </g>
      <g transform="translate(1093.919 734)">
        <path className="trash-alt-a" d="M23.809,6.252H12.947A.971.971,0,0,1,12,5.3V.947A.971.971,0,0,1,12.947,0H23.809a.971.971,0,0,1,.947.947V5.3A.93.93,0,0,1,23.809,6.252ZM13.895,4.357h8.968V1.895H13.895Z" transform="translate(-12)"/>
      </g>
    </g>
  </SVG>
)

export default TrashAlt
