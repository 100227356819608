import React, { Component } from 'react'
import { connect } from 'react-redux'
import Body from '@components/Body'
import ContactCard from '@components/ContactCard'
import { Col, Row } from 'react-flexbox-grid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as BrandIcons from '@fortawesome/free-brands-svg-icons'
import * as Icons from '@fortawesome/free-solid-svg-icons'
import GAEvents from '../../helpers/GAEvents'
import { List, ButtonGroup, IconButton, OutlineButton, InputMask } from './styles'
import './index.css'

class Contact extends Component {
  render() {
    const { store } = this.props
    const { settings } = store
    const {
      config_address,
      config_store_city,
      config_store_number,
      config_phone,
      config_email,
      config_whatsapp_phone,
      config_whatsapp_button
    } = settings

    /*
      <li>
        <FontAwesomeIcon icon={BrandIcons.faFacebookF} style={{marginRight: 15}} />
        Facebook
      </li>
      <li>
        <FontAwesomeIcon icon={BrandIcons.faTwitter} style={{marginRight: 8}} />
        Twitter
      </li>
      <li>
        <FontAwesomeIcon icon={BrandIcons.faInstagram} style={{marginRight: 10}} />
        Instagram
      </li>
    */

    return (
      <Body>
        <Row>
          <Col xs={4} style={{ padding: 0, display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            {config_phone && config_phone.length > 0 &&
              <ButtonGroup>
                <a href={`tel:${config_phone}`} style={{ outline: 'none' }} onClick={() => { GAEvents({ category: 'Contato', action: 'Click no botão de Telefone' }) }}>
                  <IconButton style={{ marginRight: 10, outline: 'none' }}>
                    <FontAwesomeIcon icon={Icons.faPhone} rotation={90} size="2x" />
                  </IconButton>
                </a>
                <OutlineButton>
                  <a href={`tel:${config_phone}`} style={{ outline: 'none', fontSize: 14 }} onClick={() => { GAEvents({ category: 'Contato', action: 'Click no botão de Telefone' }) }}>
                    <InputMask type="tel" name="telephone" disabled value={config_phone} placeholder="" mask="(99) 9999-9999" height={10} fontSize={16} />
                  </a>
                </OutlineButton>
              </ButtonGroup>
            }

            <List style={{ marginTop: 10 }}>
              {
                config_whatsapp_button && config_whatsapp_phone && config_whatsapp_phone.length > 0 && (
                  <li>
                    <a style={{ display: 'flex', alignItems: 'center', fontSize: 14, fontWeight: 'bold', color: '#707070', minWidth: 285 }} href={`https://wa.me/55${config_whatsapp_phone.replace(/\D+/g, '')}`} target="_blank" rel="noopener noreferrer" onClick={() => { GAEvents({ category: 'Contato', action: 'Click no botão do WhatsApp na página de Contatos' }) }}>
                      <FontAwesomeIcon icon={BrandIcons.faWhatsapp} size="3x" color={'#46C655'} style={{ marginRight: 10 }} />
                      Whatsapp - <InputMask type="tel" name="whatsapp" disabled value={config_whatsapp_phone} placeholder="" mask="(99) 9 9999-9999" height={10} fontSize={16} />

                    </a>
                  </li>
                )
              }
            </List>
          </Col>
          <Col xs={8}>
            <Row>
              <Col xs={6} style={{ marginTop: 10, marginLeft: 10 }}>
                <ContactCard title={config_store_city}>
                  <List>
                    <li>{config_address}, {config_store_number}</li>
                    <li>{<InputMask type="tel" name="telephone" disabled value={config_phone} placeholder="" mask="(99) 9999-9999" fontSize={12} />}</li>
                    <li>{config_email}</li>
                  </List>
                </ContactCard>
              </Col>
            </Row>
          </Col>
        </Row>
      </Body>
    )
  }
}

const mapStateToProps = ({ startup }) => ({
  store: startup.store
})

export default connect(mapStateToProps, null)(Contact)
