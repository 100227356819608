import { create } from 'apisauce'
import Config from '../config'

const api = create({
  baseURL: Config.masterApi
})

const authApi = create({
  baseURL : Config.authApi
})

const paymentApi = create({
  baseURL : Config.paymentApi
})

// Configuration
const setHeaders = (opts) => api.setHeaders(opts)
const setURL = (url) => api.setBaseURL(url)
const authSetHeaders = (opts) => authApi.setHeaders(opts)
const authSetURL = (url) => authApi.setBaseURL(url)
const paymentSetHeaders = (opts) => paymentApi.setHeaders(opts)
const paymentSetURL = (url) => paymentApi.setBaseURL(url)

// Methods
const startup = () => authApi.get('/v1/store/startup')
const login = ({ email, password }) => authApi.post('/v1/customer/session', { email, password })
const renewToken = () => authApi.get('/v1/customer/renew')
const register = ({ store_id, firstname, lastname, telephone, email, password }) => authApi.post('/v1/customer/register', { store_id, firstname, lastname, telephone, email, password })
const accountUpdate = ({ firstname, lastname, telephone, email, birthdate }) => authApi.post('/v1/customer/update', { firstname, lastname, telephone, email, birthdate })
const findAccount = ({ email }) => authApi.post('/v1/customer/login/email', { email })
const recoverPassword = ({ email }) => authApi.post('/v1/customer/login/recover', { email })
const validateResetPasswordToken = ({ token }) => authApi.get(`/v1/customer/login/validate-reset-password/${token}`)
const resetPassword = ({ token, password }) => authApi.post(`/v1/customer/login/reset-password/${token}`, { password })
const getCategories = () => api.get('/v1/category')
const getDeliveries = ({ neighborhood_id }) => api.get(`/v1/delivery/area/${neighborhood_id || ''}`)
const getDeliverySchedule = () => api.get('/v1/delivery/schedule')
const getPaymentMethods = () => paymentApi.get('v1/payment/methods')
const getAddresses = () => api.get('v1/address')
const getCities = () => api.get('v1/delivery/cities')
const getNeighborhoods = () => api.get('v1/delivery/neighborhoods') // DEPRECATED
const registerAddress = ({ address_id, firstname, lastname, street, neighborhood_id, complement, postcode = '', not_deliverable = false, addressType, neighborhood, city, state_address, number }) => api.put('/v1/address', { address_id, firstname, lastname, street, neighborhood_id, complement, postcode, not_deliverable, addressType, neighborhood, city, state_address, number })
const deleteAddress = (address_id) => api.delete(`v1/address/${address_id}`)
const registerOrder = ({ address_id, city_id, payment_code, payment_option_id, payment_money_change, products, cpf, sub_method, sender_hash, card_token, card_holder, installments, payment_custom_field, code = null, delivery_mode, shipping_order, health_insurance }) => paymentApi.put('/v1/order', { address_id, city_id, payment_code, payment_option_id, payment_money_change, products, cpf, sub_method, sender_hash, card_token, card_holder, installments, payment_custom_field, code, delivery_mode, shipping_order, health_insurance })
const getOrderDetail = ({ order_id }) => paymentApi.get(`/v1/order/detail/${order_id}`)
const getOrders = () => paymentApi.get('v1/order')
const getBanners = () => api.get('v1/banner')
const getFeaturedProducts = () => api.get('v1/product/feature')
const getAboutUs = () => api.get('v1/about-us')
const startSession = ({ payment_option_id }) => paymentApi.post('/v1/gateway/session', { payment_option_id })
const getCart = ({ fingerprint }) => paymentApi.get(`v1/cart/${fingerprint}`)
const updateCart = ({ products, code, fingerprint,origin }) => paymentApi.put('v1/cart', { products, code, fingerprint,origin })
const addSingleToCart = ({ product_ean, fingerprint, origin, customer_id }) => paymentApi.post('v1/cart/add-single', { product_ean, fingerprint, origin, customer_id })
const registerWithdrawlOrder = ({ address_id, city_id, payment_code, payment_option_id, payment_money_change, products, cpf, sub_method, sender_hash, card_token, card_holder, installments, shipping_custom_fields }) => paymentApi.put('/v1/order-withdrawl', { address_id, city_id, payment_code, payment_option_id, payment_money_change, products, cpf, sub_method, sender_hash, card_token, card_holder, installments, shipping_custom_fields })
const getOrderPicpay = ({ orderId }) => paymentApi.get(`/v1/order-picpay/detail/${orderId}`)
const googleLogin = ({ code }) => authApi.post(`/v1/customer/google/callback`, { code })
const facebookLogin = ({ code }) => authApi.post(`/v1/customer/facebook/callback`, { code })
const getProductBySlug = ({ slug, fingerprint, customer_id = null }) => api.post(`v1/product/${slug}`, { fingerprint, customer_id })
const getRegionsDelivery = ({ _id }) => api.get(`v1/delivery/regions/${_id || ''}`)
const getAddressByCep = ({ postcode }) => api.get(`v1/location/${postcode}`)
const updateCartUnknow = ({ products, code, fingerprint, user = null }) => paymentApi.put('v1/cart/unknow', { products, code, fingerprint, user })
const unsubscribeCustomer = ({email}) => api.post(`v1/customer/unsubscribe/${email}`)
const updateGlobalCart = ({ products, purchased }) => paymentApi.put('/v1/cart/send', { products, purchased })

export default {
  setHeaders,
  setURL,
  authSetHeaders,
  authSetURL,
  paymentSetHeaders,
  paymentSetURL,
  unsubscribeCustomer,
  startup,
  login,
  renewToken,
  register,
  accountUpdate,
  findAccount,
  recoverPassword,
  validateResetPasswordToken,
  resetPassword,
  getCategories,
  getDeliveries,
  getDeliverySchedule,
  getPaymentMethods,
  getAddresses,
  getCities,
  getNeighborhoods,
  registerAddress,
  deleteAddress,
  registerOrder,
  getOrderDetail,
  getOrders,
  getBanners,
  getFeaturedProducts,
  getAboutUs,
  startSession,
  getCart,
  updateCart,
  addSingleToCart,
  registerWithdrawlOrder,
  getOrderPicpay,
  googleLogin,
  facebookLogin,
  getProductBySlug,
  getRegionsDelivery,
  getAddressByCep,

  updateCartUnknow,
  updateGlobalCart
}
