import styled from 'styled-components'

export const Title = styled.h2`
  line-height: 0;
  font-family: Gilmer-Bold;
  font-size: 28px;
`
export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid #a8a8a8;
  margin-top: 30px;
`
export const DividerContent = styled.div`
  background-color: #fff;
  width: 23px;
  margin-bottom: -8px;
  margin-left: 50%;
`
export const Label = styled.p`
  line-height: 20px;
  font-family: Gilmer;
  font-size: 15px;
  color: #828282;
  margin: 0;
`
export const Value = styled.span`
  font-family: Gilmer-Light;
  font-size: 15px;
  color: #A8A8A8;
`
