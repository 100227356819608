import styled from 'styled-components'

export const CardContainer = styled.div`
  min-height: 80px;
`
export const Title = styled.h4`
  color: #606060;
  font-family: "Gilmer-Bold"
  line-height: 0;
  margin: 0;
`
export const Divider = styled.hr`
  color: #707070;
  margin: 0;
`
export const Content = styled.div`
  font-size: 13px;
  color: #707070;
`
