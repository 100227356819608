import styled from 'styled-components'

export const Container = styled.div`
  width : 100vw;
  display : flex;
  justify-content : center;
  align-items : center;
  box-sizing : border-box;
  padding: 2rem;
  flex-direction : column;
  background-color : rgba(0,0,0,0.9);
  position : fixed;
  z-index : 999999;
  bottom : 0;
`
export const Text = styled.p`
  font-size : 1rem;
  color : white;
  @media only screen and (max-device-width : 667px){
    text-align : center;
  }
`

export const TextLinked = styled.a`
  font-size : 1rem;
  color : white;
  margin-left : 8px;
  text-decoration : underline;
`

export const AcceptBtn = styled.button`
  padding : 16px;
  display : flex;
  align-items : center;
  font-size : 1rem;
  border-radius : 4px;
  background : green;
  color : white;
  outline : none;
  border : none;
  &:hover:{
    cursor : pointer;
  }
`

export const Row = styled.div`
  display : flex;
  align-items : center;
  gap : 2rem;

  @media (max-width : 760px){
    flex-direction : column;
    gap : 1rem;
  }
`