import { call, put, select } from 'redux-saga/effects'
import { Creators } from '../actions'
import { loggedStore } from '../redux/StartupRedux'

const accessTokenStore = ({ startup }) => loggedStore(startup)

export function * getPaymentMethods(api) {
  const token = yield select(accessTokenStore)
  api.paymentSetHeaders({
    Authorization: `Bearer ${token}`,
  })
  
  const response = yield call(api.getPaymentMethods)
  const { ok, data } = response

  if (ok) {
    const { paymentMethods } = data

    yield put(Creators.paymentMethodListSuccess(paymentMethods))
  } else {
    const { error } = data

    yield put(Creators.paymentMethodListFailure(error))
  }
}

export function * getPaymentGatewaySession(api, { payment_option_id }) {
  const token = yield select(accessTokenStore)
  api.paymentSetHeaders({
    Authorization: `Bearer ${token}`,
  })
  
  const response = yield call(api.startSession, { payment_option_id })
  const { ok, data } = response

  if (ok) {
    const { session } = data

    yield put(Creators.paymentGatewaySessionSuccess(session))
  } else {
    const { error } = data

    yield put(Creators.paymentGatewaySessionFailure(error))
  }
}
