/* eslint-disable no-useless-escape */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Input from '@components/Input'
import Button from '@components/Button'
import { Creators } from '../../actions'
import ErrorCode from '../../helpers/ErrorCodes'
import { Title, Error, InputMask, InputGroup } from './styles'
import './styles'

class MissingData extends Component {
  static propTypes = {
    mobile: PropTypes.bool,
    customer: PropTypes.object
  }

  static defaultProps = {
    mobile: false
  }

  constructor(props) {
    super(props)

    this.state = {
      saving: false,
      finishing: false,
      error: null,
      enable: false,
      inputs: {
        email: '',
        telephone: '',
        name: '',
      },
      emptyFields: {
        fieldEmail: false,
        fieldName: false
      }    
    }
  }

  componentDidMount() {
    const { customer } = this.props;

    if (customer) {
      const { firstname, lastname, email, telephone } = customer;

      this.setState({
        inputs: {
          name: `${firstname} ${lastname}` || '',
          email: email || '',
          telephone: telephone || ''  
        },
        emptyFields: {
          fieldEmail: !email,
          fieldName: (!firstname || !lastname),
        }
      })
    }
  }

  componentWillReceiveProps(newProps) {
    const { onFinish, setUser } = this.props;
    const { customerFetching, updatedCustomer, customerError } = newProps;

    let newState = Object.assign({}, this.state)

    if (newState.saving && customerError && !customerFetching) {
      newState.error = ErrorCode(customerError)
    }

    if (newState.saving && updatedCustomer && !customerFetching) {
      newState.saving = false
      newState.finishing = true

      setUser(updatedCustomer)
      setTimeout(() => {
        onFinish({
          customer: updatedCustomer
        })
      }, 500)
    }

    // Update state if needed
    if (newState !== this.state) {
      this.setState(newState)
    }
  }

  onChangeField = ({ target: { name, value } }) => {
    this.setState(state => ({
      ...state,
      inputs: {
        ...state.inputs,
        [name]: value
      }
    }), () => {
      // Validate register fields
      this.validateRegisterFields()
    })
  }

  validateRegisterFields = () => {
    const {  inputs } = this.state
    const { email, name, telephone } = inputs
 
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const nameRegex = /^[a-záàâãéèêíïóôõöúçñ]([-']?[a-záàâãéèêíïóôõöúçñ]+)*( [a-záàâãéèêíïóôõöúçñ]([-']?[a-záàâãéèêíïóôõöúçñ]+)*)+\s?$/i
  
    const telephoneOnlyDigits = /^\d+$/.test(telephone.replace(/\s/g,'').replace(/\(/g, '').replace(/\)/g, '').replace(/\-/g, ''))

    return  {
      all: nameRegex.test(name) && telephoneOnlyDigits && emailRegex,
      bFullNameValid: nameRegex.test(name),
      bTelephoneValid: telephoneOnlyDigits,
      bEmailValid: emailRegex.test(email.toLowerCase())
    }
  }

  _finish = () => {
    const {  attemUpdateAccount } = this.props
    const {  inputs } = this.state
    const { email, name, telephone } = inputs

    this.setState({
      saving: true,
    }, () => {
      const fullName = name.split(' ')
      const firstname = fullName[0]
      const lastname = fullName.slice(1).toString().replace(/\,/g, ' ')

      setTimeout(() => {
        attemUpdateAccount({
          firstname,
          lastname,
          telephone: telephone,
          email: email.toLowerCase(),
        })
      }, 500)
    })
  }
  
  _renderRegister = () => {
    const { inputs, registerError, emptyFields } = this.state
    const { name, telephone, email } = inputs
    const { fieldEmail, fieldName } = emptyFields;

    const validation = this.validateRegisterFields();

    return (
      <form style={{marginBottom: 5}}>
        { registerError ? <Error className="animated slideInDown">{registerError}</Error> : null }
        {
         fieldName ? 
          <InputGroup style={{marginTop: 15,  marginBottom: 15}}>
            <Input type="text" name="name" value={name}  validfield={!validation.bFullNameValid.toString()} placeholder="Nome Completo" maxlength={60} height={10} fontSize={16} onChange={this.onChangeField} />
            { !validation.bFullNameValid  ? <Error className="animated slideInDown">Informe seu Nome e Sobrenome, por favor.</Error> : null }
          </InputGroup>
          : null
        }

        {
          fieldEmail ? (
            <InputGroup style={{marginTop: 15}}>
              <Input type="email" name="email" value={email} validfield={!validation.bEmailValid.toString()}  placeholder="Email" maxlength={60} height={10} fontSize={16} onChange={this.onChangeField} />
              { !validation.bEmailValid && email.length > 0 ? <Error className="animated slideInDown">Email invalido.</Error> : null }
            </InputGroup>
          ) : null
        }

        <InputGroup style={{marginBottom: 15}}>
          <InputMask type="tel" name="telephone" value={telephone} validfield={!validation.bTelephoneValid.toString()} placeholder="Número de Celular" mask="(99) 9 9999-9999" onChange={this.onChangeField} height={10} fontSize={16} />
          { !validation.bTelephoneValid ? <Error className="animated slideInDown"> Campo obrigatório, Informe seu Número de Celular.</Error> : null }
        </InputGroup> 
      </form>
    )
  }

  render() {
    const { saving, finishing, error } = this.state
    
    return (
      !finishing ? (
          <React.Fragment>
            <Title>Estamos quase lá complete seu cadastro</Title>
              {error ?  <Error className="animated slideInDown">{error}</Error>  : null}
            {this._renderRegister()}
            <Button style={{width: '100%'}} disabled={!this.validateRegisterFields().all || saving} onClick={this._finish}>Finalizar</Button>
          </React.Fragment>
      ) : (
        <Title>Salvando informações...</Title>
      )
    )
  }
}

const mapStateToProps = ({ account }) => ({
  customerFetching: account.fetching,
  customerError: account.error,
  updatedCustomer: account.user
})

const mapDisptachToProps = dispatch => ({
  attemUpdateAccount: ({ firstname, lastname, telephone, email, birthdate }) => dispatch(Creators.accountUpdateRequest(firstname, lastname, telephone, email, birthdate)),
  setUser: (user) => dispatch(Creators.setUserRequest(user))
})

export default connect(mapStateToProps, mapDisptachToProps)(MissingData)
