import { call, put, select } from 'redux-saga/effects'
import { Creators } from '../actions'
import { loadedStore, loggedStore } from '../redux/StartupRedux'
import { loggedToken } from '../redux/LoginRedux'
import { getCoupom } from '../redux/CartRedux'

const accessTokenStore = ({ startup }) => loggedStore(startup)
const storeId = ({ startup }) => loadedStore(startup)
const userToken = ({ login }) => loggedToken(login)
const userCupom = ({ cart }) => getCoupom(cart)

export function * registerOrder(api, { address_id, city_id, payment_code, payment_option_id, payment_money_change, products, cpf, sub_method, sender_hash, card_token, card_holder, installments, payment_custom_field, delivery_mode, shipping_order, health_insurance }) {
  const token = yield select(userToken)
  const code = yield select(userCupom)

  api.paymentSetHeaders({
    Authorization: `Bearer ${token}`,
  })
  
  const response = yield call(api.registerOrder, { address_id, city_id, payment_code, payment_option_id, payment_money_change, products, cpf, sub_method, sender_hash, card_token, card_holder, installments, payment_custom_field, code: code, delivery_mode, shipping_order, health_insurance })
  const { ok, data } = response

  if (ok) {
    const { order } = data
    
    yield put(Creators.orderRegisterSuccess(order))
  } else {
    const { error } = data

    yield put(Creators.orderRegisterFailure(error))
  }
}

export function * getOrderDetail(api, { order_id }) {
  const store_id = yield select(storeId)
  const token = yield select(userToken)

  api.paymentSetHeaders({
    Authorization: `Bearer ${token}`,
    'store-id': store_id
  })
  
  const response = yield call(api.getOrderDetail, { order_id })
  const { ok, data } = response

  if (ok) {
    const { order } = data

    yield put(Creators.orderDetailSuccess(order))
  } else {
    const { error } = data

    yield put(Creators.orderDetailFailure(error))
  }
}

export function * getOrders(api) {
  const store_id = yield select(storeId)
  const token = yield select(userToken)

  api.paymentSetHeaders({
    Authorization: `Bearer ${token}`,
    'store-id': store_id
  })
  
  const response = yield call(api.getOrders)
  const { ok, data } = response

  if (ok) {
    const { orders } = data

    yield put(Creators.orderListSuccess(orders))
  } else {
    const { error } = data

    yield put(Creators.orderListFailure(error))
  }
}

export function setInstallmentOrder() {

  put(Creators.orderInstallmentSuccess());
  
}

export function * registerWithdrawlOrder(api, { address_id, city_id, payment_code, payment_option_id, payment_money_change, products, cpf, sub_method, sender_hash, card_token, card_holder, installments, shipping_custom_fields }) {
  const store_id = yield select(storeId)
  const token = yield select(userToken)

  api.setHeaders({
    Authorization: `Bearer ${token}`,
    'store-id': store_id
  })
  
  const response = yield call(api.registerWithdrawlOrder, { address_id, city_id, payment_code, payment_option_id, payment_money_change, products, cpf, sub_method, sender_hash, card_token, card_holder, installments, shipping_custom_fields })
  const { ok, data } = response

  if (ok) {
    const { order } = data

    yield put(Creators.orderWithdrawlRegisterSuccess(order))
  } else {
    const { error } = data

    yield put(Creators.orderRegisterFailure(error))
  }
}

export function* getOrderPicpayDetail(api,{ orderId }) {
  const token = yield select(accessTokenStore)
  api.paymentSetHeaders({
    Authorization: `Bearer ${token}`,
  })
  
  const response = yield call(api.getOrderPicpay, { orderId });
  const { ok, data } = response;

  if (ok) {
    const { order } = data;

    yield put(Creators.orderPicpayDetailSuccess(order))

  } else {
    const { error } = data

    yield put(Creators.orderRegisterFailure(error))
  }
  
}

export function* addShippingToCart(api, { orderId, shipping, sender }) {
  const token = yield select(accessTokenStore)

  api.setHeaders({
    Authorization: `Bearer ${token}`,
  })

  const response = yield call(api.addToCartShipping, { orderId, shipping, sender })
  const { ok, data } = response

  if (ok) {
    const { ok } = data;

    yield put(Creators.setOnCartShippingSuccess(ok))

  } else {
    const { error } = data

    yield put(Creators.orderRegisterFailure(error))
  }

}