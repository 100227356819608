import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { PrimaryButton, OutlineButton } from './styles'

export default class Button extends Component {
  static propTypes = {
    color: PropTypes.oneOf(['primary', 'secondary']),
    onClick: PropTypes.func,
    style: PropTypes.any,
    disabled: PropTypes.bool
  }

  static defaultProps = {
    color: 'primary',
    style: {},
    disabled: false
  }

  render() {
    const { color, onClick, children, style, disabled } = this.props

    let buttonComponent = <PrimaryButton style={style} disabled={disabled} onClick={onClick}>{children}</PrimaryButton>
    if (color === 'secondary') {
      buttonComponent = <OutlineButton style={style} disabled={disabled} onClick={onClick}>{children}</OutlineButton>
    }

    return buttonComponent
  }
}
