import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TrashAltIcon from '@components/Icons/TrashAlt'
import DeliveryBoy from '@components/Icons/DeliveryBoy'
import Button from '@components/Button'
import { AddressCardContainer, FakeAddressCardContainer, InfoContainer, ActionContainer, AddressPrimary, AddressSecondary, ActionDivider } from './styles'
import './index.css'
import { setStep } from '../../helpers/ECEvents'

export default class AddressCard extends Component {
  static propTypes = {
    slideIn: PropTypes.bool,
    slideInOffet: PropTypes.number,
    id: PropTypes.number,
    street: PropTypes.string,
    number: PropTypes.string,
    neighborhood: PropTypes.string,
    city: PropTypes.string,
    uf: PropTypes.string,
    postcode: PropTypes.number,
    selected: PropTypes.bool,
    onDelete: PropTypes.func,
    onSelect: PropTypes.func
  }

  static defaultProps = {
    slideIn: false,
    slideInOffet: 1,
    selected: false,
    onDelete: () => { },
    onSelect: () => { }
  }

  constructor(props) {
    super(props)

    this.state = {
      hide: false,
      deleted: false,
      className: 'animated slideInLeft'
    }
  }

  componentWillMount() {
    const { slideIn } = this.props

    this.setState({
      hide: slideIn
    })
  }

  componentDidMount() {
    const { slideInOffet } = this.props
    const { hide } = this.state

    if (hide) {
      setTimeout(() => {
        this.setState({
          hide: false
        })
      }, (slideInOffet * 500))
    }
  }

  _handleOnDelete = (id) => {
    const { onDelete } = this.props

    this.setState({
      className: 'animated slideOutRight'
    })

    setTimeout(() => {
      onDelete(id)

      // Show invisible fake card
      this.setState({
        hide: true
      })

      setTimeout(() => {
        // Make invisible fake card disappear
        this.setState({
          deleted: true
        })
      }, 100)
    }, 500)
  }

  render() {
    const {
      id,
      street,
      neighborhood,
      city,
      uf,
      selected,
      onSelect,
      number,
      postcode,
    } = this.props
    const { hide, deleted, className } = this.state

    return (
      !hide ? (
        <AddressCardContainer className={className}>
          <DeliveryBoy width="35" height="35"/>

          <InfoContainer>
            <AddressPrimary>
              {street} {number && number}
            </AddressPrimary>
            <AddressSecondary>
              {neighborhood} - {city} {uf} {postcode && postcode }
            </AddressSecondary>
          </InfoContainer>

          <ActionContainer>
            <Button style={{ width: 200 }} onClick={() => {
              onSelect(id)
              setStep(1)
            }} color={selected ? 'secondary' : 'primary'}>{selected ? 'Continuar com esse' : 'Usar este'}</Button>
            <ActionDivider />
            <TrashAltIcon width={16} height={25} style={{ cursor: 'pointer' }} onClick={() => this._handleOnDelete(id)} />
          </ActionContainer>
        </AddressCardContainer>
      ) : <FakeAddressCardContainer className={deleted ? 'address-card-disappear' : ''} />
    )
  }
}
