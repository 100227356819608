import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { AllHtmlEntities } from 'html-entities'
import Button from '@components/Button'
import CartIcon from '@components/Icons/Cart'
import { Row, Col } from 'react-flexbox-grid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as BrandIcons from '@fortawesome/free-brands-svg-icons'
import * as Icons from '@fortawesome/free-solid-svg-icons'
import { Image, ProductInfoContainer, Title, Text, Description, WAButton, TelButton, ControlButton, ControlDescription, SpecialBadge, PriceCompare, DeliveryFree } from './styles'
import { floatToBRL } from '../../helpers/MoneyFormat'
import Capitalize from '../../helpers/Capitalize'
import Config from '../../config'
import NoImage from '../../assets/images/noimage.png'
import NoImageGeneric from '../../assets/images/noimage-generic.png'
import { withRouter } from 'react-router-dom'
import './styles.css'
import GAEvents from '../../helpers/GAEvents'
import { addToCart, viewProduct } from '../../helpers/ECEvents'

const htmlEntities = new AllHtmlEntities()

const AB_DESCRIPTION = `
  VENDA PROIBIDA VIA INTERNET.
  MEDICAMENTO SUJEITO A CONTROLE ESPECIAL MEDIANTE A RETENÇÃO DE RECEITUÁRIO SIMPLES DE DUAS VIAS. VÁLIDA POR 10 DIAS A CONTAR DA DATA DE SUA DATA DE EMISSÃO. RDC Nº 20/2011.
`
const GENERIC_DESCRIPTION = `
  VENDA PROIBIDA VIA INTERNET.
  MEDICAMENTO SUJEITO A CONTROLE ESPECIAL MEDIANTE RETENÇÃO DA RECEITA. PORTARIA Nº 344 - 01/02/1999 - MINISTÉRIO DA SAÚDE.
`

class ProductInfo extends Component {
  static propTypes = {
    id: PropTypes.string,
    ean: PropTypes.number,
    ms: PropTypes.number,
    name: PropTypes.string,
    model: PropTypes.string,
    presentation: PropTypes.string,
    description: PropTypes.string,
    laboratory: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number,
    quantity: PropTypes.number,
    special: PropTypes.object,
    control: PropTypes.shape({
      description: PropTypes.string,
      initials: PropTypes.string
    }),
    classification: PropTypes.string,
    hidePrice: PropTypes.bool,
    storeWANumber: PropTypes.string,
    storePhoneNumber: PropTypes.string,
    historyGoBack: PropTypes.bool,
    onAdd: PropTypes.func,
    onRegister: PropTypes.func
  }

  static defaultProps = {
    hidePrice: false,
    historyGoBack: false,
    onRegister: () => { }
  }

  componentDidMount() {
    viewProduct({ ...this.props })
  }

  mountSchemaMarkup = () => {
    const { ean, name, model, presentation, laboratory, image, price, quantity, classification } = this.props
    const { s3Bucket } = Config
    const productImage = image && image.split('/')[0] !== 'catalog' ? `${s3Bucket}${image}` : (classification === 'GENERICO' ? NoImageGeneric : NoImage)
    let availability = 'http://schema.org/InStock'

    if (quantity > 0 && quantity <= 5) {
      availability = 'http://schema.org/LimitedAvailability'
    }
    if (quantity <= 0) {
      availability = 'http://schema.org/OutOfStock'
    }

    const markup = {
      '@context': 'http://schema.org/',
      '@type': 'Product',
      name: `${Capitalize(model)} ${Capitalize(name)}`,
      description: presentation,
      brand: laboratory && laboratory.length > 0 ? {
        '@type': 'Brand',
        name: laboratory
      } : null,
      image: productImage,
      gtin: ean.toString(),
      gtin8: ean.toString(),
      sku: ean.toString(),
      offers: {
        '@type': 'Offer',
        priceCurrency: 'BRL',
        price: price.toFixed(2),
        availability
      }
    }

    return JSON.stringify(markup)
  }

  render() {
    const { id, ean, ms, categories, name, model, presentation, description, laboratory, image, price, quantity, special, control, classification, hidePrice, storeWANumber, storePhoneNumber, onAdd, onRegister, deliveryFree, storeColor } = this.props
    const { s3Bucket } = Config
    let controlId = null
    if (control) {
      controlId = control._id
    }

    const productImage = image && image.split('/')[0] !== 'catalog' ? `${s3Bucket}${image}` : (classification === 'GENERICO' ? NoImageGeneric : NoImage)
    let htmlDescription = description
    const htmlParsed = htmlEntities.decode(htmlDescription)
    if (htmlParsed.length > 0) {
      htmlDescription = htmlParsed
    }

    let waMessage = `Olá, estava vendo o * ${name} *, de valor *${special ? floatToBRL(special.price) : floatToBRL(price)}* no site.`
    const waLink = `https://wa.me/55${storeWANumber.replace(/\D+/g, '')}?text=${encodeURI(waMessage)}`

    // Change WhatsApp message if we are out of stock!
    if (quantity < 1) {
      waMessage = `Olá, quero ser avisado quando o produto * ${name} * chegar.`
    }

    const telLink = `tel:${storePhoneNumber.replace(/\D+/g, '')}`

    const onAddHandler = (id) => {
      // if (historyGoBack) {
      //   this.props.history.goBack()
      // } else {
      //   this.props.history.replace('/produtos')
      // }
      onAdd(id)
    }

    return (
      <React.Fragment>
        <Helmet>
          <script type="application/ld+json">
            {
              this.mountSchemaMarkup()
            }
          </script>
        </Helmet>

        <Row style={{ borderBottom: '1px solid #A8A8A8' }}>
          <Col xs={5} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRight: '1px solid #A8A8A8' }}>
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
              {
                deliveryFree && (controlId === 0 || controlId === null) ? (
                  <DeliveryFree storeColor={storeColor}>
                    <span>Entrega grátis!</span>
                  </DeliveryFree>
                ) : <div></div>
              }

              {
                (special && Number(special.discount.toFixed(0)) > 0) ? (
                  <SpecialBadge>
                    <span>-{special.discount.toFixed(0)}</span>%
                  </SpecialBadge>
                ) : <div></div>
              }
            </div>
            <Image src={productImage} alt={`${Capitalize(model)} ${Capitalize(name)}`} />
          </Col>

          <Col xs={7}>
            <ProductInfoContainer>
              <Title bold>{Capitalize(name)}</Title>
              {presentation && <Text style={{ marginBottom: 15 }}>Apresentação: {Capitalize(presentation)}</Text>}
              {/* {presentation ? <Text>Apresentação: {presentation}</Text> : null} */}
              {laboratory.length > 0 ? <Text>Fabricante: {Capitalize(laboratory)}</Text> : null}
              <Text>EAN: {ean}</Text>
              {ms > 0 ? <Text>Código Ministério da Saúde: {ms}</Text> : null}
              {control ? <Text>Controle: {control.description}</Text> : null}

              <div style={{ marginTop: '15%' }}>
                {
                  // We do not have stock? O.O -> :(
                  quantity <= 0 ? (
                    <span style={{ fontSize: 12, color: '#ff4646' }}>
                      <FontAwesomeIcon icon={Icons.faTimesCircle} color="#ff4646" style={{ marginRight: 5 }} />
                      Sem estoque
                    </span>
                  ) : null
                }
                {
                  // Our last products, JUST BUY IT!
                  quantity > 0 && quantity <= 5 ? (
                    <span style={{ fontSize: 12, color: '#ffa346' }}>
                      <FontAwesomeIcon icon={Icons.faExclamationCircle} color="#ffa346" style={{ marginRight: 5 }} />
                      Restam só {quantity} {`unidade${quantity > 1 ? 's' : ''}`}
                    </span>
                  ) : null
                }
                {
                  // We are good \o/
                  quantity > 5 ? (
                    <span style={{ fontSize: 12, color: '#00bf91' }}>
                      <FontAwesomeIcon icon={Icons.faCheckCircle} color="#00bf91" style={{ marginRight: 5 }} />
                      Em estoque
                    </span>
                  ) : null
                }
              </div>

              {
                !hidePrice ? (
                  (special && Number(special.discount.toFixed(0)) > 0) ? (
                    <PriceCompare>
                      <Text style={{ color: '#A8A8A8', textDecoration: 'line-through', fontSize: 18 }}>{floatToBRL(price)}</Text>
                      <Text bold style={{ marginTop: 5, color: '#00BF91', fontSize: 22 }}>{floatToBRL(special.price)}</Text>
                    </PriceCompare>
                  ) : (
                    <Text bold style={{ marginTop: 20, color: '#00BF91', fontSize: 22 }}>{floatToBRL(price)} </Text>
                  )
                ) : null
              }

              {
                !hidePrice ? (
                  control === null ? (
                    quantity > 0 ? (
                      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 15, marginBottom: 15 }}>
                        <Button style={{ display: 'flex', flexDirection: 'row', width: '60%' }} onClick={() => {
                          GAEvents({ category: 'Shop', action: 'Click em Adicionar ao Carrinho pelo modal do produto', label: `Produto : ${model} - ${id}` })
                          addToCart({
                            ean,
                            model,
                            categories,
                            laboratory,
                            price,
                            quantity,
                            view: 'detail view'
                          })
                          onAddHandler(id)
                        }}>
                          <CartIcon width={25} height={25} />
                          <span style={{ fontFamily: 'Gilmer', fontSize: 15, alignSelf: 'center' }}>Adicionar ao carrinho</span>
                        </Button>

                        <WAButton href={waLink} target="_blank" style={{ marginLeft: 10, width: 50 }} onClick={() => {
                          GAEvents({ category: 'Contato', action: 'Clique no botão Whatsapp pelo modal do produto', label: `Produto : ${model} - ${id}` })
                        }} >
                          <FontAwesomeIcon icon={BrandIcons.faWhatsapp} size="2x" />
                        </WAButton>

                        <TelButton href={telLink} target="_blank" style={{ marginLeft: 10, width: 40, fontSize: 18 }} onClick={() => {
                          GAEvents({ category: 'Contato', action: 'Clique no botão Telefone pelo modal do produto', label: 'ProductInfo' })
                        }}>
                          <FontAwesomeIcon icon={Icons.faPhone} rotation={90} />
                        </TelButton>
                      </div>
                    ) : (
                      <WAButton href={waLink} target="_blank" style={{ marginTop: 15, marginBottom: 15, width: '60%' }}>
                        <FontAwesomeIcon icon={BrandIcons.faWhatsapp} size="2x" />
                        <span style={{ fontFamily: 'Gilmer-Bold', fontSize: 15, alignSelf: 'center', marginLeft: 10 }}>Avise-me quando chegar</span>
                      </WAButton>
                    )
                  ) : (
                    <ControlButton onClick={() => {
                      GAEvents({ category: 'Contato', action: 'Clique no botão VENDA EXCLUSIVA NAS LOJAS FÍSICAS pelo modal do produto', label: `Produto : ${model} - ${id}` })
                    }} >
                      VENDA EXCLUSIVA NAS LOJAS FÍSICAS
                    </ControlButton>
                  )
                ) : (
                  <Button style={{ margin: 'auto 0', justifySelf: 'flex-end', width: '70%' }} onClick={() => {
                    onRegister()
                    GAEvents({ category: 'Login', action: 'Clique no botão Cadastre-se para ver os preços pelo modal do produto', label: 'ProductInfo' })
                  }}>
                    <span style={{ fontFamily: 'Gilmer', fontSize: 15, alignSelf: 'center' }}>Cadastre-se para ver os preços</span>
                  </Button>
                )
              }
            </ProductInfoContainer>
          </Col>
        </Row>

        <Row>
          <Col xs={12} style={{ padding: 20 }}>
            {
              control ? (
                <ControlDescription>
                  {
                    control.initials.toLowerCase() === 'ab' ? AB_DESCRIPTION : GENERIC_DESCRIPTION
                  }
                </ControlDescription>
              ) : null
            }

            <Description dangerouslySetInnerHTML={{ __html: htmlDescription }} />
            <div id="standoutDivAutomatico" />
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

export default withRouter(ProductInfo)