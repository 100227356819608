import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: #FFFFFF;
  box-shadow: 2px 4px 10px rgba(0,0,0, 0.2);
  height: 160px;
  padding: 12px;
`
export const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  cursor: pointer;
`
export const Text = styled.h2`
  font-family: ${({ bold }) => `Gilmer-${bold ? 'Bold' : 'Light'}`};
  font-size: ${({ fontSize }) => `${fontSize || '13'}px`};
  color: ${({ color }) => color || '#707070'};
  line-height: 15px;
`
export const TextLink = styled(Link)`
  display: block;
  font-family: ${({ bold }) => `Gilmer-${bold ? 'Bold' : 'Light'}`};
  font-size: ${({ fontSize }) => `${fontSize || '13'}px`};
  color: ${({ color }) => color || '#707070'};
  line-height: 18px;
`
export const Image = styled(LazyLoadImage)`
  max-width: 98px;
  max-height: 92px;
  z-index: 1;
  margin-left: 10px;
`