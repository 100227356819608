import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import * as Icons from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingCircle from '@components/Icons/LoadingCircle'
import PrettySelect from '@components/PrettySelect'
import Body from '@components/Body'
import { SelectableDropdown, SelectableDropdownItem } from '@components/SelectableDropdown'
import Select from '@components/Select'
import CardIcon from '@components/Icons/Card'
import MoneyIcon from '@components/Icons/DolarSign'
import WalletIcon from '@components/Icons/Wallet'
import LoadingMoney from '@components/Icons/LoadingMoney'
import Cart from '@components/Cart'
import CompleteCheckout from '@components/CompleteCheckout'
import CurrencyInput from 'react-currency-input'
import { Formik, Form } from 'formik'
import { Creators } from '../../actions'
import { CreateStoneTokenException, getToken } from '../../helpers/StoneToken'
import ErrorCodes from '../../helpers/ErrorCodes'
import CpfValidator from '../../helpers/CpfValidator'
import { CreateCardToken, GetCardBrand, GetInstallments, CreateCardTokenException } from '../../helpers/PagSeguroWrapper'
import { floatToBRL } from '../../helpers/MoneyFormat'
import PagSeguroBanner from '../../assets/images/pagseguro_banner.gif'
import PicPayBanner from '../../assets/images/Banner-PicPay.png'
import PixIcon from '../../assets/images/pixicon.png'
import PixLogo from '../../assets/images/pixLogo.png'
import { BRow, BCol, Input, InputMask, Title, SubTitle, ErrorsContainer, Error, PicPayContainer, PicPayInfo, PicPayText, PicPayContainerQRcode, HealthInsuranceContainer, InputContainer, CopyInput } from './styles'
import './index.css'
import GAEvents from '../../helpers/GAEvents'
import { setStep } from '../../helpers/ECEvents'
// import productsApi from '../../services/ProductsAPI'
import { io } from 'socket.io-client'
import InformationIcon from '../../assets/images/informacoes.png'
const { REACT_APP_WEBHOOK_API } = process.env

class Checkout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      onlinePaymentSelected: false,
      stonePaymentSelected: false,
      ticketStonePaymentSelected : false,
      deliveryPaymentSelected: false,
      cardMethodSelected: false,
      healthInsuranceSelected: false,
      moneyMethodSelected: false,
      convMethodSelected: false,
      moneyChange: false,
      moneyChangeValue: 0,
      moneyChangeValueMasked: '',
      cpfCheckout: false,
      cpfCheckoutValid: false,
      cpfCheckoutValue: '',
      selectedConv: 0,
      hasCartMethod: false,
      hasMoneyMethod: false,
      hasCovenantMethod: false,
      hasGatewayMethod: false,
      processingOrder: false,
      paymentMethods: {
        credit: ['MasterCard', 'Visa', 'American', 'HiperCard'],
        debit: ['Elo', 'MasterCard'],
        gateway: []
      },
      paymentMethodOption: '',
      pagseguroHash: null,
      gatewaySession: null,
      onlinePaymentInputs: {
        cardNumber: '',
        cardBrand: '',
        cardExpire: '',
        cardCVC: '',
        cardName: '',
        cardCPF: ''
      },
      stoneInstallmentSelected: '',
      onlineStonePaymentInputs: {
        number: '',
        label: '',
        cvv: '',
        dateStone: '',
        holder_name: '',
        cpf: '',
      },
      ticketStonePaymentInputs:{
        cpf_ticket: '',
      },
      errors: [],
      hasActiveAddress: false,
      shouldCompleteCheckout: false,
      showCompleteAddress: false,
      installmentOnCreditCard: [],
      selectAmount: '',
      installmentOnCreditCardSelect: {
        quantity: '',
        installmentAmount: '',
        totalAmount: '',
        interestFree: null
      },
      pagSeguroPaymentMethod: false,
      picPayPaymentMethod: false,
      stonePaymentMethod: false,
      ticketStonePaymentMethod : false,
      onlinePaymentMethods: false,
      picPayPaymentSelected: false,
      pixPaymentSelected: false,
      processingPicPay: false,
      processingTicketStone: false,
      processingPix: false,
      picpayData: null,
      pixData: null,
      inputsOnline: {
        cardCPFValidate: null
      },
      healthInsurance: '',
      copySuccess: false,
      pixOrderId: null,
      sendShipping: false,
      shipping_sender: null
    }

    this.qrCodeInput = React.createRef()

    this.socket = io(REACT_APP_WEBHOOK_API, {
      transports: ['websocket'],
      reconnectionAttempts: 3,
    })

    this.socket.on('status', () => {
      this.props.history.replace(`/pedido/${this.props.order.order_id}`)
    })
  }

  componentWillMount() {
    const { user, currentAddress, currentOrderShipping } = this.props

    // There's something missing on user info?
    let completeCheckout = false
    if (user && currentAddress) {
      completeCheckout = (!user.birthdate || user.birthdate.length === 0 || user.telephone.length === 0) || (!currentAddress.postcode || currentAddress.postcode.length === 0)
    }

    // Load pagseguro script
    if (!window.pagseguroLoaded) {
      window.loadPagSeguro()
    }

    this.setState({
      hasActiveAddress: (currentAddress || currentOrderShipping) ? true : false,
      shouldCompleteCheckout: completeCheckout
    })
  }

  componentWillReceiveProps(newProps) {

    const {
      store,
      history,
      clearCartProducts,
      attempGetOrderPicpay,
      attemptOrderInstallment,
      attemptSetShippingToCart,
      attemptGetPaymentGatewaySession,
    } = this.props

    const {
      fetching,
      error,
      paymentMethods,
      orderFetching,
      orderError,
      order,
      gatewaySession,
      currentAddress,
      orderPipcayDetail,
      currentOrderShipping
    } = newProps

    const { processingPicPay, processingPix, processingTicketStone, shipping_sender } = this.state;

    let newState = Object.assign({}, this.state)
    // Does we have active address?
    newState.hasActiveAddress = (currentAddress || currentOrderShipping) ? true : false

    //newState.onlinePaymentMethods = (currentAddress.not_deliverable && newState.picPayPaymentMethod) ? true : false

    if (store) {
      const { settings } = store
      const { config_shipping_courier, config_best_shipping } = settings

      newState.shipping_sender = config_shipping_courier && !config_best_shipping ? 'courier' :
      config_best_shipping && !config_shipping_courier ? 'bestshipping' : null

    }

    if (!fetching && paymentMethods !== null) {
      newState.paymentMethods.credit = paymentMethods.filter(p => p.type === 'credit')
      newState.paymentMethods.debit = paymentMethods.filter(p => p.type === 'debit')
      newState.paymentMethods.gateway = paymentMethods.filter(p => p.type === 'gateway')

      newState.hasCartMethod = paymentMethods.filter(p => p.type === 'credit' || p.type === 'debit').length > 0
      newState.hasMoneyMethod = paymentMethods.filter(p => p.type === 'money').length > 0
      newState.hasCovenantMethod = paymentMethods.filter(p => p.type === 'covenant').length > 0
      newState.hasGatewayMethod = paymentMethods.filter(p => p.type === 'gateway' &&  p.name.toLowerCase() === 'pagseguro').length > 0
      newState.onlinePaymentMethods = paymentMethods.filter(p => p.type === 'gateway').length > 0

      newState.pagSeguroPaymentMethod = paymentMethods.filter(p => p.type === 'gateway' && p.name.toLowerCase() === 'pagseguro')
      newState.stonePaymentMethod = paymentMethods.filter(p => p.type === 'gateway' && p.name.toLowerCase() === 'stone')
      newState.picPayPaymentMethod = paymentMethods.filter(p => p.type === 'gateway' && p.name.toLowerCase() === 'picpay').length > 0
      newState.pixPaymentMethod = paymentMethods.filter(p => p.type === 'gateway' && p.name.toLowerCase() === 'pix').length > 0

      newState.ticketStonePaymentMethod = paymentMethods.filter( p => p.type === 'ticket').length > 0 

      // If we do have gateway method available, let's request session for it
      if (!fetching && !error && newState.hasGatewayMethod && newState.gatewaySession === null && gatewaySession === null) {
        const [{ option_id }] = newState.pagSeguroPaymentMethod

        attemptGetPaymentGatewaySession({ payment_option_id: option_id })
      }

      // Gateway session received
      if (!fetching && !error && newState.gatewaySession === null && gatewaySession !== null) {
        newState.gatewaySession = gatewaySession

        // Set PagSeguro session
        window._PagSeguroDirectPayment.setSessionId(gatewaySession)

        // Get PagSeguro hash
        window._PagSeguroDirectPayment.onSenderHashReady((response) => {
          if (response && response.status !== 'error') {
            this.setState({
              pagseguroHash: response.senderHash
            })
          }
        })
      }
    }

    // We got an error :/
    if (!orderFetching && orderError) {
      newState.processingOrder = false
      newState.processingPicPay = false
      newState.processingPix = false
      newState.processingTicketStone = false

      if (newState.errors.indexOf(orderError) === -1) {
        newState.errors.push(orderError)
      }
    }

    if (order && currentOrderShipping && !newState.sendShipping && !orderError && shipping_sender) {
      attemptSetShippingToCart(order.order_id, currentOrderShipping, shipping_sender)
      newState.sendShipping = true
    }

    if (newState.processingOrder && !orderFetching && order !== null && order !== undefined) {
      this.socket.emit('newOrder', order.order_id)


      if (processingTicketStone){
        console.log(order)

        const { ticketStoneReturn } = order

        if(ticketStoneReturn){
          newState.ticketStoneData = ticketStoneReturn

          console.log(ticketStoneReturn)
        }
      }

      if (processingPix) {
        const { pixReturn } = order

        if (pixReturn) {
          newState.pixData = pixReturn
        }

      }

      if (processingPicPay) {
        const { picpayReturn } = order;

        if (picpayReturn) {
          newState.picpayData = picpayReturn
        }

        if (!orderPipcayDetail) {
          attempGetOrderPicpay({
            orderId: order.order_id
          })
        }
      }

      if (!processingPix && !processingPicPay && !processingTicketStone) {
        newState.processingOrder = false
        clearCartProducts()
      }

    }

    if (newState.processingOrder && !orderFetching && orderPipcayDetail !== null) {

      if (orderPipcayDetail.status === 'paid') {
        newState.processingOrder = false
        newState.processingPicPay = false

      } else {
        setTimeout(() => {
          attempGetOrderPicpay({
            orderId: orderPipcayDetail.order_id
          })
        }, 3000);
      }
    }

    if (newState !== this.state) {
      this.setState(newState, () => {
        if (!newState.processingOrder && order !== null) {
          history.replace(`/pedido/${order.order_id}`)
        }
      })
    }

    if (newProps.cart !== this.props.cart) {


      if (this.state.onlinePaymentSelected ) {
        setTimeout(() => {
          this.getInstallmentsOnCreditCard();
        }, 500);

        setTimeout(() => {
          const { installmentOnCreditCard, installmentOnCreditCardSelect } = this.state
          const installment = installmentOnCreditCard.find(i => i.quantity === installmentOnCreditCardSelect.quantity);

          if (installment) {
            const { attemptOrderInstallment } = this.props;
            attemptOrderInstallment(installment.totalAmount)
            this.setState(state => ({
              ...state,
              selectAmount: (state.selectAmount !== installment.totalAmount ? '' : state.selectAmount),
              installmentOnCreditCardSelect: {
                quantity: installmentOnCreditCardSelect.quantity,
                installmentAmount: installment.installmentAmount,
                totalAmount: installment.totalAmount
              }
            }))

          } else {
            attemptOrderInstallment(null)
            this.setState(state => ({
              ...state,
              selectAmount: '',
              installmentOnCreditCardSelect: {
                quantity: '',
                installmentAmount: '',
                totalAmount: ''
              }
            }))
          }
        }, 1500)
      }

      if (this.state.stonePaymentSelected) {

        setTimeout(() => {
          this.getInstallmentsOnCreditCard();
        }, 500);

        setTimeout(() => {
          
          const { installmentOnCreditCard, installmentOnCreditCardSelect } = this.state
          const installment = installmentOnCreditCard.find(i => i.quantity === installmentOnCreditCardSelect.quantity);

          if (installment) {
            const { attemptOrderInstallment } = this.props;
            attemptOrderInstallment(installment.totalAmount)
            this.setState(state => ({
              ...state,
              selectAmount: (state.selectAmount !== installment.totalAmount ? '' : state.selectAmount),
              installmentOnCreditCardSelect: {
                quantity: installmentOnCreditCardSelect.quantity,
                installmentAmount: installment.installmentAmount,
                totalAmount: installment.totalAmount
              }
            }))

          } else {
            attemptOrderInstallment(null)
            this.setState(state => ({
              ...state,
              selectAmount: '',
              installmentOnCreditCardSelect: {
                quantity: '',
                installmentAmount: '',
                totalAmount: ''
              }
            }))
          }
        }, 1500)
      }
    }

    
    // if (order) {
    //   if (!orderPipcayDetail || orderPipcayDetail.status === 3) {
    //     const { products } = this.props.cart
    //     const { store } = this.props
    //     const shipping = order.total.find((value) => value.code === 'shipping')
    //     Promise.all(products.map(async product => {
    //       const detail = await productsApi.getProduct({ store_id: store.store_id, slug: product.slug })
    //       return detail
    //     })).then(completed => {
    //       const mapped = completed.map(({ data }, index) => {
    //         const found = products.find(original => original.slug === data.product.url_slug)
    //         return {
    //           ean: data.product.ean,
    //           model: found.model,
    //           price: found.price,
    //           quantity: found.quantity,
    //           categories: data.product.categories,
    //           manufacturer: data.product.manufacturer
    //         }
    //       })
    //       purchase({
    //         id: order.order.order_id,
    //         affiliation: order.order.store_name,
    //         revenue: Number(order.order.total).toFixed(2),
    //         tax: 0,
    //         shipping: Number(shipping.value).toFixed(2)
    //       }, mapped)
    //     })
    //   }
    // }
  }


  onChangeField = ({ target: { name, value } }) => {
    this.setState(state => ({
      ...state,
      [name]: value
    }), () => {
      // Validate fields
      this.validateCpf()
    })
  }

  // refat
  validateInputsOnline = (name, value) => {
    let errorMessage = false
    const Bvalid = CpfValidator(value)

    if (name === 'cardCPF')

      if (!Bvalid) {
        errorMessage = 'Por favor informe um CPF válido.'
      } else {
        errorMessage = false
      }

    this.setState(state => ({
      ...state,
      inputsOnline: {
        ...state.inputsOnline,
        cardCPFValidate: errorMessage
      }

    }))
  }


  onChangeStonePaymentField = ({target: { name, value }}) => {
    this.setState(state => ({
      ...state,
      
      onlineStonePaymentInputs: {
        ...state.onlineStonePaymentInputs,
        [name]: value
      }
    }))
  }

  onChangeTicketStonePaymentInputs = ({target : {name, value}}) => {
    this.setState(state => ({
      ...state,

      ticketStonePaymentInputs: {
        ...state.ticketStonePaymentInputs,
        [name] : value
      }
    }))
  }



  onChangeOnlinePaymentField = ({ target: { name, value } }) => {

    if (name === 'cardNumber') {
      const cardNumber = value.replace(/\D/g, '')
      this.getInstallmentsOnCreditCard()

      GetCardBrand(cardNumber).then(brand => {
        const { onlinePaymentInputs } = this.state
        const { cardBrand } = onlinePaymentInputs

        if (cardBrand !== brand) {
          this.setState(state => ({
            ...state,
            onlinePaymentInputs: {
              ...state.onlinePaymentInputs,
              cardBrand: brand
            }
          }))
          this.getInstallmentsOnCreditCard()
        }
      })
    }

    this.setState(state => ({
      ...state,
      onlinePaymentInputs: {
        ...state.onlinePaymentInputs,
        [name]: value
      }
    }), () => {
      this.validateInputsOnline(name, value)
    })
  }

  onChangeCurrencyField = (ev, maskedValue, floatValue) => {
    this.setState(state => ({
      ...state,
      moneyChangeValue: floatValue,
      moneyChangeValueMasked: maskedValue
    }))
  }

  onChangePaymentMethod = (current) => {
    const { paymentMethods } = this.props

    let paymentMethodOption = ''
    if (current === 'moneyMethodSelected' && !this.state[current]) {
      const moneyMethod = paymentMethods.find(p => p.type === 'money')
      paymentMethodOption = moneyMethod ? moneyMethod.option_id : ''
    }
    if (current === 'onlinePaymentSelected' && !this.state[current]) {
      const gatewayMethod = paymentMethods.find(p => p.type === 'gateway' && p.name.toLowerCase() === 'pagseguro')
      paymentMethodOption = gatewayMethod ? gatewayMethod.option_id : ''
    }
    if (current === 'stonePaymentSelected' && !this.state[current]) {
      const gatewayMethod = paymentMethods.find(p => p.type === 'gateway' && p.name.toLowerCase() === 'stone')
      paymentMethodOption = gatewayMethod ? gatewayMethod.option_id : ''
    }
    if (current === 'ticketStonePaymentSelected' && !this.state[current]) {
      const ticketMethod = paymentMethods.find(p => p.type === 'ticket')
      paymentMethodOption = ticketMethod ? ticketMethod.option_id : ''
    }
    if (current === 'picPayPaymentSelected' && !this.state[current]) {
      const gatewayMethod = paymentMethods.find(p => p.type === 'gateway' && p.name.toLowerCase() === 'picpay')
      paymentMethodOption = gatewayMethod ? gatewayMethod.option_id : ''
    }
    if (current === 'pixPaymentSelected' && !this.state[current]) {
      const gatewayMethod = paymentMethods.find(p => p.type === 'gateway' && p.name.toLowerCase() === 'pix')
      paymentMethodOption = gatewayMethod ? gatewayMethod.option_id : ''
    }
    if (current === 'healthInsuranceSelected' && !this.state[current]) {
      const gatewayMethod = paymentMethods.find(p => p.type === 'covenant')
      paymentMethodOption = gatewayMethod ? gatewayMethod.option_id : ''
    }

    this.setState(state => ({
      ...state,
      deliveryPaymentSelected: false,
      onlinePaymentSelected: false,
      stonePaymentSelected: false,
      cardMethodSelected: false,
      moneyMethodSelected: false,
      convMethodSelected: false,
      moneyChange: false,
      picPayPaymentSelected: false,
      selectedConv: 0,
      [current]: !state[current],
      paymentMethodOption,
      installmentOnCreditCard: [],
    }), () => {
      // Did we select any of delivery payment methods?
      this.setState(state => ({
        deliveryPaymentSelected: state.cardMethodSelected || state.moneyMethodSelected
      }))
    })
  }

  // Ao refatorar pro novo e-commerce, deixar os campos válidos como estados, para facilitar a estilização dos campos.
  validateFields = () => {
    const { store } = this.props
    const { settings } = store
    const { config_cpf_checkout } = settings

    const {
      cpfCheckout, // este
      cpfCheckoutValid,
      paymentMethodOption,
      onlinePaymentSelected, 
      stonePaymentSelected,
      ticketStonePaymentSelected,
      onlinePaymentInputs,
      onlineStonePaymentInputs,
      ticketStonePaymentInputs,
      installmentOnCreditCardSelect,
      picPayPaymentSelected,
      healthInsuranceSelected,
      healthInsurance,
      pixPaymentSelected
    } = this.state

    const {
      number, dateStone, cvv, holder_name, cpf
    } = onlineStonePaymentInputs


    const {
      cardNumber,
      cardBrand,
      cardExpire,
      cardCVC,
      cardName,
      cardCPF
    } = onlinePaymentInputs

    const { cpf_ticket } = ticketStonePaymentInputs

    if(stonePaymentSelected){
      const validateCardCPF = CpfValidator(cpf)
      const cardNumberOnlyDigits = /^\d+$/.test(number.replace(/\s/g, ''))
      const cardExpireOnlyDigits = /^\d+$/.test(dateStone.replace(/\s/g, '').replace('/', ''))
      const cardNameValid = /^[a-záàâãéèêíïóôõöúçñ]([-']?[a-záàâãéèêíïóôõöúçñ]+)*( [a-záàâãéèêíïóôõöúçñ]([-']?[a-záàâãéèêíïóôõöúçñ]+)*)+\s?$/i.test(holder_name)
      const cvvIsValid =  cvv.length >= 3 && cvv.length <=4

      if (cardNumberOnlyDigits && cardExpireOnlyDigits && cvvIsValid && cardNameValid && validateCardCPF /*&& installmentOnCreditCardSelect.quantity > 0*/) {
        return true
      }
      return false
    }

    if (onlinePaymentSelected) {
      const validateCardCPF = CpfValidator(cardCPF)
      const cardNumberOnlyDigits = /^\d+$/.test(cardNumber.replace(/\s/g, ''))
      const cardExpireOnlyDigits = /^\d+$/.test(cardExpire.replace(/\s/g, '').replace('/', ''))
      const cardNameValid = /^[a-záàâãéèêíïóôõöúçñ]([-']?[a-záàâãéèêíïóôõöúçñ]+)*( [a-záàâãéèêíïóôõöúçñ]([-']?[a-záàâãéèêíïóôõöúçñ]+)*)+\s?$/i.test(cardName)

      if (cardNumberOnlyDigits && cardBrand.length > 0 && cardExpireOnlyDigits && cardCVC.length > 0 && cardNameValid && validateCardCPF && installmentOnCreditCardSelect.quantity > 0) {
        return true
      }

      return false
    } else {
      if (picPayPaymentSelected) return true
      if(ticketStonePaymentSelected) {
        const ticketCpfValid = CpfValidator(cpf_ticket)
        if(ticketCpfValid){
          return true
        } else {
          return false
        }
      }

      if (pixPaymentSelected) {
        if (cpfCheckoutValid) {
          return true
        } else {
          return false
        }
      }

      if (paymentMethodOption === '') return false

      if (healthInsuranceSelected) {
        if (!healthInsurance.length > 0 || !cpfCheckoutValid) {
          return false
        } else {
          return true
        }
      }

      // Do we need CPF?
      if (cpfCheckout || (config_cpf_checkout && config_cpf_checkout === true)) {
        // Is it CPF valid?
        return cpfCheckoutValid
      }

      return true
    }
  }

  validateCpf = () => {
    const { cpfCheckoutValue, cpfCheckoutValid } = this.state
    const bValid = CpfValidator(cpfCheckoutValue)

    if (bValid !== cpfCheckoutValid) {
      this.setState({
        cpfCheckoutValid: bValid
      })
    }
  }

  checkout = () => {
    const { currentAddress, products, currentOrderShipping } = this.props
    const { onlinePaymentSelected, shouldCompleteCheckout, stonePaymentSelected } = this.state

    if (currentAddress && products) {
      // Should we confirm some data before process the checkout?
      if (onlinePaymentSelected && shouldCompleteCheckout) {
        // Dounce state

        setTimeout(() => {
          this.setState({
            showCompleteAddress: true
          })
        }, 500)
      }
      else {
        if (stonePaymentSelected && shouldCompleteCheckout) {
          // Dounce state
          return setTimeout(() => {
            this.setState({
              showCompleteAddress: true
            })
          }, 500)
        }
          setTimeout(() => {
            this.setState({
              processingOrder: true,
              errors: []
            })
          }, 500)
      

        // Kinda of debounce
        setTimeout(() => {
          this.processCheckout()
        }, 2000)


      }
    }

    if (currentOrderShipping && products) {
      if (onlinePaymentSelected && shouldCompleteCheckout) {

        setTimeout(() => {
          this.setState({
            showCompleteAddress: true
          })
        }, 500)

      } else {
        setTimeout(() => {
          this.setState({
            processingOrder: true,
            errors: []
          })
        }, 500)

        setTimeout(() => {
          this.processCheckout()
        }, 2000)
      }
    }
  }

  finishCheckoutCompletion = ({ address, customer }) => {
    setTimeout(() => {
      this.setState({
        processingOrder: true,
        errors: [],
        showCompleteAddress: false,
        shouldCompleteCheckout: false
      }, () => {
        // Kinda of debounce
        setTimeout(() => {
          this.processCheckout()
        }, 2000)
      })
    }, 500)
  }

  processCheckout = async () => {
    const { currentAddress, products, deliveries, attemptRegisterOrder, currentOrderShipping, currentShippingAddress, setGlobalCart } = this.props
    const {
      moneyChange,
      moneyChangeValue,
      paymentMethodOption,
      cpfCheckoutValue,
      pagseguroHash,
      onlinePaymentSelected,
      stonePaymentSelected,
      onlinePaymentInputs,
      installmentOnCreditCardSelect,
      picPayPaymentSelected,
      healthInsuranceSelected,
      pixPaymentSelected,
      ticketStonePaymentSelected,
      healthInsurance 
    } = this.state

    // Deliveries
    const { delivery_fee, free_from } = deliveries[0]
    const deliveryFee = Number(delivery_fee)
    const deliveryFreeFrom = Number(free_from)
    const _moneyChange = moneyChange ? moneyChangeValue : 0

    try {
      const { not_deliverable } = currentAddress ? currentAddress : false

      // Order total amount
      let orderTotal = 0

      // Initial checkout info
      let obj = {
        address_id: currentShippingAddress ? currentShippingAddress.id : currentAddress ? currentAddress.id : null,
        payment_code: 'mypharma',
        payment_option_id: paymentMethodOption,
        payment_money_change: _moneyChange,
        sub_method: '',
        card_token: '',
        sender_hash: '',
        card_holder: '',
        installments: {
          quantity: ''
        },
        cpf: cpfCheckoutValue,
        delivery_mode: '',
        shipping_order: null,
        products: products.map(p => {
          // Increment order total
          orderTotal += (Number(p.price.toFixed(2)) * p.quantity)

          return {
            product_id: p.id,
            quantity: p.quantity
          }
        })
      }

      // Not free delivery?
      if (orderTotal < deliveryFreeFrom || deliveryFreeFrom === 0) {
        if (!not_deliverable) orderTotal += currentOrderShipping ? Number(currentOrderShipping.price) : deliveryFee
      }

      // Are we processing on online payment checkout?
      if (onlinePaymentSelected) {
        const { cardName, cardNumber, cardBrand, cardCVC, cardExpire, cardCPF } = onlinePaymentInputs
        const cardToken = await CreateCardToken({
          cardNumber,
          cardBrand,
          cardCVC,
          cardExpire
        })

        // Parse order total
        orderTotal = Number(orderTotal).toFixed(2)

        const { quantity, installmentAmount } = installmentOnCreditCardSelect;
        let paymentCustomField = [];
        paymentCustomField.push(installmentOnCreditCardSelect);

        // Setup pagseguro info
        obj.payment_code = 'pagseguro'
        obj.sub_method = 'creditCard'
        obj.card_token = cardToken
        obj.sender_hash = pagseguroHash
        // numerodeparcelas
        obj.payment_custom_field = JSON.stringify(paymentCustomField)
        obj.cpf = cardCPF
        obj.card_holder = cardName
        obj.installments = {
          quantity: quantity,
          amount: installmentAmount,
          noInterestInstallmentQuantity: 2
        }
      }

      if (healthInsuranceSelected) {
        obj.health_insurance = healthInsurance
        obj.payment_code = 'convenio'
      }

      // Are we processing on picpay payment checkout?
      if (picPayPaymentSelected) {
        obj.payment_code = 'picpay'
        obj.sub_method = 'Picpay'
        orderTotal = Number(orderTotal).toFixed(2)

        this.setState({
          processingPicPay: true,
        })
      }

      if (pixPaymentSelected) {
        obj.payment_code = 'pix'
        obj.sub_method = 'Pix'
        orderTotal = Number(orderTotal).toFixed(2)

        this.setState({
          ...this.state,
          processingPix: true,
        })
      }

      if(ticketStonePaymentSelected) {
        const { ticketStonePaymentInputs } = this.state
        const {cpf_ticket} = ticketStonePaymentInputs
        obj.payment_code = "ticket"
        obj.sub_method = "Ticket Stone"
        obj.cpf = cpf_ticket
        
        this.setState({
          ...this.state,
          processingTicketStone: true
        })
      }

      if (currentOrderShipping) {

        obj.delivery_mode = 'delivery_company'
        obj.shipping_order = currentOrderShipping
      }

      if(stonePaymentSelected){
        const { stoneInstallmentSelected} = this.state
        obj.payment_code = "stone"
        obj.cpf = this.state.onlineStonePaymentInputs.cpf
        const res = await getToken(this.state.onlineStonePaymentInputs)
        obj.card_token = res.data.id
        
        if(stoneInstallmentSelected === ''){
          obj.installments = {
            quantity: 1
          }} else {
            obj.installments ={
              quantity: stoneInstallmentSelected
          }
        }
      }
      
      setGlobalCart([], 'YES')
      attemptRegisterOrder(obj)

    } catch (ex) {

      const { errors } = this.state
      // criar instancias de erro
      if (ex instanceof CreateCardTokenException) {

        ex.errors.forEach(err => {
          let error_name = err.error.replace(/ /g, '_')
          if (err.code === 30400) error_name = 'invalid_creditcard'
          if (errors.indexOf(error_name) === -1) {
            errors.push(error_name)
          }
        })
      } else if(ex instanceof CreateStoneTokenException){
        const { errors } = this.state
        errors.push(ex.errors)
      } else {
        alert(ex)
      }

      this.setState({
        processingOrder: false,
        processingPicPay: false,
        processingTicketStone: false,
        processingPix: false,
        errors
      })
    }
  }
  getInstallmentsOnCreditCard = async () => {

    const { productsCupom, deliveries, currentAddress, currentOrderShipping } = this.props
    const { onlinePaymentSelected, onlinePaymentInputs } = this.state
    const { not_deliverable } = currentAddress ? currentAddress : false
    const { delivery_fee, free_from } = deliveries[0]
    const deliveryFee = Number(delivery_fee)
    const deliveryFreeFrom = Number(free_from)

    try {
      let orderTotal = 0
      productsCupom.forEach(p => orderTotal += (Number(p.price.toFixed(2)) * p.quantity))

      if (orderTotal < deliveryFreeFrom || deliveryFreeFrom === 0) {
        if (!not_deliverable && !currentOrderShipping) orderTotal += deliveryFee
      }

      if (currentOrderShipping && !not_deliverable) {
        orderTotal += Number(currentOrderShipping.price)
      }

      if (onlinePaymentSelected) {
        const { cardNumber, cardBrand } = onlinePaymentInputs;

        if (cardNumber && cardBrand) {
          orderTotal = Number(orderTotal).toFixed(2)
          const installments = await GetInstallments({
            amount: orderTotal,
            brand: cardBrand,
            maxInstallmentNoInterest: 2
          })
          this.setState(state => ({
            ...state,
            installmentOnCreditCard: installments,
          }))
        }
      }
    } catch (ex) {
      const { errors } = this.state
      if (ex instanceof CreateCardTokenException) {

        ex.errors.forEach(err => {
          let error_name = err.error.replace(/ /g, '_')
          if (err.code === 30400) error_name = 'invalid_creditcard'

          if (errors.indexOf(error_name) === -1) {
            errors.push(error_name)
          }
        })
      } else {
        alert(ex)
      }
    }
  }

  setStoneInstallmentOnOrder = ({target: { value }}) => {
    if(!value || value === '') value = 1; else {
      this.setState(state => ({
        ...state,
        stoneInstallmentSelected: value
      }))
    }
    

  }
  setInstallmentOnOrder = ({ target: { value } }) => {
    const { installmentOnCreditCard } = this.state
    const { attemptOrderInstallment } = this.props;

    if (!value) value = 1;
    const installments = installmentOnCreditCard
      .find(installment => installment.quantity === Number(value))

    attemptOrderInstallment(installments.totalAmount)

    this.setState(state => ({
      ...state,
      selectAmount: installments.totalAmount,
      installmentOnCreditCardSelect: {
        quantity: installments.quantity,
        installmentAmount: installments.installmentAmount,
        totalAmount: installments.totalAmount,
        interestFree: installments.interestFree
      }
    }))
  };


  _renderCpfForm = () => {
    const { store } = this.props
    const { settings } = store
    const { config_cpf_checkout } = settings
    const { cpfCheckout, cpfCheckoutValue, cpfCheckoutValid } = this.state

    const handleCpfCheckoutChangeOption = ({ target: { value } }) => {
      this.setState({
        cpfCheckout: value === 'yes' ? true : false
      })
    }

    return (
      <React.Fragment>
        {
          !config_cpf_checkout || config_cpf_checkout === false ? (
            <React.Fragment>
              <BRow style={{ marginTop: 15 }}>
                <span>CPF na nota?</span>
              </BRow>

              <BRow>
                <BCol margedRight>
                  <div className="radio-button">
                    <input type="radio" name="cpfCheckoutValue" value="no" id="cpf-no-to-change" onChange={handleCpfCheckoutChangeOption} defaultChecked />
                    <label htmlFor="cpf-no-to-change"><span></span>Não</label>
                  </div>
                </BCol>
                <BCol>
                  <div className="radio-button">
                    <input type="radio" name="cpfCheckoutValue" value="yes" id="cpf-yes-to-change" onChange={handleCpfCheckoutChangeOption} />
                    <label htmlFor="cpf-yes-to-change"><span></span>Sim</label>
                  </div>
                </BCol>
              </BRow>
            </React.Fragment>
          ) : (
            <BRow style={{ marginTop: 15 }}>
              <span>CPF na nota</span>
            </BRow>
          )
        }

        {
          cpfCheckout || (config_cpf_checkout && config_cpf_checkout === true) ? (
            <BRow className={cpfCheckout ? 'animated slideInDown' : ''} style={{ flexDirection: 'column' }}>
              <InputMask
                id="cpfCheckoutValue"
                name="cpfCheckoutValue"
                value={cpfCheckoutValue}
                placeholder="Insira o CPF"
                mask="999.999.999-99"
                height={5}
                fontSize={14}
                onChange={this.onChangeField}
                cpfvalid={cpfCheckoutValid ? 1 : 0}
              />
              {
                !cpfCheckoutValid ? <Error>Por favor informe um CPF válido.</Error> : null
              }
            </BRow>
          ) : null
        }
      </React.Fragment>
    )
  }

  _renderHealthInsuranceCpfForm = () => {
    const { cpfCheckout, cpfCheckoutValue, cpfCheckoutValid } = this.state

    return (
      <React.Fragment>
        <BRow style={{ marginTop: 15 }}>
          <span>CPF</span>
        </BRow>
        <BRow className={cpfCheckout ? 'animated slideInDown' : ''} style={{ flexDirection: 'column' }}>
          <InputMask
            id="cpfCheckoutValue"
            name="cpfCheckoutValue"
            value={cpfCheckoutValue}
            placeholder="Insira o CPF"
            mask="999.999.999-99"
            height={5}
            fontSize={14}
            onChange={this.onChangeField}
            style={{ marginBottom: 20 }}
            cpfvalid={cpfCheckoutValid ? 1 : 0}
          />
          {
            !cpfCheckoutValid && cpfCheckoutValue.length > 0 ? <Error>Por favor informe um CPF válido.</Error> : null
          }
        </BRow>
      </React.Fragment>
    )
  }

  _renderPaymentMethods = (type) => {
    const { paymentMethods } = this.state
    const method = paymentMethods[type]

    if (method !== undefined) {
      return (
        <ul>
          {
            method.map((val, index) => {
              const { option_id, name } = val

              return (
                <li key={`${type}-${index}`}>
                  <div className="radio-button">
                    <input type="radio" name="paymentMethodOption" value={option_id} id={`option_${option_id}`} onChange={this.onChangeField} />
                    <label htmlFor={`option_${option_id}`}><span></span>{name}</label>
                  </div>
                </li>
              )
            })
          }
        </ul>
      )
    }
  }

  _renderMoneyChange = () => {
    const { moneyChange, moneyChangeValueMasked } = this.state

    const handleMoneyChangeOption = ({ target: { value } }) => {
      this.setState({
        moneyChange: value === 'yes' ? true : false
      })
    }

    return (
      <React.Fragment>
        <BRow>
          <span>Precisa de troco?</span>
        </BRow>
        <BRow>
          <BCol margedRight>
            <div className="radio-button">
              <input type="radio" name="needChange" value="no" id="no-to-change" onChange={handleMoneyChangeOption} defaultChecked />
              <label htmlFor="no-to-change"><span></span>Não</label>
            </div>
          </BCol>
          <BCol>
            <div className="radio-button">
              <input type="radio" name="needChange" value="yes" id="yes-to-change" onChange={handleMoneyChangeOption} />
              <label htmlFor="yes-to-change"><span></span>Sim</label>
            </div>
          </BCol>
        </BRow>
        {
          moneyChange ? (
            <BRow className="animated slideInDown">
              <CurrencyInput className="default-input" name="moneyChangeValue" placeholder="Valor do troco" prefix="R$ " decimalSeparator="," thousandSeparator="." value={moneyChangeValueMasked} onChangeEvent={this.onChangeCurrencyField} />
            </BRow>
          ) : null
        }
      </React.Fragment>
    )
  }

  _renderConvForm = () => {
    const { selectedConv } = this.state

    const handleConvSelection = ({ target: { value } }) => {
      this.setState({
        selectedConv: value
      })
    }

    return (
      <React.Fragment>
        <BRow>
          <Formik>
            <Form style={{ width: 150 }}>
              <Select options={[{ value: 1, label: 'oi' }]} onChange={handleConvSelection} />
            </Form>
          </Formik>
        </BRow>
        {
          selectedConv > 0 ? (
            <div className="animated slideInDown" style={{ marginTop: 10, zIndex: -1 }}>
              <BRow>
                <Input type="text" name="convID" placeholder="Digite seu ID" />
              </BRow>
              <BRow>
                <Input type="text" name="convPassword" placeholder="Digite sua senha" />
              </BRow>
            </div>
          ) : null
        }
      </React.Fragment>
    )
  }
  // parcelas
  // parcelasstone
  // damaso

    // refat

  _renderInstallmentStone = () => {
    const { productsCupom, deliveries, currentAddress, currentOrderShipping } = this.props
    const { not_deliverable } = currentAddress ? currentAddress : false
    const { delivery_fee, free_from } = deliveries[0]
    const deliveryFreeFrom = Number(free_from)
    const deliveryFee = Number(delivery_fee)


    let orderTotal = 0
    productsCupom.map(p => orderTotal += (Number(p.price.toFixed(2)) * p.quantity))

    if (orderTotal < deliveryFreeFrom || deliveryFreeFrom === 0 || currentOrderShipping !== null) {

      if (!not_deliverable) orderTotal +=  currentOrderShipping ?  Number(currentOrderShipping.price) : deliveryFee
    }


    let x = ['', '', '', '', '', '', '', '', '', '', '', '']
    
    return x.map((installment, i) => {
      i = i + 1
      let trueInstallment = 0 + i
      return (
        <option value={trueInstallment}>
          {(trueInstallment) + ' vezes de R$ ' + (orderTotal / trueInstallment).toFixed(2)}
        </option>
      )
    })
  }

  _renderInstallmentOnCreditCard = () => {
    const { installmentOnCreditCard } = this.state

    if (installmentOnCreditCard) {
      return installmentOnCreditCard.map((installments, index) => {
        const { quantity, installmentAmount, totalAmount, interestFree } = installments

        return (
          <option key={index} value={quantity}>
            {interestFree && quantity === 1 ? 'Pagamento à vista - ' + floatToBRL(totalAmount) :
              interestFree ? quantity + 'X de ' + floatToBRL(installmentAmount) + ' sem juros' :
                quantity + 'X de ' + floatToBRL(installmentAmount) + ' com juros '
            }
          </option>
        )
      })
    }
  }

  _renderOnlinePaymentForm = () => {
    const { onlinePaymentInputs, processingOrder, pagseguroHash, gatewaySession, selectAmount, installmentOnCreditCardSelect } = this.state
    const { cardNumber, cardBrand, cardExpire, cardCVC, cardName, cardCPF } = onlinePaymentInputs
    const { quantity, installmentAmount, totalAmount, interestFree } = installmentOnCreditCardSelect;

    const brandImage = cardBrand.length > 0 ? `https://stc.pagseguro.uol.com.br/public/img/payment-methods-flags/68x30/${cardBrand}.png` : null

    return (
      <div className={processingOrder ? 'scale-down-ver-top' : 'scale-up-ver-top'} style={{ width: 468 }}>
        {!pagseguroHash || !gatewaySession ? (
          <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
            <LoadingCircle width={100} height={100} />
          </div>
        ) : (
          <>
            <InputMask
              id="cardNumber"
              name="cardNumber"
              value={cardNumber}
              autoComplete="cc-number"
              placeholder="Número do Cartão"
              mask="9999 9999 9999 9999"
              height={10}
              fontSize={16}
              image={brandImage}
              onChange={this.onChangeOnlinePaymentField}
              style={{ marginBottom: 10 }}
            />

            <BRow>
              <BCol width="50%">
                <InputMask
                  id="cardExpire"
                  name="cardExpire"
                  value={cardExpire}
                  autoComplete="cc-exp"
                  placeholder="Validade"
                  mask="99/9999"
                  height={10}
                  fontSize={16}
                  onChange={this.onChangeOnlinePaymentField}
                />
              </BCol>
              <BCol width="48%">
                <Input type="number" name="cardCVC" autoComplete="cc-csc" maxLength={3} fontSize={16} height={10} value={cardCVC} placeholder="CVV" onChange={this.onChangeOnlinePaymentField} style={{ marginLeft: 10 }} />
              </BCol>
            </BRow>

            <Input type="text" name="cardName" autoComplete="cc-name" fontSize={16} height={10} value={cardName} placeholder="Nome do Titular" onChange={this.onChangeOnlinePaymentField} />
            <InputMask
              id="cardCPF"
              name="cardCPF"
              value={cardCPF}
              placeholder="CPF do Titular"
              mask="999.999.999-99"
              height={10}
              fontSize={16}
              onChange={this.onChangeOnlinePaymentField}
              style={{ marginBottom: 20 }}
            />
            <PrettySelect
              name="InstallmentOnCreditCard"
              value={selectAmount}
              placeholder={quantity ?
                interestFree && quantity === 1 ? 'Pagamento a vista - ' + floatToBRL(totalAmount) :
                  interestFree ? quantity + 'X de ' + floatToBRL(installmentAmount) + ' sem juros' :
                    quantity + 'X de ' + floatToBRL(installmentAmount) + ' com juros'
                : 'Em quantas parcelas deseja pagar?'}
              fontSize={16}
              textAlign="center"
              disabled={!CpfValidator(cardCPF)}
              onChange={this.setInstallmentOnOrder}
            >
              {this._renderInstallmentOnCreditCard()}
            </PrettySelect>
          </>
        )}
        {

          !CpfValidator(cardCPF) ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Error>Por favor informe um CPF válido.</Error>
            </div>
          ) : null
        }
      </div>
    )
  }

  // trocar nomes
  _renderStonePaymentForm = () => {
    const { onlineStonePaymentInputs, processingOrder } = this.state
    const { number, dateStone, cvv, holder_name, cpf } = onlineStonePaymentInputs

    return (
      <div className={processingOrder ? 'scale-down-ver-top' : 'scale-up-ver-top'} style={{ width: 468 }}>
          <>
          <Input type="text" name="holder_name" autoComplete="cc-name" fontSize={16} height={10} value={holder_name} placeholder="Nome presente no cartão" onChange={this.onChangeStonePaymentField}
              style={{borderColor:"#707070"}}
                  
                  />

            <InputMask
              id="number"
              name="number"
              value={number}
              autoComplete="cc-number"
              placeholder="Número do Cartão"
              mask="9999 9999 9999 9999"
              height={10}
              fontSize={16}
              onChange={this.onChangeStonePaymentField}
              style={{marginBottom: 10, borderColor:"#707070"}}

              cpfvalid={''}
            />

            <BRow>
              <BCol width="45%">
                <InputMask
                  id="dateStone"
                  name="dateStone"
                  value={dateStone}
                  autoComplete="cc-exp"
                  placeholder="Validade"
                  mask="99/9999"
                  height={10}
                  fontSize={16}
                  onChange={this.onChangeStonePaymentField}
                  style={{ borderColor:"#707070"}}
                
                />
              </BCol>
              <BCol width="45%">
                <Input
                  type="number" 
                  name="cvv" 
                  autoComplete="cc-csc" 
                  maxLength={3} 
                  fontSize={16} 
                  height={10} 
                  value={cvv} 
                  placeholder="CVV"
                  onChange={this.onChangeStonePaymentField} 
                  style={{marginBottom: 10, marginLeft:10, borderColor:"#707070"}}

                />
              </BCol>
              <BCol width="5%">
                  <div className="tooltip">
                    <span className="tooltip-text">CVV são os três digitos que são encontrados na parte de trás do seu cartão ;)</span>
                    <img src={InformationIcon} alt="" style={{width: '30px', margin: '6px 0 0 18px', opacity:'0.5'}}/>
                  </div>
              </BCol>
            </BRow>

            {
              (cvv.length > 4) ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Error>Por favor informe um CVV de tamanho válido.</Error>
                </div>
              ) : null
            }
            <InputMask
              id="cpf"
              name="cpf"
              value={cpf}
              placeholder="CPF do Titular"
              mask="999.999.999-99"
              height={10}
              fontSize={16}
              onChange={this.onChangeStonePaymentField}
              style={{marginBottom: 20, borderColor:"#707070"}}
            />
              {
              !CpfValidator(cpf) && cpf.length > 1?(
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Error>Por favor informe um CPF válido.</Error>
                </div>
              ) : null
            }
            <PrettySelect
              name="InstallmentOnCreditCard"
              value={"Deseja parcelar?"}
              placeholder={"Deseja parcelar?"}
              fontSize={16}
              textAlign="center"
              disabled={!CpfValidator(cpf)}
              onChange={this.setStoneInstallmentOnOrder}
            >
              {this._renderInstallmentStone()}
            </PrettySelect>
          </>
      </div>
    )
  }

  _renderTicketStonePaymentForm = () => {

    const { ticketStonePaymentInputs, processingOrder } = this.state
    const { cpf_ticket } = ticketStonePaymentInputs

    return (
      <div className={processingOrder ? 'scale-down-ver-top' : 'scale-up-ver-top'} style={{ width: 468 }}>
          <InputMask
              id="cpf_ticket"
              name="cpf_ticket"
              value={cpf_ticket}
              placeholder="Digite seu CPF"
              mask="999.999.999-99"
              height={10}
              fontSize={16}
              onChange={this.onChangeTicketStonePaymentInputs}
              style={{marginBottom: 20, borderColor:"#707070"}}
            />
            {
              !CpfValidator(cpf_ticket) && cpf_ticket.length > 1?(
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Error>Por favor informe um CPF válido.</Error>
                </div>
              ) : null
            }
      </div>
    )
  }

  _renderOnlineFormPicpay = () => {
    const { processingOrder } = this.state;

    return (
      <PicPayContainer>
        <PicPayInfo className={processingOrder ? 'scale-down-ver-top' : 'scale-up-ver-top'}>
          <img src={PicPayBanner} alt="banner picpay" title="Pague com picpay é rápido fácil e seguro" />
          <PicPayText>Pague com o PicPay, direto do seu celular. <br /> Ao finalizar a compra, um código será exibido. Para pagar basta escanear o código
            com seu PicPay.<br /> Ainda não tem conta ? <br /> Baixe o aplicativo gratuitamente na Play Store (Android) ou na Apple Store (Iphone).</PicPayText>
        </PicPayInfo>

      </PicPayContainer>
    );
  }


  _renderDeliveryPaymentMethdos = () => {

    const {
      cardMethodSelected,
      moneyMethodSelected,
      onlinePaymentSelected,
      stonePaymentSelected,
      ticketStonePaymentSelected,
      errors,
      picPayPaymentSelected,
      healthInsuranceSelected,
      pixPaymentSelected,
      healthInsurance,
      cpfCheckoutValue,
      cpfCheckoutValid
    } = this.state

    const { currentAddress } = this.props;
    const { not_deliverable } = currentAddress ? currentAddress : false;

    // Render card payment method
    if (cardMethodSelected) {
      return (
        <div style={{ marginTop: 15 }} className="animated slideInLeft">
          <SubTitle>{!not_deliverable ? 'Pagamento na entrega' : 'Pagamento na retirada'}: Máquina</SubTitle>

          <BRow>
            <BCol style={{ marginRight: 60 }}>
              <strong>Crédito</strong>
              {this._renderPaymentMethods('credit')}
              <br />
              <strong>Débito</strong>
              {this._renderPaymentMethods('debit')}
            </BCol>
            <BCol>
              {this._renderCpfForm()}
            </BCol>
          </BRow>
        </div>
      )
    }

    if(stonePaymentSelected){
      return(
        // aqui3
        <div style={{ marginTop: 15 }} className="animated slideInLeft">
          {
            errors.length > 0 ? (
              <ErrorsContainer className="animated slideInLeft">
                <h3>Ooops. Tivemos alguns problemas ao processar seu pedido.</h3>

                {
                  errors.map((error, index) => {
                    return <p key={`error_${index}`}>- {ErrorCodes(error)}</p>
                  })
                }
              </ErrorsContainer>
            ) : null
          }
          {this._renderStonePaymentForm()}
        </div>
        
      )
    }

    if(ticketStonePaymentSelected){
      return(
      <div style={{ marginTop: 15 }} className="animated slideInLeft">
      {
        errors.length > 0 ? (
          <ErrorsContainer className="animated slideInLeft">
            <h3>Ooops. Tivemos alguns problemas ao processar seu pedido.</h3>

            {
              errors.map((error, index) => {
                return <p key={`error_${index}`}>- {ErrorCodes(error)}</p>
              })
            }
          </ErrorsContainer>
        ) : null
      }

      <h1>Boleto Bancário</h1>
          {this._renderTicketStonePaymentForm()}
          <h3>Ao digitar seu CPF, clique em finalizar a compra e será gerado um boleto bancário.</h3>
      </div>
      )
    }

    // Render money payment method
    if (moneyMethodSelected) {
      return (
        <div style={{ marginTop: 15 }} className="animated slideInLeft">
          <SubTitle>{!not_deliverable ? 'Pagamento na entrega' : 'Pagamento na retirada'}: Dinheiro</SubTitle>

          <BRow>
            <BCol>
              {this._renderMoneyChange()}
              {this._renderCpfForm()}
            </BCol>
          </BRow>
        </div>
      )

    }

    // Render online payment method
    if (onlinePaymentSelected) {
      return (
        <div style={{ marginTop: 15 }} className="animated slideInLeft">
          {
            errors.length > 0 ? (
              <ErrorsContainer className="animated slideInLeft">
                <h3>Ooops. Tivemos alguns problemas ao processar seu pedido.</h3>

                {
                  errors.map((error, index) => {
                    return <p key={`error_${index}`}>- {ErrorCodes(error)}</p>
                  })
                }
              </ErrorsContainer>
            ) : null
          }

          <img src={PagSeguroBanner} alt="Banner PagSeguro" title="Compre com PagSeguro e fique sossegado" />
          {this._renderOnlinePaymentForm()}
        </div>
      )
    }

    if (picPayPaymentSelected) {
      return (
        <div style={{ marginTop: 15 }} className="animated slideInLeft">
          {
            errors.length > 0 ? (
              <ErrorsContainer className="animated slideInLeft">
                <h3>Ooops. Tivemos alguns problemas ao processar seu pedido.</h3>

                {
                  errors.map((error, index) => {
                    return <p key={`error_${index}`}>- {ErrorCodes(error)}</p>
                  })
                }
              </ErrorsContainer>
            ) : null
          }
          {this._renderOnlineFormPicpay()}
        </div>
      );
    }

    if (pixPaymentSelected) {
      return (
        <div style={{ marginTop: 15, paddingRight: 16 }} className="animated slideInLeft">
          <img src={PixLogo} alt="" style={{ height: 150 }} />
          <p>Pague com pix, lendo o QRCode ou utilizando a chave de pagamento</p>
          <p>Para continuar,informe seu cpf e finalize o pedido para receber o QRcode e a chave de pagamento.</p>
          <InputMask
            id="cpfCheckoutValue"
            name="cpfCheckoutValue"
            value={cpfCheckoutValue}
            placeholder="Insira o CPF"
            mask="999.999.999-99"
            height={5}
            fontSize={14}
            onChange={this.onChangeField}
            cpfvalid={cpfCheckoutValid ? 1 : 0}
          />
        </div>
      );
    }

    if (healthInsuranceSelected) {
      return (
        <HealthInsuranceContainer>
          <SubTitle>Forma de pagamento selecionada : Convênio</SubTitle>
          <p>insira o nome da empresa conveniada e finalize o pedido</p>
          <label htmlFor="healthInsurance">Empresa</label>
          <input
            type="text"
            className="default-input"
            placeholder="Nome da empresa"
            value={healthInsurance}
            name="healthInsurance"
            onChange={this.onChangeField}
          />
          {
            this._renderHealthInsuranceCpfForm()
          }
        </HealthInsuranceContainer>
      )
    }
  }

  copyToClipboard = (e) => {
    this.qrCodeInput.current.select();
    document.execCommand('copy');
    this.setState({ ...this.state, copySuccess: true });
  };

  render() {


    const { deliveries, products, user, currentAddress, store, currentOrderShipping } = this.props
    const { settings } = store
    const { config_navbar_color } = settings
    const { not_deliverable } = currentAddress ? currentAddress : false;
    const {
      deliveryPaymentSelected,
      onlinePaymentSelected,
      hasCartMethod,
      hasMoneyMethod,
      hasCovenantMethod,
      processingOrder,
      hasActiveAddress,
      shouldCompleteCheckout,
      showCompleteAddress,
      pagSeguroPaymentMethod,
      stonePaymentMethod,
      ticketStonePaymentMethod,
      picPayPaymentMethod,
      pixPaymentMethod,
      processingPicPay,
      processingTicketStone,
      processingPix,
      picpayData,
      ticketStoneData,
      pixData,
      copySuccess
    } = this.state

    console.log(processingTicketStone)

    return (

      <Body>
        <BRow className="content">
          {
            !processingOrder ? (
              <BCol width="66%">
                <Title>Escolher forma de pagamento</Title>

                <BRow>
                  <BCol margedRight>
                    { !currentOrderShipping && <SelectableDropdown
                      color="#707070"
                      selected={deliveryPaymentSelected}
                      dropdown={true}
                      placeholder={not_deliverable ? 'Pagar na Retirada' : 'Pagar na Entrega'}
                      onClick={() => {
                        GAEvents({
                          category: 'Checkout',
                          action: 'Click no botão Pagar na Entrega'
                        })
                        setStep(2, 'Pagar na entrega')
                      }}
                      icon={
                        <WalletIcon
                          width={20}
                          height={20}
                          color={deliveryPaymentSelected ? '#eee' : '#707070'}
                        />
                      }
                    >
                      {hasCartMethod &&
                        <SelectableDropdownItem
                          text='Cartão máquina'
                          icon={
                            <CardIcon
                              width={20}
                              height={20}
                              color="#707070" />
                          }
                          onSelect={() => {
                            this.onChangePaymentMethod('cardMethodSelected')

                          }}
                        />
                      }
                      {hasMoneyMethod &&
                        <SelectableDropdownItem
                          text='Dinheiro'
                          icon={<MoneyIcon width={20} height={20} color="#707070" />}
                          onSelect={() => this.onChangePaymentMethod('moneyMethodSelected')}
                        />
                      }
                      {
                        hasCovenantMethod &&
                        <SelectableDropdownItem
                          text='Convênio'
                          icon={
                            <FontAwesomeIcon color="#707070" icon={Icons.faHandshake} style={{ height: 20, width: 20 }} />
                          }
                          onSelect={() => this.onChangePaymentMethod('healthInsuranceSelected')} />
                      }

                    </SelectableDropdown>}
                  </BCol>
                  {
                    (picPayPaymentMethod) || (pixPaymentMethod) || (pagSeguroPaymentMethod.length > 0 && !not_deliverable) || (stonePaymentMethod.length > 0 && !not_deliverable) || (ticketStonePaymentMethod && !not_deliverable) ? (
                      <BCol margedRight>
                        <SelectableDropdown
                          color="#707070"
                          placeholder='Pagar Online'
                          selected={onlinePaymentSelected}
                          dropdown={true}
                          onChange={() => this.onChangePaymentMethod('onlinePaymentSelected')}
                          onClick={() => {
                            GAEvents({
                              category: 'Checkout',
                              action: 'Click no botão Pagar Online'
                            })
                            setStep(2, 'Pagar online')
                          }}
                          icon={<CardIcon width={20} height={20} color={onlinePaymentSelected ? '#eee' : '#707070'} />}
                        >
                          {
                            pagSeguroPaymentMethod.length > 0 && !not_deliverable ? (
                              <SelectableDropdownItem
                                text='Crédito'
                                icon={<FontAwesomeIcon color="#707070" icon={Icons.faCreditCard} style={{ marginRight: 10 }} />}
                                onSelect={() => this.onChangePaymentMethod('onlinePaymentSelected')}
                              />)
                              : null}

                          {
                          stonePaymentMethod.length > 0 && !not_deliverable ? (
                            <SelectableDropdownItem
                              text='Crédito'
                              icon={<FontAwesomeIcon color="#707070" icon={Icons.faCreditCard} style={{ marginRight: 10 }} />}
                              onSelect={() => this.onChangePaymentMethod('stonePaymentSelected')}
                            />)
                            : null}
                          {
                            ticketStonePaymentMethod && !not_deliverable ? (
                              <SelectableDropdownItem
                                text="Boleto Bancário"
                                icon={<FontAwesomeIcon color="#707070" icon={Icons.faTicketAlt} style={{ marginRight : 10}} />}
                                onSelect={() => this.onChangePaymentMethod('ticketStonePaymentSelected')}
                            />)
                            :null
                          }

                          {
                            picPayPaymentMethod ? (
                              <SelectableDropdownItem
                                text='Picpay'
                                icon={<FontAwesomeIcon color="#707070" icon={Icons.faQrcode} style={{ marginRight: 10 }} />}
                                onSelect={() => this.onChangePaymentMethod('picPayPaymentSelected')}
                              />)
                              : null}

                          {
                            pixPaymentMethod ? (
                              <SelectableDropdownItem
                                text='Pix'
                                icon={<img src={PixIcon} alt="" style={{ marginRight: 10, color: '#707070', height: 20, filter: 'grayscale(100%)' }} />}
                                onSelect={() => this.onChangePaymentMethod('pixPaymentSelected')}
                              />)
                              : null}

                          

                        </SelectableDropdown>

                      </BCol>
                    ) : null
                  }
                </BRow>

                {this._renderDeliveryPaymentMethdos()}
              </BCol>
            ) : processingPix ? (
              pixData ? (
                <BCol width="100%">
                  <BRow>
                    <BCol width="100%">
                      <PicPayContainerQRcode>
                        <SubTitle>Pague o pix usando o QRCode abaixo</SubTitle>
                        <img src={pixData.imagemQrcode} alt={'pix qrcode'} />
                        <SubTitle>Ou clique no campo abaixo para copiar o codigo </SubTitle>
                        <InputContainer onClick={this.copyToClipboard} copySuccess={copySuccess}>
                          <CopyInput ref={this.qrCodeInput} value={pixData.qrcode} />
                          <button className="iconBtn" onClick={this.copyToClipboard} >
                            <FontAwesomeIcon copySuccess={copySuccess} icon={Icons.faCopy} className="icon" />
                          </button>
                        </InputContainer>
                        {
                          copySuccess && <p>Copiado com sucesso</p>
                        }
                        <SubTitle style={{ fontSize: 16, marginTop: 16 }} > Após o pagamento pode levar até alguns minutos para ser redirecionado para  pedido,aguarde. </SubTitle>
                      </PicPayContainerQRcode>
                    </BCol>
                  </BRow>
                </BCol>
              ) : (
                <BCol width={processingPix ? "100%" : "66%"} style={{ textAlign: 'center' }}>
                  <SubTitle>Processando pedido...</SubTitle>
                  <LoadingMoney width={250} height={250} color={config_navbar_color} />
                </BCol>
              )



            ) : processingTicketStone && ticketStoneData? (

              <BCol width={"100%"}>
                <BRow>
                  <BCol width={"100%"}>
                    <PicPayContainerQRcode>
                    
                      <Title>Agradecemos seu pedido!</Title>
                      <SubTitle>Pague com o QR Code</SubTitle>
                      <img src={ticketStoneData.ticket_qr_code} alt={'stone qrcode'} />
                      
                      <SubTitle>Ou clique no botão para baixar o PDF</SubTitle>
                      <SubTitle><a href={ticketStoneData.ticket_url} target="_blank" rel="noopener noreferrer" style={{"color" : "blue"}}>Baixar o PDF</a></SubTitle>
                    </PicPayContainerQRcode>
                  </BCol>
                </BRow>
              </BCol>



            ) : processingPicPay && picpayData ? (
              <BCol width="100%">
                <BRow>
                  <BCol width="100%">
                    <PicPayContainerQRcode>
                      <SubTitle>Abra o PicPay em seu telefone e escaneie o código abaixo :</SubTitle>
                      <img src={picpayData.qrcode.base64} alt={picpayData.referenceId} />
                      <a href={picpayData.paymentUrl} target="_blank" rel="noopener noreferrer">Site do Picpay</a>
                    </PicPayContainerQRcode>
                  </BCol>
                </BRow>
              </BCol>
            ) : (
              <BCol width={processingPicPay ? "100%" : "66%"} style={{ textAlign: 'center' }}>
                <SubTitle>Processando pedido...</SubTitle>
                <LoadingMoney width={250} height={250} color={config_navbar_color} />
              </BCol>
            )
          }
          {!processingPicPay && !processingTicketStone ? (
            <BCol width="33%">
              {
                deliveries ? (
                  <Cart buttonText="Finalizar pedido" disabled={processingOrder || !this.validateFields()} onClick={this.checkout} />
                ) : null
              }
            </BCol>
          ) : null}
        </BRow>
        {
          shouldCompleteCheckout ? <CompleteCheckout show={showCompleteAddress} customer={user} address={currentAddress} onFinish={this.finishCheckoutCompletion} /> : null
        }
        {
          // We do not have a valid checkout. let's go back to home
          !processingOrder && products.length === 0 ? <Redirect to="/" /> : null
        }

        {
          // We do not have a valid address, let's go to address view
          !hasActiveAddress ? <Redirect to={{ pathname: '/endereco', state: { selectAddress: true } }} /> : null
        }
      </Body>
    )
  }
}

const mapStateToProps = ({ payment, address, cart, order, delivery, startup, login }) => ({
  fetching: payment.fetching,
  error: payment.error,
  paymentMethods: payment.paymentMethods,
  gatewaySession: payment.gatewaySession,
  currentAddress: address.currentAddress,
  products: cart.products,
  orderFetching: order.fetching,
  orderError: order.error,
  order: order.order,
  deliveries: delivery.deliveries,
  store: startup.store,
  user: login.user,
  cart: cart,
  orderPipcayDetail: order.orderPipcayDetail,
  productsCupom: cart.productsCupom,
  currentOrderShipping: address.currentOrderShipping,
  currentShippingAddress: address.currentShippingAddress
})

const mapDispatchToProps = dispatch => ({
  attemptGetPaymentMethods: () => dispatch(Creators.paymentMethodListRequest()),
  attemptRegisterOrder: ({ address_id, city_id, payment_code, payment_option_id, payment_money_change, products, cpf, sub_method, sender_hash, card_token, card_holder, installments, payment_custom_field, delivery_mode, shipping_order, health_insurance }) => dispatch(Creators.orderRegisterRequest(address_id, city_id, payment_code, payment_option_id, payment_money_change, products, cpf, sub_method, sender_hash, card_token, card_holder, installments, payment_custom_field, delivery_mode, shipping_order, health_insurance)),
  attemptGetPaymentGatewaySession: ({ payment_option_id }) => dispatch(Creators.paymentGatewaySessionRequest(payment_option_id)),
  clearCartProducts: () => dispatch(Creators.cartSetProductRequest([])),
  attemptOrderInstallment: (orderInstallment) => dispatch(Creators.orderInstallmentRequest(orderInstallment)),
  getProductDetail: (slug) => dispatch(Creators.productDetailRequest(slug)),
  attemptRegisterWithdrawlOrder: ({ address_id, city_id, payment_code, payment_option_id, payment_money_change, products, cpf, sub_method, sender_hash, card_token, card_holder, installments, shipping_custom_fields }) => dispatch(Creators.orderWithdrawlRegisterRequest(address_id, city_id, payment_code, payment_option_id, payment_money_change, products, cpf, sub_method, sender_hash, card_token, card_holder, installments, shipping_custom_fields)),
  attempGetOrderPicpay: ({ orderId }) => dispatch(Creators.orderPicpayDetailRequest(orderId)),
  attemptGetOrderDetail: (order_id) => dispatch(Creators.orderDetailRequest(order_id)),
  attemptSetShippingToCart: (orderId, shipping, sender) => dispatch(Creators.setOnCartShippingRequest(orderId, shipping, sender)),
  setGlobalCart: (products, purchased) => dispatch(Creators.cartGlobalUpdateRequest(products, purchased))

})

export default connect(mapStateToProps, mapDispatchToProps)(Checkout)
