import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Switch, Route, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import TagManager from 'react-gtm-module'
import Modal from 'react-responsive-modal'
import * as moment from 'moment'
import { isLogged } from '../../redux/LoginRedux'
import { Creators } from '../../actions'
import { AppContainer } from './styles'
import DomainExtract from '../../helpers/DomainExtract'
import Header from '@components/Header'
import MobileHeader from '@components/MobileHeader'
import Footer from '@components/Footer'
import MobileFooter from '@components/MobileFooter'
import StoreURLsModal from '@components/StoreURLsModal'
import StoreRedirectModal from '@components/StoreRedirectModal'

// Default view
import Splash from '@views/Splash'
import Page404 from '@views/404'
import CookiePopup from '../CookiePopup'

// Routes
import NavRoutes from '../../navigation/NavRoutes'

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false,
      showApp: false,
      modalOpen: false,
      modalOpenByGroup: false,
      tagManagerInitialized: false,
      isCookiesAllowed :false
    }

    props.startup()
  }

  componentDidMount() {
    const { refreshToken, loggedIn } = this.props
    const isCookiesAllowed = localStorage.getItem('isCookiesAllowed')

    // Refresh user token
    if(isCookiesAllowed === 'true'){
      this.setState({
        ...this.state,
        isCookiesAllowed : true
      },()=>{
        if (loggedIn) {
          setTimeout(()=>{
            refreshToken()
          },3000)
        }
      })
    }
  }

  componentWillReceiveProps(newProps) {
    const { setCurrentAddress } = this.props
    const { fetching, store } = newProps
    const { loaded } = this.state
    const isCookiesAllowed = localStorage.getItem('isCookiesAllowed')

    // Copy current state
    const newState = Object.assign({}, this.state)

    if(isCookiesAllowed === 'true'){
      newState.isCookiesAllowed = true
    }

    // Our store is fully configured?
    if (!loaded && !fetching && store !== null) {
      const { settings, groups, urls } = store
      const { config_google_tag_manager_id } = settings

      // Initialize Google Tag Manager

      if (!newState.tagManagerInitialized && config_google_tag_manager_id && isCookiesAllowed === 'true' ) {
        newState.tagManagerInitialized = true

        TagManager.initialize({
          gtmId: config_google_tag_manager_id,
        })
        
      }

      // Update initial states
      newState.loaded = true
      newState.modalOpen = urls && urls.length > 0
      newState.modalOpenByGroup = groups && groups.length > 0
    }

    // Our session has expired :/
    if (!newProps.loggedIn) {
      // Reset current address
      setCurrentAddress(null)
    }

    if (newState !== this.state) {
      this.setState(newState)
    }
  }

  closeStoreModal = () => {
    const { setStoreRedirect } = this.props

    this.setState({
      modalOpen: false
    }, () => {
      const expireIn = moment().add(2, 'days').toISOString()
      setStoreRedirect(false, expireIn)
    })
  }

  allowCookies = ()=>{
    this.setState({
      ...this.state,
      isCookiesAllowed : true
    },()=>{
      localStorage.setItem('isCookiesAllowed',true)
    })
  }

  render() {
    const { location, storeRedirectExpireIn } = this.props
    const { loaded, showApp, modalOpen, modalOpenByGroup,isCookiesAllowed } = this.state
    const { search } = location

    // Web mobile app
    const isMobile = window.innerWidth < 768
    // Native mobile app
    const isMyPharmaBase = window.navigator ? window.navigator.userAgent === 'mypharmaBase' : false

    let schemaMarkup = undefined
    let tawkEmbed = undefined
    let storeUrls = []
    let storeGroups = []

    // Show store URLS modal?
    const redirectExpireIn = storeRedirectExpireIn ? moment(storeRedirectExpireIn) : moment()

    if (loaded) {
      const { store } = this.props
      const { settings, urls, groups } = store
      const { config_schema_markup, config_tawk_embed } = settings

      storeUrls = urls
      storeGroups = groups

      // Is valid schema?
      if (config_schema_markup) {
        try {
          schemaMarkup = JSON.parse(config_schema_markup.replace(/&quot;/g, '"'))
        } catch (error) {
          console.log(error)
        }
      }

      // Is valid tawk.to embed?
      if (config_tawk_embed) {
        if (DomainExtract(config_tawk_embed) === 'embed.tawk.to') {
          tawkEmbed = config_tawk_embed
        }
      }
    }

    return (
      <AppContainer style={{ overflow: showApp ? 'unset' : 'hidden' }}>
        {
          loaded ? (
            <React.Fragment>
              <Helmet>
                {schemaMarkup ? <script type='application/ld+json'>{`${JSON.stringify(schemaMarkup)}`}</script> : null}
                {tawkEmbed ? <script src={tawkEmbed} /> : null}
              </Helmet>

              <Router>
                <React.Fragment>
                {
                  isMobile ? 
                    <MobileHeader isMyPharmaBase={isMyPharmaBase} isCookiesAllowed={isCookiesAllowed} /> : 
                    <Header isCookiesAllowed={isCookiesAllowed} />
                  }

                  {
                    // eslint-disable-next-line
                    !isCookiesAllowed && showApp && (
                      <CookiePopup allowCookies={this.allowCookies} />
                    )
                  }

                  <Switch>
                    {NavRoutes.filter(route => !route.mypharmaBase).map((route, index) => {
                      const { path, exact, component, mobileComponent, render } = route

                      if (render) {
                        return (
                          <Route
                            key={`web-route-${index}`}
                            path={path}
                            exact={exact}
                            render={render}
                          />
                        )
                      } else {
                        const RouteComponent = ((isMobile || isMyPharmaBase) && mobileComponent) ? mobileComponent : component

                        return (
                          <Route
                            key={`web-route-${index}`}
                            path={path}
                            exact={exact}
                            render={(props) => <RouteComponent {...props} isMypharmaBase={isMyPharmaBase} />}
                          />
                        )
                      }
                    })}

                    {
                      // For supporting our base mobile app, we need add some routing for not break the navigation
                      isMyPharmaBase ? (
                        <React.Fragment>
                          {
                            NavRoutes.filter(route => route.mypharmaBase === true).map((route, index) => {
                              const { path, exact, render } = route

                              return <Route path={path} exact={exact} key={`mobile-route-${index}`} render={render} />
                            })
                          }
                        </React.Fragment>
                      ) : null
                    }

                    <Route component={Page404} />
                  </Switch>
                </React.Fragment>
              </Router>
              {isMobile ? <MobileFooter /> : <Footer />}
            </React.Fragment>
          ) : null
        }

        {
          // Redirect to stores
          (storeUrls && storeUrls.length > 0) && (!storeGroups || storeGroups.length <= 0) && (search !== '?noredirect') && redirectExpireIn.isSameOrBefore(moment()) ? (
            <Modal open={modalOpen} showCloseIcon={false} onClose={this.closeStoreModal} styles={{ modal: { borderRadius: 5 } }} center>
              <StoreURLsModal urls={storeUrls} mobile={isMobile} />
            </Modal>
          ) : null
        }

        {
          // Redirect to stores by CEP
          (!storeUrls || storeUrls.length <= 0) && (storeGroups && storeGroups.length > 0) && (search !== '?noredirect') && redirectExpireIn.isSameOrBefore(moment()) ? (
            <Modal closeOnEsc={false} closeOnOverlayClick={false}open={modalOpenByGroup} showCloseIcon={false} onClose={this.closeStoreModal} styles={{ modal: { borderRadius: 5 } }} center>
              <StoreRedirectModal mobile={isMobile} />
            </Modal>
          ) : null
        }
        <Splash hide={loaded} onFinish={() => this.setState({ showApp: true })} />
      </AppContainer>
    )
  }
}

const mapStateToProps = ({ startup, login }) => ({
  fetching: startup.fetching,
  store: startup.store,
  accessToken : startup.accessToken,
  loggedIn: isLogged(login),
  storeRedirect: login.storeRedirect,
  storeRedirectExpireIn: login.storeRedirectExpireIn,
})

const mapDispatchToProps = dispatch => ({
  startup: () => dispatch(Creators.startupRequest()),
  refreshToken: () => dispatch(Creators.refreshTokenRequest()),
  setStoreRedirect: (status, expireIn) => dispatch(Creators.setStoreRedirect(status, expireIn)),
  setCurrentAddress: (address) => dispatch(Creators.setCurrentAddressRequest(address)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
