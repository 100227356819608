/* eslint-disable no-useless-escape */
/* eslint-disable next-line */
/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-keys */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import MarkerIcon from '@components/Icons/Marker'
import Button from '@components/Button'
import { Row, Col } from 'react-flexbox-grid'
import * as moment from 'moment'
import LoadingCircle from '@components/Icons/LoadingCircle'
import ErroCode from "../../helpers/ErrorCodes";
import { Title, Content, ErrorContainer, InputMask, StoreList, Link } from './styles'
import { Creators } from '../../actions'


class StoreRedirectModal extends Component {
  static propTypes = {
    urls: PropTypes.any,
    mobile: PropTypes.bool
  }

  constructor(props) {
    super(props)

    this.state = {
      redirectTo: '',
      inputs: {
        addressPostcode: ''
      },
      error: false,
      registerError: false,
      cepError: false,
      canContinue: false,
      searching: false,
      hideForm: true,
      chooseStore: false,
      stores: []
    }
  }

  componentWillReceiveProps(newProps) {
    const { error, fetching, redirectUrl } = newProps;
    let newState = Object.assign({}, this.state);

    if (newState.searching && !fetching && error) {
      newState.error = ErroCode(error)
      newState.searching = false
      newState.canContinue = false
      newState.inputs.addressPostcode = ''
    }

    if (newState.searching && !fetching && redirectUrl && redirectUrl.match) {
      const { groups } = redirectUrl;
      newState.searching = false
      newState.hideForm = false


      if (groups.length === 1) {
        const url = groups[0]

        const expireIn = moment().add(2, 'days').toISOString()
        this.props.setStoreRedirect(false, expireIn)

        setTimeout(() => {
          document.location.href = `${url.url}?noredirect`
        }, 1000)
      } else {

        newState.stores = groups
        newState.chooseStore = true
      }

    }

    if (newState.searching && !fetching && !redirectUrl.match) {
      const { groups } = redirectUrl;
      newState.hideForm = false
      newState.searching = false
      newState.stores = groups
      newState.chooseStore = false

    }


    if (newState !== this.state) {
      this.setState(newState)
    }
  }

  onChangeField = ({ target: { name, value } }) => {
    const { setStoreRedirect } = this.props

    this.setState(state => ({
      ...state,
      [name]: value
    }), () => {
      const expireIn = moment().add(2, 'days').toISOString()
      setStoreRedirect(false, expireIn)

      setTimeout(() => {
        document.location.href = `${value}?noredirect`
      }, 1000)
    })
  }

  onChangeInput = ({ target: { name, value } }) => {

    this.setState(state => ({
      ...state,
      inputs: {
        ...state.inputs,
        [name]: value.replace(/\-/g, '')
      }
    }))
  }
  validFields = () => {
    const { inputs, registerError, cepError, canContinue } = this.state;
    const { addressPostcode } = inputs;
    let BValidCep = cepError;
    let BValidErrorRegister = registerError;
    let canProceed = canContinue;

    const regex = /^\d{5}\d{3}$/g;
    BValidCep = regex.test(addressPostcode);

    if (!BValidCep) {
      BValidErrorRegister = 'O cep deve conter 8 digítos.';
    }

    canProceed = BValidCep;

    this.setState({
      registerError: BValidCep,
      cepError: BValidCep,
      registerError: BValidErrorRegister,
      canContinue: canProceed
    })
  }
  _processSubmit = () => {
    this.validFields();

    setTimeout(() => {
      this._handleSubmit();
    }, 500);
  }

  _handleSubmit = () => {
    const { attempGetAddressByCep } = this.props;
    const { canContinue, inputs } = this.state;
    const { addressPostcode } = inputs;

    if (canContinue) {
      this.setState({
        searching: true,
        error: false,
        registerError: false,

      }, () => {
        attempGetAddressByCep(addressPostcode);
      })
    }
  }
  searchAgain = () => {
    const { hideForm, chooseStore } = this.state;

    this.setState(state => ({
      ...state,
      hideForm: !hideForm,
      chooseStore: !chooseStore,
    }))
  }

  closeStoreModal = () => {
    const { setStoreRedirect } = this.props;

    const expireIn = moment().add(2, 'days').toISOString()
    setStoreRedirect(false, expireIn)
  }

  _renderForm = () => {
    const { mobile } = this.props;
    const { addressPostcode, cepError, registerError } = this.state;

    return (
      <Content mobile={mobile}>
        {registerError ? <ErrorContainer>{registerError}</ErrorContainer> : null}
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <InputMask
            type="tel"
            id="addressPostcode"
            name="addressPostcode"
            value={addressPostcode}
            autoComplete="postcode"
            placeholder="Ex: 59031-100"
            mask="99999-999"
            height={10}
            fontSize={16}
            validfield={cepError.toString()}
            onChange={this.onChangeInput}
          />
          <Button style={{ fontSize: 16}} onClick={this._processSubmit}>Pesquisar</Button>
          <Link href="https://buscacepinter.correios.com.br/app/endereco/index.php" target="_blank">Não sei meu CEP</Link>
        </div>
      </Content>
    );
  }

  _renderStoresToRedirect = () => {
    const { stores } = this.state;
    return stores.map((store, index) => {
      const { name, url, address } = store
      const { street, city, number } = address

      return (
        <li key={index}>
          <strong>{name}</strong>
          <span>{street} {number} {city}</span>
          <button name="redirectTo" value={url} onClick={this.onChangeField}>
            Navegar para esta loja
          </button>
        </li>
      );
    })
  }

  _renderOptions = () => {
    const { urls } = this.props

    return urls.map((url, index) => {
      const { url_name, url_address } = url

      return <option key={index} value={url_address}>{url_name}</option>
    })
  }

  render() {
    const { mobile } = this.props
    const { hideForm, searching, chooseStore, error } = this.state

    return (
      <Row style={{ margin: mobile ? '5px' : '35px' }}>
        <Col xs={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

          {
            searching ? (
              <React.Fragment>
                <Title fontSize={18} style={{ marginBottom: 25 }}>Aguarde...</Title>
                <LoadingCircle width={100} height={100} />
              </React.Fragment>
            )
              : hideForm ? (
                <React.Fragment>
                  <MarkerIcon />
                  <Title fontSize={mobile ? 16 : 24} bold>Digite seu CEP,</Title>
                  <Title fontSize={16} style={{ marginBottom: 25 }}>Localizaremos as farmácias mais próximas a você :)</Title>
                  { error ? <ErrorContainer>{error}</ErrorContainer> : null}
                  {this._renderForm()}
                </React.Fragment>

              )
                : chooseStore ? (
                  <React.Fragment>
                    <Title fontSize={mobile ? 16 : 24} bold style={{ marginBottom: 25 }}>Localizamos algumas farmácias próximas ao seu endereço :) </Title>
                    <StoreList mobile={mobile}>
                      {this._renderStoresToRedirect()}
                    </StoreList>
                    <Button color="secondary" style={{ marginTop: mobile ? '15px' : '30px', width: mobile ? '100%' : '50%' }} onClick={this.searchAgain}>Pesquisar outro CEP</Button>
                  </React.Fragment>

                )
                  : (
                    <React.Fragment>
                      <Title fontSize={mobile ? 16 : 24} style={{ marginBottom: 25 }} bold>Escolha a farmácia mais próxima a você</Title>
                      <StoreList mobile={mobile}>
                        {this._renderStoresToRedirect()}
                      </StoreList>
                      <Button color="secondary" style={{ marginTop: mobile ? '15px' : '30px', width: mobile ? '100%' : '50%' }} onClick={this.searchAgain}>Pesquisar outro CEP</Button>
                    </React.Fragment>
                  )
          }
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = ({ login }) => ({
  redirectUrl: login.redirectStoreUrl,
  fetching: login.fetching,
  error: login.error,
  groups: login.groups,

})

const mapDispatchToProps = dispatch => ({
  setStoreRedirect: (status, expireIn) => dispatch(Creators.setStoreRedirect(status, expireIn)),
  attempGetAddressByCep: (postcode) => dispatch(Creators.searchByCepRequest(postcode)),

})

export default connect(mapStateToProps, mapDispatchToProps)(StoreRedirectModal)
