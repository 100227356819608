import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { ToastContainer } from 'react-toastify'
import { Body } from './styles.js'
import DrugstoreInfo from '@components/MobileDrugstoreInfo'
import PulsingCircle from '@components/Icons/PulsingCircle'
import Clock from '@components/Icons/Clock'
import DeliveryBoy from '@components/Icons/DeliveryBoy'
import DolarSign from '@components/Icons/DolarSign'
import Pin from '@components/Icons/Pin'
import Card from '@components/Icons/Card'
import { Grid, Col, Row, } from 'react-flexbox-grid'
import { floatToBRL } from '../../helpers/MoneyFormat'
import { minutesToTime } from '../../helpers/DateConversion'
import { Creators } from '../../actions'
import './index.css'
import GAEvents from '../../helpers/GAEvents.js'
import { format, getMinutes, isBefore, isAfter, getHours, setHours, setMinutes, isWithinInterval, parseISO } from 'date-fns'

const WEEK_DAY = [
  'Seg',
  'Ter',
  'Qua',
  'Qui',
  'Sex',
  'Sáb',
  'Dom',
  'Fer'
]

class BodyContainer extends Component {
  static propTypes = {
    openDropdown: PropTypes.string
  }

  static defaultProps = {
    openDropdown: null
  }

  constructor(props) {
    super(props)

    this.state = {
      loadingDeliveries: false,
      storeOpen: true,
      deliverySchedule: [],
      paymentMethods: []
    }
  }

  componentDidMount() {
    const { attemptGetDeliverySchedule, attemptGetDeliveryCities, attemptGetDeliveryNeighborhoods, attemptGetPaymentMethods } = this.props

    attemptGetDeliverySchedule()
    attemptGetDeliveryCities()
    attemptGetDeliveryNeighborhoods()
    attemptGetPaymentMethods()
  }

  componentWillReceiveProps(newProps) {
    const { _delivery, _payment, _address } = newProps
    let newState = Object.assign({}, this.state)

    // Delivery schedule loaded
    if (!_delivery.fetching && _delivery.schedule !== null) {
      const { schedule } = _delivery
      const now = new Date()
      let storeOpen = false

      newState.deliverySchedule = schedule

      schedule.forEach(p => {
        if (Number(p.day) === Number(format(new Date(), 'i'))) {

          const startMinutes = getMinutes(new Date(p.start))
          const startHour = getHours(new Date(p.start))

          const endMinutes = getMinutes(new Date(p.end))
          const endHour = getHours(new Date(p.end))

          const start = setMinutes(setHours(now, startHour), startMinutes)
          const end = setMinutes(setHours(now, endHour), endMinutes)

          if (isWithinInterval(now, { start, end })) {
            storeOpen = true
          }

        }
      })

      if (storeOpen !== newState.storeOpen) {
        newState.storeOpen = storeOpen
      }
    }

    // Payment methods loaded
    if (!_payment.fetching && _payment.paymentMethods !== null) {
      newState.paymentMethods = _payment.paymentMethods
    }

    // Load deliveries
    if (newState.loadingDeliveries === false && _delivery.deliveries === null) {
      const { currentAddress } = _address
      let neighborhoodId = null

      if (currentAddress) {
        const { neighborhood } = currentAddress
        neighborhoodId = neighborhood && typeof neighborhood === 'object' ? neighborhood.neighborhood_id : null
      }

      this.props.attemptGetDeliveries(neighborhoodId)
      this.props.attempGetRegionsDelivery()
      newState.loadingDeliveries = true
    }

    // Update state if has changed
    if (newState !== this.state) {
      this.setState(newState)
    }
  }

  // Check if dropdown is open by native app route
  isDropDownOpen = (name) => {
    const { openDropdown } = this.props
    if (typeof name !== 'string') return false
    if (openDropdown === null) return false

    return openDropdown === name
  }

  _renderScheduleDropdownContent = () => {
    const { deliverySchedule } = this.state

    return (
      <Grid className="schedule">
        {
          deliverySchedule.map((schedule, index) => {
            const { day, start, end } = schedule
            const exactDay = Number(day - 1)

            let startStr = `${format(new Date(start), 'kk')}`
            let endStr = `${format(new Date(end), 'kk')}`

            if (getMinutes(new Date(start)) > 0) {
              startStr += `:${format(new Date(start), 'mm')}`
            } else {
              startStr += ' h'
            }

            if (getMinutes(new Date(end)) > 0) {
              endStr += `:${format(new Date(end), 'mm')}`
            } else {
              endStr += ' h'
            }


            return (
              <Row key={index}>
                <Col xs={3} className="primary-text">
                  <strong>{WEEK_DAY[exactDay]}.</strong>
                </Col>
                <Col xs={8} xsOffset={1} className="secondary-text" style={{ fontSize: 14 }}> {`${startStr} às ${endStr}`}</Col>
              </Row>
            )
          })
        }

        <Row>
          <Col xs={12} className="observation">
            <strong className="primary-text">OBS: </strong>pedidos feitos fora do horário de entrega serão entregues no próximo dia de entrega,
            conforme lista acima.
          </Col>
        </Row>
      </Grid>
    )
  }

  _renderDeliveredRegions = () => {
    const { _delivery, _address } = this.props
    const { cities, deliveries, regions } = _delivery
    const { currentAddress } = _address;
    let currentNeighborhoodId = null;
    let deliverable = false

    GAEvents({ category: 'Navigation', action: 'Click no botão Ver regioes de entrega', label: 'BodyContainer' })

    if (currentAddress && deliveries) {
      const { neighborhood, not_deliverable } = currentAddress;

      currentNeighborhoodId = neighborhood.neighborhood_id;
      deliverable = not_deliverable
    }

    if (cities && regions) {
      return (
        <Grid className="regions">
          {
            cities.map((city, index) => {
              const { name, state, neighborhoods } = city
              
              return (
                <Row key={index}>
                  <Col xs={3}>
                    <Row center="xs">
                      <Col xs={12}>
                        <Pin />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={9}>
                    <Row>
                      <Col xs={12} className="primary-text">
                        <strong>{`${name} - ${state.name}`}</strong>
                      </Col>
                    </Row>
                    {
                      neighborhoods.sort(function (a, b) {
                        if (a._id === currentNeighborhoodId && !deliverable) {
                          return -1
                        }
                        return 0

                      }).map((region, idx) => {
                        const { _id } = region
                        const delivery = regions.find(x => x.neighborhood._id === _id)

                        if (delivery) {
                          const { feePrice } = delivery
                          
                          return (
                            <Row key={idx}>
                              <Col xs={12} className="secondary-text" style={{ color: (_id === currentNeighborhoodId && !deliverable) ? '#333' : '#b8b8b8', fontWeight: (_id === currentNeighborhoodId && !deliverable) ? 'bold' : 'normal' }}>
                                {region.name}
                                <p style={{ lineHeight: 0, marginLeft: 10, fontWeight: 600 }}>
                                  {`• ${floatToBRL(Number(feePrice))}`}
                                </p>
                              </Col>
                            </Row>
                          )
                        }
                        return null
                      })
                    }
                  </Col>
                </Row>
              )
            })
          }
        </Grid>
      )
    }
  }

  _renderPaymentMethods = () => {
    const { paymentMethods } = this.state

    // Parse our list
    const methods = []
    paymentMethods.forEach(method => {
      const { name, type } = method

      const index = methods.findIndex(p => p.name === name)
      const convens = methods.filter(p => p.types.indexOf('conven') !== -1)

      if (index === -1) {
        // Avoid multiples covenants
        if (type !== 'conven' || convens.length === 0) {
          methods.push({
            name: name,
            types: [type]
          })
        }
      } else {
        methods[index].types.push(type)
      }
    })

    return (
      <Grid className="regions">
        {
          methods.map((method, index) => {
            const { name, types } = method
            let _name = name

            let _type = types[0]
            if (types.indexOf('credit') !== -1 || types.indexOf('debit') !== -1) {
              _type = types.indexOf('credit') ? 'Cred.' : 'Deb.'
              if (types.length > 1) {
                _type += ` e ${types[types.indexOf('debit')] !== -1 ? 'Deb' : ''}`
              }
            }

            // Pagseguro fix
            if (name === 'pagseguro') {
              _name = 'PagSeguro'
            }

            if (types.indexOf('gateway') !== -1) {
              _type = 'Pagamento Online'
            }

            return (
              <Row middle="xs" key={index} style={{ marginBottom: 5 }}>
                <Col xs={3}>
                  {_type === 'money' ? <DolarSign /> : <Card />}
                </Col>
                <Col xs={9}>
                  <p className="secondary-text" style={{ lineHeight: 1, margin: 0 }}>
                    {_type === 'conven' ? 'Convênios' : _name}
                  </p>

                  {_type !== 'money' && _type !== 'conven' ? <strong className="primary-text">{_type}</strong> : null}
                </Col>
              </Row>
            )
          })
        }
      </Grid>
    )
  }

  render() {
    const { children, _delivery } = this.props
    const { deliveries } = _delivery
    const { deliverySchedule, storeOpen } = this.state

    let deliveryTime = '0 - 0 min'
    if (deliveries !== null && deliveries.length > 0) {
      deliveries.sort(function (a, b) {
        return a.delivery_time - b.delivery_time
      })
      const { delivery_time: shorter_time } = deliveries[0]

      deliveries.sort(function (a, b) {
        return b.delivery_time - a.delivery_time
      })
      const { delivery_time: longer_time } = deliveries[0]

      if (Number(shorter_time) === Number(longer_time)) {
        const { value, suffix } = minutesToTime(shorter_time)
        deliveryTime = `${value} ${suffix}`

      } else {
        deliveryTime = `${minutesToTime(shorter_time).value} ${minutesToTime(shorter_time).suffix} - ${minutesToTime(longer_time).value} ${minutesToTime(longer_time).suffix}`
      }
    }

    let scheduleTime = ''

    deliverySchedule.forEach(p => {
      if (Number(p.day) === Number(format(new Date(), 'i'))) {
        const now = format(Date.now(), 'pp')
        const timeStart = format(new Date(p.start), 'pp')
        const timeEnd = format(new Date(p.end), 'pp')

        if (!isBefore(parseISO(timeStart), parseISO(now)) && !isAfter(parseISO(timeEnd), parseISO(now))) {

          let startStr = `${format(new Date(p.start), 'kk')}`
          let endStr = `${format(new Date(p.end), 'kk')}`

          if (getMinutes(parseISO(p.start)) > 0) {
            startStr += `:${format(parseISO(p.start), 'mm')}`
          } else {
            startStr += ' h'
          }

          if (getMinutes(parseISO(p.end)) > 0) {
            endStr += `:${format(parseISO(p.end), 'mm')}`
          } else {
            endStr += ' h'
          }
          scheduleTime = `Hoje: ${startStr} às ${endStr}`
        }

      }
    })

    return (
      <React.Fragment>
        <div style={{ position: 'relative' }}>
          <Body style={{ overflow: 'auto' }}>
            <DrugstoreInfo
              name="schedule"
              icon={<PulsingCircle open={storeOpen} mobile={true} />}
              primaryText="Horários"
              secondaryText={scheduleTime}
              visible={this.isDropDownOpen('schedule')}
              onClick={() => GAEvents({ category: 'Navigation', action: 'Click no botão Ver horarios de entrega', label: 'BodyContainer' })}
              dropdown
            >
              {this._renderScheduleDropdownContent()}
            </DrugstoreInfo>
            <DrugstoreInfo
              name="delivery"
              icon={<Clock width={16} height={16} />}
              primaryText="Entrega"
              secondaryText={deliveryTime}
            />
            <DrugstoreInfo
              name="regions"
              icon={<DeliveryBoy width={22} height={20} />}
              primaryText="Regiões de entrega"
              visible={this.isDropDownOpen('regions')}
              dropdown
              onClick={() => GAEvents({ category: 'Navigation', action: 'Click no botão Ver regioes de entrega', label: 'BodyContainer' })}
            >
              {this._renderDeliveredRegions()}
            </DrugstoreInfo>
            <DrugstoreInfo
              name="paymentMethods"
              icon={<DolarSign width={16} height={16} />}
              primaryText="Formas de pagamento"
              visible={this.isDropDownOpen('paymentMethods')}
              onClick={() => GAEvents({ category: 'Navigation', action: 'Click no botão Ver formas de pagamento', label: 'BodyContainer' })}
              dropdown
            >
              {this._renderPaymentMethods()}
            </DrugstoreInfo>
          </Body>
          <Body column>
            {children}
          </Body>
        </div>
        <ToastContainer />
      </React.Fragment >
    )
  }
}

const mapStateToProps = ({ delivery, payment, address }) => ({
  _delivery: delivery,
  _payment: payment,
  _address: address,
  _regions: delivery.regions

})

const mapDispatchToProps = dispatch => ({
  attemptGetDeliverySchedule: () => dispatch(Creators.deliveryScheduleRequest()),
  attemptGetDeliveryCities: () => dispatch(Creators.cityListRequest()),
  attemptGetDeliveryNeighborhoods: () => dispatch(Creators.neighborhoodListRequest()),
  attemptGetDeliveries: (neighborhood_id) => dispatch(Creators.deliveryListRequest(neighborhood_id)),
  attemptGetPaymentMethods: () => dispatch(Creators.paymentMethodListRequest()),

  attempGetRegionsDelivery: (neighborhood_id) => dispatch(Creators.regionsListRequest(neighborhood_id))

})

export default connect(mapStateToProps, mapDispatchToProps)(BodyContainer)
