/**
 * Capitalize words in a string
 * 
 * @param {String} string
 * @return {String}
 */
export default (string) => {
  if (typeof string !== 'string') return ''

  let output = ''

  string.toLowerCase().split(' ').forEach(str => {
    output += str.charAt(0).toUpperCase() + str.slice(1) + ' '
  })

  return output.slice(0, output.length - 1)
}