import React from 'react'
import { SVG } from './styles.js'

const Rating = ({ height = 42, width = 42 }) => (
  <SVG 
    xmlns="http://www.w3.org/2000/svg" 
    width={width}
    height={height}
    viewBox="0 0 42 42"
  >
    <g transform="translate(2.5 2.5)">
      <path 
        d="M37.5,971.362a18.5,18.5,0,1,0,18.5,18.5A18.5,18.5,0,0,0,37.5,971.362Zm0,6.565a1.194,1.194,0,0,1,1.194,1.194v9.548h8.355a1.194,1.194,0,0,1,0,2.388H37.5a1.194,1.194,0,0,1-1.194-1.194V979.12A1.194,1.194,0,0,1,37.5,977.926Z"
        transform="translate(-19 -971.362)"
        fill="none"
        stroke="#a8a8a8"
        strokeWidth="5"
      />
    </g>
  </SVG>
)

export default Rating
