import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Body from '@components/Body'
import Modal from 'react-responsive-modal'
import Button from '@components/Button'
import LoadingCircle from '@components/Icons/LoadingCircle'
import MissingData from '../../components/MissingData'
import ErrorCode from '../../helpers/ErrorCodes'
import { Creators } from '../../actions'
import { isLogged } from '../../redux/LoginRedux'

import { FacebookContainer, ContentInfo, ContentError, Error } from './styles'

class FacebookCallback extends Component {
  constructor(props) {
    super(props)
    this.saveCartRemote = false;
    this.goToHome = false;
    this.goToCheckout = false;

    this.state = {
      logged: false,
      errorLogin: false,
      loading: true,
      redirectTo: null,
      goToCheckout: false,
      goToHome: false
    }
  }
  componentWillMount() {
    const { attemLoginFacebook, history } = this.props;
    const urlParams = new URLSearchParams(window.location.search)

    if (urlParams.has('error')) {
      history.replace('/produtos')
    }

    if (urlParams.has('code')) {
      this.setState({
        logged: true,
        loginError: null
      }, () => {
        setTimeout(() => {
          attemLoginFacebook(urlParams.get('code'))
          history.replace('/facebook/callback')
        }, 500);
      })
    }

  }

  componentWillReceiveProps(newProps) {
    const { fetching, errorLogin, user, proceedCheckout, proceedHome, loggedIn, products, currentAddress } = newProps;
    const { attemptUpdateCartRemote } = this.props;
    let newState = Object.assign({}, this.state)

    if (proceedCheckout) {
      this.saveCartRemote = true;
      this.goToCheckout = true;
      this.props.setProccedCart(false);
      newState.goToCheckout = true

    }

    if (proceedHome) {
      this.saveCartRemote = true;
      this.goToHome = true;
      this.props.setCartProceedHome(false);
      newState.goToHome = true

    }

    if (loggedIn && this.goToCheckout) {

      if (this.props.products !== products || this.saveCartRemote) {
        this.saveCartRemote = false;

        attemptUpdateCartRemote(products);
      }
    }

    if (loggedIn && this.goToHome) {

      if (this.props.products !== products || this.saveCartRemote) {
        this.saveCartRemote = false;

        attemptUpdateCartRemote(products);
      }
    }

    if (newState.logged && !fetching) {

      if (errorLogin) {
        newState.errorLogin = ErrorCode(errorLogin)
        newState.logged = false;
        newState.logged = false;
      }
    }

    if (newState.logged && !fetching && user) {
      newState.logged = user.firstname && user.lastname && user.telephone.length > 0
    }

    if (loggedIn && newState.logged && this.goToCheckout) {
      this.goToCheckout = false;

      let redirectTo = {
        pathname: '/checkout'
      }
      if (!currentAddress) {
        redirectTo = {
          pathname: '/endereco',
          state: {
            selectAddress: true
          }
        }
      }
      newState.redirectTo = redirectTo;
    }

    if (loggedIn && newState.logged && this.goToHome) {
      this.goToHome = false;
      let redirectTo = {
        pathname: '/produtos'
      }

      newState.redirectTo = redirectTo;
    }

    if (loggedIn && !this.goToCheckout && !this.goToHome) {
      let redirectTo = {
        pathname: '/endereco',
        state: {
          selectAddress: true
        }
      }

      newState.redirectTo = redirectTo;
    }

    if (newState !== this.state) {
      this.setState(newState)
    }

  }

  _onFinish = () => {
    const { currentAddress } = this.props;
    const { goToCheckout, goToHome } = this.state;
    let redirectTo = null;

    if (goToCheckout) {
      redirectTo = {
        pathname: '/checkout'
      }
      if (!currentAddress) {
        redirectTo = {
          pathname: '/endereco',
          state: {
            selectAddress: true
          }
        }
      }
    }

    if (goToHome) {
      redirectTo = {
        pathname: '/produtos'
      }
    }

    this.setState({
      redirectTo,
      goToCheckout: false,
      goToHome: false
    })
  }

  _renderMissingData = () => {
    const { user } = this.props;

    if (user) {
      return (
        <MissingData customer={user} onFinish={this._onFinish} />
      )
    }
  }

  render() {
    const { location } = this.props;
    const { logged, loading, errorLogin, redirectTo } = this.state;

    const isMobile = window.innerWidth <= 426 ? true : false;

    const modalOptions = {
      open: true,
      center: true,
      closeOnEsc: false,
      closeOnOverlayClick: false,
      showCloseIcon: false,
      styles: {
        modal: {
          width: isMobile ? '100%' : '40%',
          borderRadius: '5px'
        }
      },
      onClose: () => { }
    }
    return (
      <Body>
        <Modal {...modalOptions}>
          <FacebookContainer>
            {
              loading && logged ? (
                <ContentInfo>
                  <LoadingCircle width={100} height={100} />
                  <span>Aguardando informações...</span>
                </ContentInfo>
              ) : errorLogin ? (
                <ContentError mobile={isMobile}>
                  <Error className="animated slideInDown">{errorLogin}</Error>
                  <Button style={{ width: '100%' }} onClick={this._onFinish}>Voltar para página inicial</Button>
                </ContentError>
              ) :
                  <div style={{ width: "100%" }}>
                    {this._renderMissingData()}
                  </div>
            }
            {redirectTo && redirectTo !== location.pathname ? <Redirect to={redirectTo} /> : null}
          </FacebookContainer>
        </Modal>
      </Body>
    )
  }
}

const mapStateToProps = ({ login, cart, address }) => ({
  fetching: login.fetching,
  errorLogin: login.error,
  user: login.user,
  proceedCheckout: cart.proceedCheckout,
  products: cart.products,
  loggedIn: isLogged(login),
  currentAddress: address.currentAddress,
  proceedHome: cart.proceedHome,
})

const mapDispatchToProps = dispatch => ({
  attemLoginFacebook: (code) => dispatch(Creators.loginFacebookRequest(code)),
  attemptUpdateCartRemote: (products) => dispatch(Creators.cartUpdateRemoteRequest(products)),
  attemptGetCartRemote: () => dispatch(Creators.cartGetRemoteRequest()),
  setProccedCart: (proceedCheckout) => dispatch(Creators.cartProceedBySocialLogin(proceedCheckout)),
  setCartProceedHome: (proceedHome) => dispatch(Creators.cartProceedHome(proceedHome)),
  logout: () => dispatch(Creators.logoutRequest())
})

export default connect(mapStateToProps, mapDispatchToProps)(FacebookCallback)
