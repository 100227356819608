const {
  REACT_APP_MASTER_API,
  REACT_APP_PRODUCTS_API,
  REACT_APP_PAYMENT_API,
  REACT_APP_S3_BUCKET,
  REACT_APP_LOCAL_ORIGIN,
  REACT_APP_AUTH_API,
  REACT_APP_SEARCH_ENGINE_API,
  REACT_APP_CORREIOS_API,

  FACEBOOK_APP_ID,
} = process.env

export default {
  productsApi: REACT_APP_PRODUCTS_API,
  paymentApi: REACT_APP_PAYMENT_API,
  masterApi: REACT_APP_MASTER_API,
  s3Bucket: REACT_APP_S3_BUCKET,
  localOrigin: REACT_APP_LOCAL_ORIGIN,
  searchEngineApi: REACT_APP_SEARCH_ENGINE_API,
  facebookId: FACEBOOK_APP_ID,
  authApi : REACT_APP_AUTH_API,
  correiosApi: REACT_APP_CORREIOS_API
}
