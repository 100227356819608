import styled from 'styled-components'
import InputMasked from 'react-input-mask'

export const CheckoutContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: #FFFFFF;
`
export const BRow = styled.div`
  display: flex;
  flex-direction: ${({ column }) => column ? 'column' : 'row'};
  width: 100%;
  margin: ${({ marged }) => marged ? '16px 0' : '0'};
`
export const BCol = styled.div`
  width: ${({ width }) => width};
  margin-right: ${({ margedRight }) => margedRight ? '16px' : '0'};
  margin-bottom: 5px;
`
export const Input = styled.input`
  height: ${({ height }) => height ? `${height}px` : '26px'};
  width: ${({ width, textAlign }) => width ? `${width}px` : textAlign === 'center' ? '100%' : 'calc(100% - 14px)'};
  border-radius: 10px;
  border: 1px solid #707070; 
  color: #707070;
  font-family: Gilmer;
  font-size: ${({ fontSize }) => fontSize ? `${fontSize}px` : '22px'};
  text-align: ${({ textAlign }) => textAlign || 'left'};
  outline: none;
  background-color: #FFFFFF;
  padding: ${({ textAlign }) => textAlign === 'center' ? '15px 0 15px 0' : '15px 0 15px 10px'};

  &::placeholder {
    font-family: Gilmer-Light;
  }
`
export const Button = styled.button`
  background-color: ${({ disabled }) => disabled ? '#A8A8A8' : '#00bf91'};
  width: 100%;
  border-radius: 5px;
  border: 0;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 15px;
  padding: 13px;
  pointer-events: ${({ disabled }) => disabled ? 'none' : 'auto'};

  &:active {
    background-color: ${({ disabled }) => disabled ? 'unset' : '#00a881 !important'};
  }
`
export const InputMask = styled(InputMasked)`
  height: ${({ height }) => height ? `${height}px` : '26px'};
  width: ${({ width, textAlign }) => width ? `${width}px` : textAlign === 'center' ? '100%' : 'calc(100% - 10px)'};
  border-radius: 10px;
  border: 1px solid #707070; 
  color: #707070;
  font-family: Gilmer;
  font-size: ${({ fontSize }) => fontSize ? `${fontSize}px` : '22px'};
  text-align: ${({ textAlign }) => textAlign || 'left'};
  outline: none;
  background-color: #FFFFFF;
  background: ${({ image }) => image ? `url(${image}) no-repeat scroll 95% 50%` : 'unset'};
  padding: ${({ textAlign }) => textAlign === 'center' ? '15px 0 15px 0' : '15px 0 15px 10px'};

  &:focus {
    border: 1px solid ${({ cpfvalid }) => cpfvalid ? '#00BF91' : '#c0392b'};
  }

  &::placeholder {
    font-family: Gilmer-Light;
  }
`
export const Title = styled.h2`
  font-weight: 900;
  font-family: Gilmer-Bold;
  font-size: 22px;
`
export const SubTitle = styled.h2`
  font-weight: 900;
  font-family: Gilmer;
  font-size: 18px;
`
export const ErrorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #e74c3c;
  color: #c0392b;
  width: calc(100% - 92px);
  padding: 20px;
  margin-bottom: 10px;

  h3 {
    font-family: Gilmer-Bold;
    font-weight: 900;
    font-size: 16px;
    margin-top: -10px;
  }

  p {
    font-size: 14px;
    margin: 0px;
  }
`
export const Error = styled.p`
  color: #b73535;
  font-size: 12px;
  margin-top: -18px;
  margin-left: 5px;
`
export const PicPayContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const PicPayText = styled.p`
  font-family: Gilmer;
  font-size: 18px;
  line-height: 30px;
  color: #A8A8A8;
`
export const PicPayInfo = styled.div`
  display: flex;
  flex-direction: column;
  width:${({ mobile }) => mobile ? '100%' : '468px'};

  img {
    align-self: center;
    width: 100%;
    height: 60px;
  }
`
export const PicPayContainerQRcode = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  button {
    width: 50%;
    height: 40px;
    outline: 0;
    margin-top: 10px;
    border-radius: 5px;
    background: #21c25e;
    font-size: 16px;
    border: 0;
     a {
       color: #FFF;
     }
  }
`
export const HealthInsuranceContainer = styled.div`
    width: 100%;
    max-width: 500px;
    margin-top: 26px;

    p {
      font-size: 12px;
      color: #999;
    }
    
    label,span{
      flex : 1;
      margin-bottom:5px;
      color : #757575;
      font-size:13px;
    }

    input{
      height: 5px;
      width: calc(100% - 10px);
      border-radius: 10px;
      border: 1px solid #707070;
      color: #707070;
      font-family: Gilmer;
      font-size: 14px;
      text-align: left;
      outline: none;
      background-color: #FFFFFF;
      background: unset;
      padding: 15px 0 15px 10px;
      width: 100%;
      max-width: 200px;
      margin-bottom : 8px;
    }

    @media(max-width: 800px) {
      input {
        max-width:100%;
      }
  }
`

export const InputContainer = styled.div`
  position : relative;
  border: 1px solid ${props => props.copySuccess ? 'green' : '#707070'};
  display : flex;
  align-items: center;
  margin-top: 16px;
  border-radius: 4px;
  width: 100%;

  .iconBtn{
    background: transparent;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    position: absolute;
    right: 5px;

    .icon{
      color: ${props => props.copySuccess ? 'green' : '#707070'};
      height: 16px;
      width: 16px;
    }
  }

`
export const CopyInput = styled.input`
  width : 90%;
  color: #707070;
  border: none;
  outline: none;
  background: transparent;  
  height: 45px;
`