import React, { Component } from "react"
import { Link } from 'react-router-dom'
import Button from '@components/Button'
import * as Icons from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AddressCardContainer, InfoContainer, ActionContainer, AddressPrimary } from './styles'
import './index.css'

export default class CorreiosCard extends Component {
  static defaultProps = {
    mobile: false,
    hasAddress: false
  }

  render() {
    const { mobile, hasAddress } = this.props

    return (
      <AddressCardContainer mobile={mobile}>
        {!hasAddress && <FontAwesomeIcon icon={Icons.faTruckMoving} style={{ width: 40, height: 37, color: '#a8a8a8' }} />}
        <InfoContainer>
          <AddressPrimary>
            {!hasAddress && 'CALCULAR FRETE (Demais cidades)'}
          </AddressPrimary>
        </InfoContainer>
        {hasAddress &&
          <ActionContainer>
            <Link to={{ pathname: '/endereco/calculate' }}>
              <Button style={{ width: 280 }} color={'primary'} >
                <FontAwesomeIcon icon={Icons.faPlusCircle} style={{ marginRight: 10 }} />
                {'Adicionar um novo endereço'}
              </Button>
            </Link>
          </ActionContainer>
        }
        <ActionContainer>
          {!hasAddress &&
            <Link to={{ pathname: '/endereco/calculate' }}>
              <Button style={{ width: 200 }} onClick={this.handleSelect} color={'primary'} >{'Calcular frete'}</Button>
            </Link>
          }
        </ActionContainer>
      </AddressCardContainer>
    )
  }
}